import React from "react";

import SectionHeader from "../../SectionHeader";
import SectionFooter from "../../SectionFooter";

const AMLCTFPolicies: React.FC<{ id: number }> = ({ id }) => {
  return (
    <>
      <SectionHeader id={id}>{`${id} AML / CTF`}</SectionHeader>
      <p>
        ACOLIN has established and maintains adequate AML / CTF policies and
        procedures in line with local and European regulatory requirements,
        Directive (EU) 2018/843, FATF requirements as well as relevant
        Luxembourg AML law (such as CSSF Circulars 20-05 and 18/698).
        Identifying risks to which ACOLIN is exposed is one of the key elements
        of our due diligence process.
      </p>
      <p>
        As ACOLIN is not involved in any subscription or redemption processes,
        the requirements regarding transaction monitoring do not apply.
        Nonetheless, ACOLIN has the capabilities and procedures to issue
        Suspicious Activity Reports if required to do so.
      </p>
      <SectionFooter />
    </>
  );
};

export default React.memo(AMLCTFPolicies);
