import React, { ChangeEvent, useState, useCallback } from "react";

import { ArrayHelpers, FieldArray } from "formik";
import SectionHeader from "../../SectionHeader";
import SectionFooter from "../../SectionFooter";

import styles from "./Violations.module.scss";

import { v4 as uuidv4 } from "uuid";
import { ConflictOfInterest } from "../../types";
import TableInput from "../../TableInput";

const emptyInputValues = {
  id: "",
  text: "",
  editable: true,
};

const emptyInputErrors = {
  text: "",
};

const validateInput = (value: string) => {
  return value ? "" : "Required";
};

const ConflictsTable: React.FC<{
  reportedConflicts: ConflictOfInterest[];
}> = React.memo(({ reportedConflicts }) => {
  const [inputValues, setInputValues] = useState({
    ...emptyInputValues,
    id: uuidv4(),
  });
  const [inputErrors, setInputErrors] = useState(emptyInputErrors);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    const error = validateInput(value);
    setInputValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setInputErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  }, []);

  const onAddClick = (arrayHelpers: ArrayHelpers) => {
    const error = validateInput(inputValues.text);
    if (error) {
      setInputErrors({
        text: validateInput(inputValues.text),
      });
    } else {
      arrayHelpers.push(inputValues);
      setInputValues({ ...emptyInputValues, id: uuidv4() });
      setInputErrors(emptyInputErrors);
    }
  };

  return (
    <>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>No.</th>
            <th>Reported conflicts of interests</th>
          </tr>
        </thead>
        <tbody>
          <FieldArray
            name="reportedConflicts"
            render={(arrayHelpers) => (
              <>
                {reportedConflicts.length > 0 ? (
                  reportedConflicts.map((row, index) => (
                    <tr key={row.id}>
                      <td>{index + 1}.</td>
                      <td>
                        {row.text}
                        {row.editable && (
                          <button
                            className={styles.remove}
                            type="button"
                            onClick={() => {
                              arrayHelpers.remove(index);
                            }}
                          >
                            -
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td />
                    <td>None</td>
                  </tr>
                )}
                <tr className={styles.inputRow}>
                  <td />
                  <td className={styles.inputRow}>
                    <TableInput
                      name="text"
                      placeholder="Reported conflict"
                      value={inputValues.text}
                      error={inputErrors.text}
                      onChange={handleChange}
                    />
                    <button
                      className={styles.add}
                      type="button"
                      onClick={() => {
                        onAddClick(arrayHelpers);
                      }}
                    >
                      +
                    </button>
                  </td>
                </tr>
              </>
            )}
          />
        </tbody>
      </table>
      <SectionFooter />
    </>
  );
});

const Violations: React.FC<{
  id: number;
  reportedConflicts: ConflictOfInterest[];
}> = ({ id, reportedConflicts }) => {
  return (
    <>
      <SectionHeader id={id}>
        {`${id} Violation of inducement register requirements or other conflicts
         of interests`}
      </SectionHeader>
      <p>
        ACOLIN Group Compliance has adjusted the initial and ongoing
        distributors due diligence oversight procedures (DDQ) in line with MIFID
        II requirements, which covers the reporting obligation of our partners
        that keep an internal list of inducements. Distributors are dealing in
        the client’s best interests if they pay or are paid a fee, commission or
        non-monetary benefit for providing investment to clients designed to
        enhance the quality of the relevant service (Section 70 German
        Securities Trading Act = WpHG).
      </p>
      <p>
        Relevant ACOLIN oversight duties with regard to inducements are set out
        in section 70 WpHG and section 67 (4) S 2 WpHG and more detailed
        requirements relating to the acceptance of inducements under subsection
        (1) are contained in Article 40 of the EU Delegated Regulation 2017/565
        (delegated MiFID).
      </p>
      <p>
        In addition, we also report if any other conflicts of interests were
        detected or have been reported to ACOLIN.
      </p>
      <p>The table below shows other reported conflicts of interests.</p>
      <ConflictsTable reportedConflicts={reportedConflicts} />
    </>
  );
};

export default React.memo(Violations);
