import { Fade } from "@progress/kendo-react-animation";
import {
  Notification as KendoNotification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import React from "react";

import styles from "./Notifications.module.scss";

import { Notification } from "./types";

const Notifications: React.FC<{
  notifications: Notification[];
  hideNotification: (notification: Notification) => void;
}> = ({ notifications, hideNotification }) => {
  return (
    <NotificationGroup className={styles.root}>
      <Fade enter={true} exit={true}>
        {notifications.map((notification) => (
          <KendoNotification
            key={notification.uuid}
            type={{ style: notification.type, icon: true }}
            closable={true}
            onClose={() => {
              hideNotification(notification);
            }}
          >
            <span>{notification.text}</span>
          </KendoNotification>
        ))}
      </Fade>
    </NotificationGroup>
  );
};

export default Notifications;
