import React, { ChangeEvent } from "react";

import styles from "./TableInput.module.scss";

const TableInput: React.FC<{
  name: string;
  placeholder: string;
  error: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}> = ({ name, placeholder, error, value, onChange }) => {
  const inputPlacehodler = error ? error : placeholder;
  return (
    <input
      className={error && styles.error}
      name={name}
      placeholder={inputPlacehodler}
      value={value}
      onChange={onChange}
    />
  );
};

export default React.memo(TableInput);
