import React, { ChangeEvent, useCallback, useMemo, useState } from "react";

import { ArrayHelpers, FieldArray } from "formik";
import SectionHeader from "../../SectionHeader";
import SectionFooter from "../../SectionFooter";

import styles from "./BusinessContinuity.module.scss";

import { BusinessContinuityCase } from "../../types";
import { v4 as uuidv4 } from "uuid";
import TableInput from "../../TableInput";

const validateInput = (value: string) => {
  return value ? "" : "Required";
};

const BusinesssContinuityTable: React.FC<{
  businessContinuity: BusinessContinuityCase[];
}> = React.memo(({ businessContinuity }) => {
  const emptyInputValues = useMemo(
    () => ({
      id: "",
      case: "",
      mitigation: "",
      editable: true,
    }),
    []
  );

  const emptyInputErrors = useMemo(
    () => ({
      case: "",
      mitigation: "",
    }),
    []
  );
  const [inputValues, setInputValues] = useState<BusinessContinuityCase>({
    ...emptyInputValues,
    id: uuidv4(),
  });

  const [inputErrors, setInputErrors] = useState(emptyInputErrors);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    const error = validateInput(value);
    setInputValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setInputErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  }, []);

  const onAddClick = (arrayHelpers: ArrayHelpers) => {
    const caseError = validateInput(inputValues.case);
    const mitigationError = validateInput(inputValues.mitigation);

    const allInputsValid = caseError === "" && mitigationError === "";

    if (allInputsValid) {
      arrayHelpers.push(inputValues);
      setInputValues({ ...emptyInputValues, id: uuidv4() });
      setInputErrors(emptyInputErrors);
    } else {
      setInputErrors({
        case: caseError,
        mitigation: mitigationError,
      });
    }
  };

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>No.</th>
          <th>Case</th>
          <th>Mitigation</th>
        </tr>
      </thead>
      <tbody>
        <FieldArray
          name="businessContinuity"
          render={(arrayHelper) => (
            <>
              {businessContinuity?.length > 0 ? (
                businessContinuity.map((row, index) => (
                  <tr key={row.id}>
                    <td className={styles.index}>{index + 1}.</td>
                    <td>{row.case}</td>
                    <td>
                      {row.mitigation}
                      {row.editable && (
                        <button
                          className={styles.remove}
                          type="button"
                          onClick={() => {
                            arrayHelper.remove(index);
                          }}
                        >
                          -
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td />
                  <td colSpan={2}>None</td>
                </tr>
              )}
              <tr className={styles.inputRow}>
                <td />
                <td className={styles.inputRow}>
                  <TableInput
                    name="case"
                    placeholder="Case"
                    error={inputErrors.case}
                    value={inputValues.case}
                    onChange={handleChange}
                  />
                </td>
                <td className={styles.inputRow}>
                  <TableInput
                    name="mitigation"
                    placeholder="Mitigation"
                    value={inputValues.mitigation}
                    error={inputErrors.mitigation}
                    onChange={handleChange}
                  />
                  <button
                    className={styles.add}
                    type="button"
                    onClick={() => onAddClick(arrayHelper)}
                  >
                    +
                  </button>
                </td>
              </tr>
            </>
          )}
        />
      </tbody>
    </table>
  );
});

const BusinesssContinuity: React.FC<{
  id: number;
  quartal: string;
  businessContinuity: BusinessContinuityCase[];
}> = ({ id, quartal, businessContinuity }) => {
  return (
    <>
      <SectionHeader id={id}>{`${id} Business Continuity`}</SectionHeader>
      <p>
        In accordance with ACOLIN's Group Business Continuity Management Policy
        and IT Security Policy, ACOLIN has several procedures in place to
        maintain business functions or to quickly resume work after a disaster
        has occured, etc.
      </p>
      <p>
        {`The following table shows all business continuity cases in ${quartal}.`}
      </p>
      <BusinesssContinuityTable businessContinuity={businessContinuity} />
      <SectionFooter />
    </>
  );
};

export default React.memo(BusinesssContinuity);
