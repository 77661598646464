import React, { ReactNode } from "react";
import { Field, FieldAttributes } from "formik";

import styles from "./SectionHeader.module.scss";

/**
 * Workaround for:
 * https://github.com/DefinitelyTyped/DefinitelyTyped/pull/33602
 */
interface Props {
  id: number;
  children: ReactNode;
}

export const FormikSectionHeader = ({
  children,
  id,
  form,
}: FieldAttributes<any>) => {
  const pageBreakOn = form.values.print[id]?.section;

  const breakStyle = pageBreakOn ? ` ${styles.break}` : "";
  const disabledStyle = pageBreakOn ? ` ${styles.disabled}` : "";

  return (
    <div className={`${styles.root}${breakStyle}`} id={id}>
      <h3>{children}</h3>
      <hr />
      <button
        className={`${styles.pageBreakToggle}${disabledStyle}`}
        type="button"
        onClick={() => {
          form.setFieldValue("print", {
            ...form.values.print,
            [id]: { section: !pageBreakOn },
          });
        }}
      >
        pb
      </button>
    </div>
  );
};

const SectionHeader: React.FC<Props> = ({ children, id }) => {
  return (
    <>
      {/**
       * We don't need to pass name prop since we're using Field
       * component just to hook into Formik and access print object
       * in the values.
       * It's done this way because we want all print related data
       * inside of one object instead of having multiple fields for
       * each component that should be breaked.
       */}
      <Field id={id} component={FormikSectionHeader}>
        {children}
      </Field>
    </>
  );
};

export default React.memo(SectionHeader);
