import React from "react";

import SectionHeader from "../../SectionHeader";
import SectionFooter from "../../SectionFooter";

const DueDiligenceProcess: React.FC<{ id: number }> = ({ id }) => {
  return (
    <>
      <SectionHeader id={id}>
        {id} Due Diligence Process of ACOLIN on new distribution partner
      </SectionHeader>
      <p>
        Conducting business with third parties involves risks. In order to
        manage these risks, we have implemented a comprehensive due diligence
        procedure and appropriate monitoring activities. The respective
        information is reviewed and analysed accordingly to get a better
        understanding of our distribution partners.
      </p>
      <p>
        Our processes and reporting are monitored on an ongoing basis to reflect
        the requirements of the applicable European (Delegated Regulations EU
        2016/1675) and national law, and all other relevant regulatory
        requirements, e.g.: EU Anti-Money Laundering Directives, FATCA, SFAMA,
        CISA, MiFID II and all relevant circulars from the supervisory
        authorities including the new CSSF Circulars 20-05, 18/698 and 17/661
        requirements.
      </p>
      <p>
        ACOLIN Group Compliance carries out a comprehensive due diligence on
        every distribution partner (which takes into account the ongoing due
        diligence procedure “Principle of the oversight of Financial
        Intermediaries in Distribution of Funds” published by ALFI in May 2017)
        prior to signing an agreement. The ACOLIN due diligence process follows
        a risk-based approach.
      </p>
      <p>
        The results of the due diligence including the risk assessment are
        documented in a high-level document (Due Diligence Checklist “DDC”). The
        DDC is an executive summary which sets out the key information obtained
        from a distribution partner, which enables us to calculate the risk.
      </p>
      <p>
        ACOLIN’s risk-calculation method is mainly based upon the following
        criteria: organisation, AML, legal {"&"} regulatory risk, risk
        management, country-, sanctions- and corruption-risk, etc. Therefore, a
        selection of widely recognised sources are taken into account when
        assessing the risk, such as:
      </p>
      <ul>
        <li>FATF</li>
        <li>all relevant sanction lists, such as OFAC, EU, UN</li>
        <li>
          Transparency International Corruption index (
          <a
            href="https://www.transparency.org/en/# "
            rel="noreferrer"
            target="_blank"
          >
            https://www.transparency.org/en/
          </a>
          )
        </li>
        <li>EU list of High-risk third countries</li>
      </ul>
      <p>
        ACOLIN also includes other factors in the risk calculation, i.e. a
        beneficial owner that has been identified as a politically exposed
        person (PEP). As this may impose a higher risk, appropriate measures
        will be taken on a case by case basis.
      </p>
      <p>
        As the DDC is an internal document based on information that is publicly
        available, it may easily be used as a reporting tool and shared with
        ACOLIN’s counterparties upon request. The DDC provides a comprehensive
        overview of the distribution partner and requires approval from ACOLIN
        Group Compliance.
      </p>
      <SectionFooter />
    </>
  );
};

export default React.memo(DueDiligenceProcess);
