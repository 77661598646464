import React from "react";

import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
} from "@progress/kendo-react-charts";
import SectionHeader from "../../SectionHeader";
import SectionFooter from "../../SectionFooter";

import styles from "./DistributionNetworkChanges.module.scss";

import { DistributionNetworkChanges as Changes } from "../../types";

const formatLabelContent = (props: any) => {
  return `${props.dataItem.category} ${props.dataItem.value}`;
};

const DistributionNetworkChanges: React.FC<{
  id: number;
  changes: Changes;
  quartal: string;
  previousQuartal: string;
}> = ({ id, changes, quartal, previousQuartal }) => {
  return (
    <>
      <SectionHeader id={id}>
        {`${id} Changes in the distribution network`}
      </SectionHeader>
      <p>
        According to Art. 34 of Directive 2014/65/EU (MiFID II), ACOLIN Europe
        AG is notified for distribution in 20 European countries including
        Germany.
      </p>
      <p>
        {`The table below shows the changes in our 
          distribution network in ${quartal}`}
      </p>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Country</th>
            <th>{`Distribution partners at the end of ${previousQuartal}`}</th>
            <th>{`Distribution partners at the end of ${quartal}`}</th>
            <th>Changes in period</th>
          </tr>
        </thead>
        <tbody>
          {changes.eu.map((change, index) => (
            <tr key={index}>
              <td>{change.country}</td>
              <td>{change.partnersPreviousCount}</td>
              <td>{change.partnersCurrentCount}</td>
              <td>{change.changes}</td>
            </tr>
          ))}
          <tr className={styles.nonEUHeader}>
            <td>
              <div>Non-EU</div>
            </td>
            <td colSpan={3} />
          </tr>
          {changes.nonEu.map((change, index) => (
            <tr key={index}>
              <td>{change.country}</td>
              <td>{change.partnersPreviousCount}</td>
              <td>{change.partnersCurrentCount}</td>
              <td>{change.changes}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.mapContainer}>
        <div className={styles.subtitle}>Country overview</div>
        <div className={styles.map} />
      </div>
      <div className={styles.chartContainer}>
        <div className={styles.subtitle}>Agreements per country</div>
        <Chart
          style={{ height: 600 }}
          seriesColors={[
            "#0093ad",
            "#BCBEC0",
            "#8ADFFF",
            "#B0DFA1",
            "#C9DA92",
            "#5FF3CA",
            "#077D82",
            "#55A839",
            "#0C9B74",
            "#A5C249",
            "#7CCA62",
            "#10CF9B",
            "#91C6F7",
          ]}
        >
          <ChartLegend visible={false} />
          <ChartSeries>
            <ChartSeriesItem
              type="pie"
              data={changes.agrementsPerCountry}
              field="value"
              categoryField="category"
              labels={{ visible: true, content: formatLabelContent }}
            />
          </ChartSeries>
        </Chart>
      </div>
      <SectionFooter />
    </>
  );
};

export default React.memo(DistributionNetworkChanges);
