import React from "react";

import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
} from "@progress/kendo-react-charts";
import SectionHeader from "../../SectionHeader";
import SectionFooter from "../../SectionFooter";

import styles from "./DistributionPartnersDueDiligence.module.scss";

import { ChartListItem, DistributionPartnerDueDiligence } from "../../types";
import { getRiskCellStyle } from "../../util";

const DistributionPartnersDueDiligence: React.FC<{
  id: number;
  quartal: string;
  dueDiligence: DistributionPartnerDueDiligence[];
  overview: ChartListItem[];
}> = ({ id, quartal, dueDiligence, overview }) => {
  // Map backend data for overview chart to chart friendly structure.
  // Bar chart requires list of strings for category names
  // and matrix of numbers for series values. Therefore,
  // we have to transpose the values we get from the backend.
  const acc: { categories: string[]; series: number[][] } = {
    categories: [],
    series: [[], [], []],
  };

  const {
    categories,
    series: [low, medium, high],
  } = overview.reduce((acc, item) => {
    acc.series[0].push(item.values[0]);
    acc.series[1].push(item.values[1]);
    acc.series[2].push(item.values[2]);
    return acc;
  }, acc);

  return (
    <>
      <SectionHeader
        id={id}
      >{`${id} Ongoing due diligence on distribution partners in ${quartal}`}</SectionHeader>
      <p>
        The principle behind ACOLIN’s due diligence process is to get an overall
        impression of the counterparty by taking into consideration various
        aspects of risk. The questions in our due diligence questionnaire are
        designed to identify potential risks that may occur in the course of the
        business relationship. ACOLIN follows a risk-based approach, by carrying
        out ongoing due diligences every 12 months, or in case of highly
        regulated counterparties such as banks, every 24 months. In addition, we
        use Refinitive´s WorldCheck One database of PEPs and heightened risk
        individuals and organisations to manage financial, regulatory and
        reputational risk related to our counterparties.
      </p>
      <p>
        {`The following table shows the type of agreement, the frequency of due
         diligences conducted alongside the previous and the current risk scoring in ${quartal}. 
         The risk scoring is classified in three different risk levels: low-risk score under 25% is 
         highlighted in green, medium-risk score between 25% and 48% is highlighted in yellow and 
         high-risk score above 48% is highlighted in red.`}
      </p>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>No.</th>
            <th>Distribution partner</th>
            <th>Domicile</th>
            <th>Type of agreement</th>
            <th>CSSF Classification</th>
            <th>Sub-distribution</th>
            <th>Date of agreement</th>
            <th>Previous risk scoring</th>
            <th>Current risk scoring</th>
            <th>Frequency in months</th>
            <th>Ongoing DD finalised</th>
          </tr>
        </thead>
        <tbody>
          {dueDiligence.map((row, index) => (
            <tr key={index}>
              <td className={styles.index}>{index + 1}.</td>
              <td>{row.partner}</td>
              <td>{row.domicile}</td>
              <td>{row.agreementType}</td>
              <td>{row.classification}</td>
              <td>{row.subDistribution}</td>
              <td>{row.agreementDate}</td>
              <td className={getRiskCellStyle(row.previousRiskScore, styles)}>
                {row.previousRiskScore.status === "inProgress"
                  ? "In progress"
                  : `${row.previousRiskScore.value}%`}
              </td>
              <td className={getRiskCellStyle(row.currentRiskScore, styles)}>
                {row.currentRiskScore.status === "inProgress"
                  ? "In progress"
                  : `${row.currentRiskScore.value}%`}
              </td>
              <td>{row.frequency}</td>
              <td>{row.ddFinalized}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.chartContainer}>
        <div className={styles.subtitle}>
          {/* Overview Agreements and risk levels in 3Q 20 */}
          Overview Agreements and risk levels in {quartal}
        </div>
        <Chart
          seriesColors={["#BCBEC0", "#0093ad", "#8ADFFF"]}
          transitions={false}
        >
          <ChartCategoryAxis>
            <ChartCategoryAxisItem categories={categories} />
          </ChartCategoryAxis>
          <ChartSeries>
            <ChartSeriesItem
              type="column"
              name="Low Risk"
              stack={{ group: "a" }}
              data={low}
            />
            <ChartSeriesItem
              type="column"
              name="Medium Risk"
              data={medium}
              stack={{ group: "a" }}
            />
            <ChartSeriesItem
              type="column"
              name="High Risk"
              data={high}
              stack={{ group: "a" }}
            />
          </ChartSeries>
          <ChartLegend position="bottom" />
        </Chart>
      </div>
      <SectionFooter />
    </>
  );
};

export default React.memo(DistributionPartnersDueDiligence);
