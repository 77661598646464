export const COMPLIANCE_API_ORIGIN =
  process.env.REACT_APP_COMPLIANCE_API_ORIGIN;

export const MSAL_CLIENT_ID: string =
  process.env.REACT_APP_MSAL_CLIENT_ID || "";

export const MSAL_TENANT_ID: string =
  process.env.REACT_APP_MSAL_TENANT_ID || "";

export const ENABLE_DDC_CREATION: boolean =
  process.env.REACT_APP_ENABLE_DDC_CREATION === "1";

export const ENABLE_WCO: boolean = process.env.REACT_APP_ENABLE_WCO === "1";

export const DEFAULT_WCO_GROUP_ID: string =
  process.env.REACT_APP_DEFAULT_WCO_GROUP_ID || "";

export const IS_PRODUCTION: boolean = process.env.NODE_ENV === "production";

export const PUBLIC_URL: string = process.env.PUBLIC_URL || "";
