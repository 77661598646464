import React, { useState, useCallback, ChangeEvent } from "react";

import { FieldArray } from "formik";
import SectionHeader from "../../SectionHeader";
import SectionFooter from "../../SectionFooter";

import styles from "./IdentifiedInfringements.module.scss";

import { v4 as uuidv4 } from "uuid";
import { Infrigement } from "../../types";
import TableInput from "../../TableInput";

const emptyInputValues = {
  id: "",
  text: "",
  editable: true,
};

const emptyInputErrors = {
  text: "",
};

const validateInput = (value: string) => {
  return value ? "" : "Required";
};

const IdentifiedInfringements: React.FC<{
  id: number;
  infrigements: Infrigement[];
}> = ({ id, infrigements }) => {
  const [inputValues, setInputValues] = useState({
    ...emptyInputValues,
    id: uuidv4(),
  });

  const [inputErrors, setInputErrors] = useState(emptyInputErrors);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    const error = validateInput(value);
    setInputValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setInputErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  }, []);
  return (
    <>
      <SectionHeader id={id}>
        {`${id} Identified Infringements of local regulatory requirements and authorities’ investigations`}
      </SectionHeader>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>No.</th>
            <th>Infringements</th>
          </tr>
        </thead>
        <tbody>
          <FieldArray
            name="infrigements"
            render={(arrayHelper) => (
              <>
                {infrigements.length > 0 ? (
                  infrigements.map((row, index) => (
                    <tr key={row.id}>
                      <td className={styles.index}>{index + 1}.</td>
                      <td>
                        {row.text}
                        {row.editable && (
                          <button
                            className={styles.remove}
                            type="button"
                            onClick={() => {
                              arrayHelper.remove(index);
                            }}
                          >
                            -
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td />
                    <td>None</td>
                  </tr>
                )}
                <tr className={styles.inputRow}>
                  <td />
                  <td className={styles.inputRow}>
                    <TableInput
                      name="text"
                      placeholder="Infringement"
                      error={inputErrors.text}
                      value={inputValues.text}
                      onChange={handleChange}
                    />
                    <button
                      className={styles.add}
                      type="button"
                      onClick={() => {
                        const error = validateInput(inputValues.text);
                        if (error) {
                          setInputErrors({
                            text: validateInput(inputValues.text),
                          });
                        } else {
                          arrayHelper.push(inputValues);
                          setInputValues({ ...emptyInputValues, id: uuidv4() });
                          setInputErrors(emptyInputErrors);
                        }
                      }}
                    >
                      +
                    </button>
                  </td>
                </tr>
              </>
            )}
          />
        </tbody>
      </table>
      <SectionFooter />
    </>
  );
};

export default React.memo(IdentifiedInfringements);
