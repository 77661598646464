import {
  PublicClientApplication,
  Configuration,
  EventType,
  AccountInfo,
} from "@azure/msal-browser";
import { MSAL_CLIENT_ID, MSAL_TENANT_ID } from "../config";

const msalConfig: Configuration = {
  auth: {
    clientId: MSAL_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${MSAL_TENANT_ID}`,
    redirectUri: "/",
  },
  cache: {
    secureCookies: true,
    storeAuthStateInCookie: true,
  },
};

export function createMSALInstance() {
  const msal = new PublicClientApplication(msalConfig);
  const accounts = msal.getAllAccounts();

  if (accounts.length > 0) {
    msal.setActiveAccount(findAcolinAccount(accounts) || null);
  }

  msal.addEventCallback((e) => {
    if (e.eventType === EventType.LOGIN_SUCCESS && e.payload?.account) {
      msal.setActiveAccount(e.payload.account);
    }
  });

  msal.handleRedirectPromise().then((response) => console.log(response));
  return msal;
}

export const msalInstance = createMSALInstance();

export function findAcolinAccount(accounts: AccountInfo[]) {
  return accounts.find((account) => account.tenantId === MSAL_TENANT_ID);
}
