import React from "react";

import SectionHeader from "../../SectionHeader";
import SectionFooter from "../../SectionFooter";

const HighRiskJurisdictions: React.FC<{ id: number }> = ({ id }) => {
  return (
    <>
      <SectionHeader id={id}>
        {`${id} High Risk Jurisdictions and Sanction Lists`}
      </SectionHeader>
      <p>
        Part of the due diligence process is also to consider the jurisdiction
        in which the partner is domiciled and whether or not the country or the
        entity is subject to sanctions. ACOLIN uses Refinitiv´s World Check One
        alongside other sources, such as the EU list of high risk countries,
        FATF-High-risk jurisdictions, Basel AML Index, OFAC and other applicable
        sanction lists for this purpose.
      </p>
      <p>
        The jurisdiction of the distribution partner is taken into account in
        the risk scoring. In case the jurisdiction of a partner is subject to
        sanctions or other relevant risks, ACOLIN may adapt the risk scoring
        manually and take measures on how to proceed with the partner - e.g.
        increased monitoring and screening, terminating the agreement, etc.
      </p>
      <SectionFooter />
    </>
  );
};

export default React.memo(HighRiskJurisdictions);
