import Axios from "axios";
import { tokenRequest } from "../auth/AcolinAuthContext";
import { msalInstance } from "../auth/msal";

const axiosInstance = Axios.create();

axiosInstance.interceptors.response.use(undefined, async (error) => {
  const requestConfig = error.config;
  if (!error.response) {
    return Promise.reject(error);
  }
  if (error.response.status === 401) {
    if (!requestConfig._retry) {
      requestConfig._retry = true;
      try {
        const token = await msalInstance.acquireTokenSilent(tokenRequest);
        const idToken = token?.idToken;
        axiosInstance.defaults.headers["Authorization"] = `Bearer ${idToken}`;
        requestConfig.headers["Authorization"] = `Bearer ${idToken}`;
        return axiosInstance(requestConfig);
      } catch (e) {
        msalInstance.acquireTokenRedirect(tokenRequest);
      }
    } else {
      console.log("Failed to acquire token... Logging out soon...");
    }
  }
  return Promise.reject(error);
});

export const createCancelTokenSource = () => {
  return Axios.CancelToken.source();
};

export const isRequestCanceled = (error: any) => {
  return Axios.isCancel(error);
};

export default axiosInstance;
