import React from "react";

import { Field, FieldArray } from "formik";
import { FormikTableInput } from "../../../../components/common/formik/FormikWrappers";
import SectionHeader from "../../SectionHeader";
import SectionFooter from "../../SectionFooter";

import { HighRiskScoringPartner } from "../../types";

import styles from "./MeasuresTaken.module.scss";

const MeasuresTaken: React.FC<{
  id: number;
  quartal: string;
  highRiskScoringPartners: HighRiskScoringPartner[];
}> = ({ id, quartal, highRiskScoringPartners }) => {
  return (
    <>
      <SectionHeader id={id}>
        {`${id} Measures taken on high risk scorings`}
      </SectionHeader>
      <p>
        Any business relationship carries certain inherent risks. Therefore, our
        risk scoring system doesn’t allow a final score of 0%. As previously
        mentioned, we consider a risk score of under 25% as low, a score between
        25% and 48% as medium, and a risk score of more than 48% as high.
      </p>
      <p>
        In case of a high-risk scoring, we will take appropriate measures. These
        might include enhanced due diligences, increased monitoring or the
        refusal to enter into a business relationship with a distributor.
      </p>
      <p>
        The table below shows all distribution partners with a high-risk scoring
        in {quartal}.
      </p>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>No.</th>
            <th>Distribution partner</th>
            <th>High risk scoring</th>
            <th>Measures taken</th>
          </tr>
        </thead>
        <tbody>
          <FieldArray
            name="highRiskScoringPartners"
            render={() => (
              <>
                {highRiskScoringPartners.length > 0 ? (
                  highRiskScoringPartners.map((row, index) => (
                    <tr key={row.id}>
                      <td className={styles.index}>{index + 1}.</td>
                      <td>{row.distributorPartner}</td>
                      <td>{row.highRiskScoring}</td>
                      <td>
                        <Field
                          name={`highRiskScoringPartners[${index}].measuresTaken`}
                          placeholder="Measures taken"
                          component={FormikTableInput}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td></td>
                    <td colSpan={3}>
                      {
                        "During the reporting period no high risk client with a risk score of over 48% was identified."
                      }
                    </td>
                  </tr>
                )}
              </>
            )}
          />
        </tbody>
      </table>
      <p>
        In the past quarter we have not rejected any agreements or had any
        on-boarding issues regarding investors or distributors. There has been
        no indication of any suspicious behavior in the reporting period.
      </p>
      <SectionFooter />
    </>
  );
};

export default React.memo(MeasuresTaken);
