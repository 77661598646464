import React from "react";

import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";

type TextAlignOptions = "left" | "center" | "right";

const headerTextAlign: TextAlignOptions = "left";
const headerCellOptions = {
  textAlign: headerTextAlign,
  background: "#0093AD",
};
const cellTextAlign: TextAlignOptions = "left";
const cellOptions = {
  textAlign: cellTextAlign,
};

const dateCellOptions = {
  ...cellOptions,
  format: "dd/MM/yyyy HH:mm:ss",
};

const OverviewExcelExport = React.forwardRef(
  ({ maxUBOS }: { maxUBOS: number }, ref) => {
    const UBOComponents =
      maxUBOS <= 0
        ? []
        : Array(maxUBOS)
            .fill(0)
            .map((_, index) => {
              const fieldKey = `UBO${index + 1}`;
              const titleKey = `UBO ${index + 1}`;
              const components = [
                <ExcelExportColumn
                  field={`${fieldKey}.name`}
                  title={`${titleKey} Name`}
                  headerCellOptions={headerCellOptions}
                  cellOptions={cellOptions}
                />,
                <ExcelExportColumn
                  field={`${fieldKey}.nationality`}
                  title={`${titleKey} Nationality`}
                  headerCellOptions={headerCellOptions}
                  cellOptions={cellOptions}
                />,
                <ExcelExportColumn
                  field={`${fieldKey}.countryOfTaxResidence`}
                  title={`${titleKey} Country Of Tax Residence`}
                  headerCellOptions={headerCellOptions}
                  cellOptions={cellOptions}
                />,
                <ExcelExportColumn
                  field={`${fieldKey}.isOnSanctionList`}
                  title={`${titleKey} On Sanction List`}
                  headerCellOptions={headerCellOptions}
                  cellOptions={cellOptions}
                />,
                <ExcelExportColumn
                  field={`${fieldKey}.isPEP`}
                  title={`${titleKey} PEP`}
                  headerCellOptions={headerCellOptions}
                  cellOptions={cellOptions}
                />,
                <ExcelExportColumn
                  field={`${fieldKey}.isRCA`}
                  title={`${titleKey} RCA`}
                  headerCellOptions={headerCellOptions}
                  cellOptions={cellOptions}
                />,
                <ExcelExportColumn
                  field={`${fieldKey}.hasAdverseInformationRelatedToFinancialCrime`}
                  title={`${titleKey} Adverse Information Financial Crime`}
                  headerCellOptions={headerCellOptions}
                  cellOptions={cellOptions}
                />,
              ];
              return components;
            })
            .flat();

    return (
      <ExcelExport
        ref={ref as React.MutableRefObject<ExcelExport>}
        fileName="DDC_Overview.xlsx"
      >
        <ExcelExportColumn
          field="ddc.counterpartyId"
          title="ID"
          headerCellOptions={headerCellOptions}
          cellOptions={cellOptions}
          width={120}
          locked={true}
        />
        <ExcelExportColumn
          field="ddc.counterpartyName"
          title="Counterparty name"
          headerCellOptions={headerCellOptions}
          cellOptions={cellOptions}
          locked={true}
        />
        <ExcelExportColumn
          field="ddc.domicile"
          title="Domicile"
          headerCellOptions={headerCellOptions}
          cellOptions={cellOptions}
        />
        <ExcelExportColumn
          field="ddc.domicileGroup"
          title="Domicile Group"
          headerCellOptions={headerCellOptions}
          cellOptions={cellOptions}
        />
        <ExcelExportColumn
          field="ddc.relationshipToOtherParties"
          title="Relationship to other parties"
          headerCellOptions={headerCellOptions}
          cellOptions={cellOptions}
        />
        <ExcelExportColumn
          field="ddc.service"
          title="Service"
          headerCellOptions={headerCellOptions}
          cellOptions={cellOptions}
        />
        <ExcelExportColumn
          field="ddc.ddcType.name"
          title="Type"
          headerCellOptions={headerCellOptions}
          cellOptions={cellOptions}
        />
        <ExcelExportColumn
          field="ddc.typeOfDd.name"
          title="Type of DDC"
          headerCellOptions={headerCellOptions}
          cellOptions={cellOptions}
        />
        <ExcelExportColumn
          field="ddc.acolinCounterparties"
          title="ACOLIN Counterparty"
          headerCellOptions={headerCellOptions}
          cellOptions={cellOptions}
        />
        {/* <ExcelExportColumn
      field="ddc.dueDiligenceDocuments"
      title="Due Diligence Documents used"
      headerCellOptions={headerCellOptions}
      cellOptions={cellOptions}
    /> */}
        <ExcelExportColumn
          field="ddc.cooperationWithCounterparty.name"
          title="Cooperation with Counterparty"
          headerCellOptions={headerCellOptions}
          cellOptions={cellOptions}
        />
        <ExcelExportColumn
          field="ddc.arr"
          title="ARR"
          headerCellOptions={headerCellOptions}
          cellOptions={cellOptions}
          width={100}
        />
        <ExcelExportColumn
          field="ddc.amlCtfScore"
          title="AML & CTF Risk Rating"
          headerCellOptions={headerCellOptions}
          cellOptions={cellOptions}
        />
        <ExcelExportColumn
          field="ddc.recommendation.name"
          title="Recommended by the Compliance Team"
          headerCellOptions={headerCellOptions}
          cellOptions={cellOptions}
        />
        <ExcelExportColumn
          field="ddc.additionalMeasures"
          title="Additional measures to be taken"
          headerCellOptions={headerCellOptions}
          cellOptions={cellOptions}
          width={300}
        />
        <ExcelExportColumn
          field="ddc.submissionDate"
          title="Reporting Date"
          headerCellOptions={headerCellOptions}
          cellOptions={dateCellOptions}
          width={150}
        />
        <ExcelExportColumn
          field="ddc.lastModified"
          title="Modification Date"
          headerCellOptions={headerCellOptions}
          cellOptions={dateCellOptions}
          width={150}
        />
        <ExcelExportColumn
          field="ddc.frequency.name"
          title="Next review date"
          headerCellOptions={headerCellOptions}
          cellOptions={cellOptions}
        />
        <ExcelExportColumn
          field="ddc.nextDDDate"
          title="Ongoing DD"
          headerCellOptions={headerCellOptions}
          cellOptions={cellOptions}
        />
        <ExcelExportColumn
          field="assignedUser.email"
          title="Owner"
          headerCellOptions={headerCellOptions}
          cellOptions={cellOptions}
        />
        <ExcelExportColumn
          field="ddc.comments"
          title="Comment"
          headerCellOptions={headerCellOptions}
          cellOptions={{ wrap: true }}
          width={500}
        />
        {UBOComponents.map((component, index) =>
          React.cloneElement(component, { key: index })
        )}
      </ExcelExport>
    );
  }
);

export default OverviewExcelExport;
