import React, { useCallback, useState } from "react";

import { Field, FormikProps, FormikValues } from "formik";
import {
  FormRow,
  FormikRadioGroup,
  FormikMultiSelect,
  FormikDropDown,
  Option,
} from "../../common/formik/FormikWrappers";
import Score from "./Score";

import { AMLCTFOptions, Country, UBO } from "../../../common/ddc/types";

import styles from "./AMLCTFRiskCalculator.module.scss";

const allFieldsFilled = (
  values: FormikValues,
  amlCtfVersion?: string
): boolean => {
  const allCountriesFilled: boolean =
    values.countryOfIncorporation &&
    values.countriesOfBusinessActivities &&
    values.countriesOfBusinessActivities.length > 0;

  const allUBOSFilled =
    values.UBO?.length > 0 &&
    values.UBO?.every(
      (ubo: UBO) =>
        ubo.name &&
        ubo.countryOfTaxResidence &&
        ubo.isPEP &&
        ubo.nationality &&
        (amlCtfVersion === "v1" || ubo.isRCA)
    );

  const isExemptFromUBOIdentification =
    values.exemptFromUBOIdentification?.value === "yes";

  const isApplicableForCountryOfResidence =
    values.applicableCountryOfResidence?.value === "yes";

  const conditionalFieldsFilled = (() => {
    if (!isExemptFromUBOIdentification) {
      return allUBOSFilled;
    } else if (isApplicableForCountryOfResidence) {
      return values.countryOfResidence;
    } else {
      return true;
    }
  })();

  return allCountriesFilled && conditionalFieldsFilled;
};

const calculateScore = (
  values: FormikValues,
  amlCtfVersion?: string
): string => {
  const COBARiskCategories = values.countriesOfBusinessActivities?.map(
    (country: Country) => country.riskCategory
  );

  const isExemptFromUBOIdentification =
    values.exemptFromUBOIdentification?.value === "yes";

  const isApplicableForCountryOfResidence =
    values.applicableCountryOfResidence?.value === "yes";

  const UBOSrisks: string[] = values.UBO?.flatMap((ubo: UBO): string[] => [
    ubo.name ? "a" : "error",
    ubo.countryOfTaxResidence?.riskCategory ?? "error",
    ubo.hasAdverseInformationRelatedToFinancialCrime?.riskCategory ?? "error",
    ubo.isOnSanctionList?.riskCategory ?? "error",
    ubo.isPEP?.riskCategory ?? "error",
    ubo.nationality?.riskCategory ?? "error",
    (amlCtfVersion === "v1" ? "a" : ubo.isRCA?.riskCategory) ?? "error",
  ]);

  const UBOSrisk: string | undefined = ((risks: string[]) => {
    if (!risks || risks.length === 0 || risks.includes("error")) {
      return undefined;
    } else if (risks.includes("c")) {
      return "c";
    } else if (risks.includes("b")) {
      return "b";
    } else {
      return "a";
    }
  })(UBOSrisks);

  const conditionalRiskCategory = ((): string | undefined => {
    if (!isExemptFromUBOIdentification) {
      return UBOSrisk;
    } else if (isApplicableForCountryOfResidence) {
      return values.countryOfResidence?.riskCategory;
    } else {
      return "a";
    }
  })();

  const riskCategories = [
    values.countryOfIncorporation?.riskCategory,
    values.onSanctionList?.riskCategory,
    values.opaqueShareholdingStructure?.riskCategory,
    ...COBARiskCategories,
    values.dealingWithCustomersSubjectToSanctions?.riskCategory,
    values.informationRelatedToFinancialCrime?.riskCategory,
    values.informationRegardingOtherRegulatoryFailings?.riskCategory,
    conditionalRiskCategory,
  ];

  const score = ((categories) => {
    if (categories.includes(undefined)) {
      return "error";
    } else if (categories.includes("c")) {
      return "c";
    } else if (categories.includes("b")) {
      return "b";
    } else {
      return "a";
    }
  })(riskCategories);

  return score;
};

type Details = {
  active: boolean;
  details: string;
};

const initHoverDetails = {
  active: false,
  details: "",
};

const RequiredTableField: React.FC<{ value: string | undefined }> = ({
  value,
}) => {
  return value ? (
    <td> {value} </td>
  ) : (
    <td className={styles.requiredTableField}> Required </td>
  );
};

const NewAMLCTFRiskCalculator: React.FC<{
  amlCtfOptions: AMLCTFOptions;
  formikProps: FormikProps<any>;
  //newUBOList?: UBO[];
  readOnly: boolean;
}> = ({ amlCtfOptions, formikProps, readOnly /*, newUBOList*/ }) => {
  const [hoverDetails, setHoverDetails] = useState<Details>(initHoverDetails);

  const amlCtfVersion = amlCtfOptions.version;

  const [
    isExemptFromUBOIdentificationAnswer,
    setIsExemptFromUBOIdentificationAnswer,
  ] = useState<boolean>(
    formikProps.values.exemptFromUBOIdentification?.value === "yes"
  );

  const [
    isApplicableForCountryOfResidence,
    setIsApplicableForCountryOfResidence,
  ] = useState<boolean>(
    formikProps.values.applicableCountryOfResidence?.value === "yes"
  );

  const UBOs: UBO[] = formikProps.values.UBO;

  const {
    onSanctionList,
    dealingWithCustomersSubjectToSanctions,
    informationRelatedToFinancialCrime,
    informationRegardingOtherRegulatoryFailings,
    opaqueShareholdingStructure,
    exemptFromUBOIdentification,
    applicableCountryOfResidence,
    PEPUBO,
  } = amlCtfOptions.binary;

  const {
    countryOfIncorporation,
    countryOfResidence,
    nationalityUBO,
    countriesOfBusinessActivities,
  } = amlCtfOptions.countries;

  const getNotApplicableForCountryOfResidenceOption = useCallback(() => {
    const option = amlCtfOptions.binary.applicableCountryOfResidence.find(
      (option) => option.value === "no"
    );
    if (!option) {
      console.error(
        "Can't find 'Not applicable' for Country of Residence option"
      );
    }
    return option;
  }, [amlCtfOptions.binary]);

  const resetHoverDetails = useCallback(
    () => setHoverDetails(initHoverDetails),
    []
  );

  return (
    <>
      <FormRow label="Country of Incorporation of Counterparty:">
        <Field
          name="countryOfIncorporation"
          options={countryOfIncorporation}
          component={FormikDropDown}
          disabled={readOnly}
        />
      </FormRow>
      <FormRow label="Counterparty on Sanction List:">
        <Field
          name="onSanctionList"
          options={onSanctionList}
          component={FormikRadioGroup}
          disabled={readOnly}
        />
      </FormRow>
      <FormRow label="Opaque Shareholding Structure:">
        <Field
          name="opaqueShareholdingStructure"
          options={opaqueShareholdingStructure}
          component={FormikRadioGroup}
          disabled={readOnly}
        />
      </FormRow>
      <FormRow label="Countries of Business Activities:">
        <Field
          name="countriesOfBusinessActivities"
          options={countriesOfBusinessActivities}
          component={FormikMultiSelect}
          disabled={readOnly}
          sideEffect={(oldValue: Option[], newValue: Option[]) => {
            if (newValue && newValue.length === 0) {
              formikProps.setFieldTouched("worldCheckOneConducted", false);
              formikProps.setFieldValue("worldCheckOneConducted", false, true);
            }
          }}
        />
      </FormRow>
      <FormRow label="Dealings with Customers Subject to Sanctions:">
        <Field
          name="dealingWithCustomersSubjectToSanctions"
          options={dealingWithCustomersSubjectToSanctions}
          component={FormikRadioGroup}
          disabled={readOnly}
        />
      </FormRow>
      <FormRow label="Adverse Information Related to Financial Crime Counterparty:">
        <Field
          name="informationRelatedToFinancialCrime"
          options={informationRelatedToFinancialCrime}
          component={FormikRadioGroup}
          disabled={readOnly}
        />
      </FormRow>
      <FormRow label="Other Adverse Information Regarding Counterparty/UBO:">
        <Field
          name="informationRegardingOtherRegulatoryFailings"
          options={informationRegardingOtherRegulatoryFailings}
          component={FormikRadioGroup}
          disabled={readOnly}
        />
      </FormRow>

      <FormRow label="Exempt from UBO Identification:">
        <Field
          name="exemptFromUBOIdentification"
          options={exemptFromUBOIdentification}
          component={FormikRadioGroup}
          disabled={readOnly}
          sideEffect={(oldValue: any, newValue: any) => {
            const isExempt: boolean = newValue.value === "yes";
            if (!isExempt) {
              formikProps.setFieldTouched("countryOfResidence", false);
              formikProps.setFieldValue("countryOfResidence", undefined);
              formikProps.setFieldTouched(
                "applicableCountryOfResidence",
                false
              );
              formikProps.setFieldValue(
                "applicableCountryOfResidence",
                getNotApplicableForCountryOfResidenceOption()
              );
              setIsApplicableForCountryOfResidence(false);
            }
            setIsExemptFromUBOIdentificationAnswer(isExempt);
            // if (newValue && newValue.length === 0) {
            formikProps.setFieldTouched("worldCheckOneConducted", false);
            formikProps.setFieldValue("worldCheckOneConducted", false, true);
            // }
          }}
        />
      </FormRow>

      {isExemptFromUBOIdentificationAnswer && (
        <FormRow label="Country of Residence of the Parent Company:">
          <Field
            name="applicableCountryOfResidence"
            options={applicableCountryOfResidence}
            component={FormikRadioGroup}
            disabled={readOnly}
            sideEffect={(oldValue: any, newValue: any) => {
              const isApplicable: boolean = newValue.value === "yes";
              formikProps.setFieldTouched("countryOfResidence", false);
              formikProps.setFieldValue("countryOfResidence", undefined);
              setIsApplicableForCountryOfResidence(isApplicable);
              if (isApplicable) {
                formikProps.setFieldTouched("worldCheckOneConducted", false);
                formikProps.setFieldValue(
                  "worldCheckOneConducted",
                  false,
                  true
                );
              }
              // if (newValue && newValue.length === 0) {
              //formikProps.setFieldTouched("worldCheckOneConducted", false);
              //formikProps.setFieldValue("worldCheckOneConducted", false, true);
              // }
            }}
          />
        </FormRow>
      )}
      {isExemptFromUBOIdentificationAnswer &&
        isApplicableForCountryOfResidence && (
          <FormRow label="">
            <Field
              name="countryOfResidence"
              options={countryOfResidence}
              component={FormikDropDown}
              disabled={readOnly}
            />
          </FormRow>
        )}

      {!isExemptFromUBOIdentificationAnswer && (
        <>
          <br />
          <table>
            <tbody>
              <tr className={styles.tableRow}>
                <th> Name </th>
                <th> Nationality </th>
                <th> Country of Tax Residence </th>
                <th> On Sanction List </th>
                {amlCtfVersion === "v1" ? (
                  <th> PEP / RCA </th>
                ) : (
                  <>
                    <th> PEP </th>
                    <th> RCA </th>
                  </>
                )}
                <th> Adverse Information Financial Crime </th>
              </tr>
              {UBOs?.length === 0 && (
                <tr>
                  <div className={styles.requiredUBOList}>
                    Empty UBO list. Please enter UBO to the account on OMNI
                  </div>
                </tr>
              )}
              {UBOs?.map((ubo: UBO, index: number) => {
                return (
                  <tr
                    key={index}
                    className={styles.tableRow}
                    onMouseOver={() =>
                      setHoverDetails({
                        active: true,
                        details: `[Name]: ${
                          ubo.name ? ubo.name : "Missing name"
                        } ${ubo.details}`,
                      })
                    }
                    onMouseOut={resetHoverDetails}
                  >
                    <RequiredTableField value={ubo.name} />
                    <RequiredTableField value={ubo.nationality?.name} />
                    <RequiredTableField
                      value={ubo.countryOfTaxResidence?.name}
                    />
                    <td>
                      <Field
                        name={`UBO[${index}].isOnSanctionList`}
                        options={onSanctionList}
                        component={FormikRadioGroup}
                        disabled={readOnly}
                      />
                    </td>
                    <RequiredTableField value={ubo.isPEP?.label} />
                    {amlCtfVersion !== "v1" && (
                      <RequiredTableField value={ubo.isRCA?.label} />
                    )}
                    <td>
                      <Field
                        name={`UBO[${index}].hasAdverseInformationRelatedToFinancialCrime`}
                        options={informationRelatedToFinancialCrime}
                        component={FormikRadioGroup}
                        disabled={readOnly}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className={styles.hoverRow}>
            {hoverDetails.active && (
              <>
                <div>UBO details: </div>
                <div className={styles.hoverDetails}>
                  {" "}
                  {hoverDetails.details}
                </div>
              </>
            )}
          </div>
        </>
      )}

      <Score
        name="amlCtfScore"
        formikProps={formikProps}
        allFieldsFilled={allFieldsFilled}
        calculateScore={calculateScore}
        amlCtfVersion={amlCtfVersion}
      />
    </>
  );
};

export default NewAMLCTFRiskCalculator;
