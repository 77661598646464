import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import {
  Grid,
  GridColumn as Column,
  GridRowClickEvent,
} from "@progress/kendo-react-grid";
import {
  DataResult,
  process,
  State as GridState,
} from "@progress/kendo-data-query";
import RiskConfigurationForm from "../../components/RiskConfigurationForm";
import Loading from "../../components/Loading";

import styles from "./RiskConfigurationHistory.module.scss";

import { COMPLIANCE_API_ORIGIN } from "../../config";
import { getErrorMessage } from "../../common/util";
import { Template } from "../../common/risk/types";
import { Snapshot } from "./types";
import axiosInstance, {
  createCancelTokenSource,
  isRequestCanceled,
} from "../../api/axios";

const RiskConfigurationHistory: React.FC<{}> = () => {
  const [loading, setLoading] = useState(true);
  const [snapshots, setSnapshots] = useState<Snapshot[]>([]);
  const [gridState, setGridState] = useState<GridState>({
    skip: 0,
    take: 5,
  });
  const [selectedSnapshot, setSelectedSnapshot] = useState<{
    id: string;
    templates: Template[];
    comment: string;
    date: Date;
  }>();

  useEffect(() => {
    const source = createCancelTokenSource();
    axiosInstance
      .get(`${COMPLIANCE_API_ORIGIN}/v1/configurations/risk/`, {
        cancelToken: source.token,
      })
      .then((response) => {
        const snapshots: Snapshot[] = response.data.map((item: any) => {
          return {
            id: item.id,
            email: item.updatedBy.email,
            date: new Date(item.timestamp),
            content: item.content,
            comment: item.comment,
            selected: false,
          };
        });
        setSnapshots(snapshots);
        setLoading(false);
      })
      .catch((e) => {
        if (isRequestCanceled(e)) {
          return;
        }
        const message = getErrorMessage(e);
        alert(message);
      });
    return () => source.cancel();
  }, []);

  const gridData: DataResult = process(snapshots, gridState);
  gridData.data = gridData.data.map((snapshot) => ({
    ...snapshot,
    selected: snapshot.id === selectedSnapshot?.id,
  }));

  return (
    <div className={styles.root}>
      <h1>Risk Configuration History</h1>
      {loading ? (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      ) : (
        <>
          <Card>
            <CardHeader>
              <CardTitle>History</CardTitle>
            </CardHeader>
            <CardBody className={styles.historyCardBody}>
              <Grid
                className={styles.grid}
                {...gridState}
                pageable
                sortable
                data={gridData}
                selectedField="selected"
                onRowClick={(e: GridRowClickEvent) => {
                  setSelectedSnapshot({
                    id: e.dataItem.id,
                    templates: e.dataItem.content,
                    comment: e.dataItem.comment,
                    date: e.dataItem.date,
                  });
                }}
                onDataStateChange={(e) => {
                  setGridState(e.dataState);
                }}
              >
                <Column field="email" title="User" />
                <Column
                  field="date"
                  title="Date"
                  format="{0:dd/MMM/yyyy HH:mm}"
                />
              </Grid>
            </CardBody>
          </Card>
          {selectedSnapshot && (
            <>
              <RiskConfigurationForm
                templates={selectedSnapshot.templates}
                comment={selectedSnapshot.comment}
                onSubmit={() => {}}
                disabled={true}
                isCommentOnTop={true}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default RiskConfigurationHistory;
