import React from "react";

import SectionHeader from "../../SectionHeader";
import SectionFooter from "../../SectionFooter";

const KYCAndPEP: React.FC<{ id: number }> = ({ id }) => {
  return (
    <>
      <SectionHeader id={id}>
        {`${id} Know your Customer (KYC) and Politically Exposed Persons (PEP)`}
      </SectionHeader>
      <p>
        Know your Customer (“KYC”) checks are done prior to establishing a
        business relationship, in order to identify and verify a potential
        distribution partner. This includes checking personal and business
        information in order to determine the risk of entering into a potential
        business relationship.
      </p>
      <p>
        A PEP is a “natural person who is or has been entrusted with a
        high-ranking prominent public function at international, European or
        national level (or a public function of comparable political im-portance
        below national level) and immediate family members and persons known to
        be close associates with such persons”. A PEP may pose a higher money
        laundering risk to ACOLIN. This risk also extends to members of their
        immediate families and to known close associates. Therefore, PEPs, as
        well as relatives and close associates, are required to be subject to
        enhanced scrutiny by firms subject to the European Anti Money Laundering
        regulations. It is part of ACOLIN’s due diligence process to identify
        PEPs and to take additional measures in order to comply with the
        enhanced due diligence requirements under applicable laws and
        regulations.
      </p>
      <SectionFooter />
    </>
  );
};

export default React.memo(KYCAndPEP);
