import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import {
  Grid,
  GridColumn as Column,
  GridRowClickEvent,
  GridCellProps,
} from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import Loading from "../../components/Loading";
import AccountInformation from "../../components/AccountInformation";

import { process, State as GridState } from "@progress/kendo-data-query";
import { useHistory, useParams } from "react-router-dom";
import {
  formatAsQuartal,
  getErrorMessage,
  optionsToStrings,
} from "../../common/util";
import { Counterparty, Document } from "./types";
import { COMPLIANCE_API_ORIGIN } from "../../config";
import axiosInstance, {
  createCancelTokenSource,
  isRequestCanceled,
} from "../../api/axios";

import styles from "./CounterpartyDetails.module.scss";
import Dialog from "../../components/Dialog";
import { formatDate } from "@progress/kendo-intl";

const ReportingDateGridCell = (props: GridCellProps) => {
  const date = props.dataItem.submissionDate;
  return <td>{date ? formatDate(new Date(date), "dd/MM/yyyy") : ""}</td>;
};

const getRiskColorClass = (score: number) => {
  if (!score) {
    return styles.riskNotCalculated;
  } else if (score < 36) {
    return styles.riskLow;
  } else if (score < 48) {
    return styles.riskMedium;
  } else {
    return styles.riskHigh;
  }
};

const formatRiskScoreValue = (score: number) => {
  if (!score) {
    return "-";
  } else {
    return `${score}%`;
  }
};

const CounterpartyDetails: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [counterparty, setCounterparty] = useState<Counterparty>();
  const [gridState, setGridState] = useState<GridState>({
    skip: 0,
    take: 5,
  });
  const { counterpartyId } = useParams<{ counterpartyId: string }>();
  const history = useHistory();

  const [dialogMessage, setDialogMessage] = useState("");

  useEffect(() => {
    const source = createCancelTokenSource();
    axiosInstance
      .get(`${COMPLIANCE_API_ORIGIN}/v1/counterparties/${counterpartyId}`, {
        cancelToken: source.token,
      })
      .then((response) => {
        const counterparty: Counterparty = response.data;
        counterparty.accInfo.terminationDate = new Date(
          counterparty.accInfo.terminationDate
        );
        counterparty.documents = counterparty.documents.map(
          (document: Document) => ({
            ...document,
            submissionDate: document.submissionDate
              ? new Date(document.submissionDate)
              : document.submissionDate,
          })
        );
        counterparty.documents = counterparty.documents.sort(
          (d1: Document, d2: Document) => {
            if (!d1.submissionDate) {
              return 1;
            } else if (!d2.submissionDate) {
              return -1;
            }
            return d2.submissionDate?.getTime() - d1.submissionDate?.getTime();
          }
        );
        Object.values(counterparty.ddcHistory).forEach((ddc) => {
          ddc.submissionDate =
            ddc.submissionDate && new Date(ddc.submissionDate);
        });
        setCounterparty(counterparty);
        setLoading(false);
      })
      .catch((e) => {
        if (isRequestCanceled(e)) {
          return;
        }
        const message = getErrorMessage(e);
        alert(message);
        setLoading(false);
      });
    return () => source.cancel();
  }, [counterpartyId]);

  const services = useMemo(
    () => optionsToStrings(counterparty?.accInfo.services),
    [counterparty?.accInfo.services]
  );
  const acolinCounterparties = useMemo(
    () => optionsToStrings(counterparty?.accInfo.acolinCounterparties, "id"),
    [counterparty?.accInfo.acolinCounterparties]
  );
  const contracts = useMemo(
    () => optionsToStrings(counterparty?.accInfo.contracts),
    [counterparty?.accInfo.contracts]
  );

  const onDDCClick = (e: GridRowClickEvent) => {
    const document = e.dataItem;
    if (document.type !== "DDC") {
      return;
    }
    if (document.status === "STUB") {
      history.push(`/ddc/review-stub/${counterpartyId}/${document.id}`);
    } else if (document.status === "ARCHIVED") {
      history.push(`/ddc/review-archived/${counterpartyId}/${document.id}`);
    } else {
      history.push(`/ddc/review/${counterpartyId}/${document.id}`);
    }
  };

  const onStartClick = useCallback(() => {
    const statusName = counterparty?.accInfo?.status?.name ?? "";
    const isActiveOrProspect = ["Active", "Prospect"].includes(statusName);
    if (!isActiveOrProspect) {
      setDialogMessage(`Counterparty status on OMNI is ${statusName}`);
      return;
    }

    const hasStubDraftPending = counterparty?.documents?.some((ddc) =>
      ["STUB", "DRAFT", "PENDING"].includes(ddc.status)
    );
    if (hasStubDraftPending) {
      setDialogMessage(
        "There is a DDC under STUB, DRAFT or PENDING status. Please close that record first to create a STUB."
      );
      return;
    }

    history.push(`/ddc/create/${counterpartyId}`);
  }, [counterpartyId, counterparty, history]);

  return (
    <div className={styles.root}>
      <h1>Counterparty Details View</h1>
      {loading ? (
        <Loading />
      ) : (
        <>
          {counterparty && (
            <>
              <div className={styles.controls}>
                <Button onClick={onStartClick}>Start Due Diligence</Button>
              </div>
              <AccountInformation
                showOMNI={true}
                counterpartyName={counterparty.accInfo.counterpartyName}
                counterpartyId={counterparty.accInfo.counterpartyId.toString()}
                domicile={counterparty.accInfo.domicile}
                ddcType={counterparty.accInfo.ddcType.name}
                services={services}
                acolinCounterparties={acolinCounterparties}
                classification={counterparty.accInfo.classification}
                contracts={contracts}
                frequency={counterparty.accInfo.latestFrequency?.name}
                contacts={counterparty.accInfo.contacts}
                sharePointFolder={counterparty.accInfo.sharePointFolder}
                nextDDDate={counterparty.ddcHistory.nextDDC.submissionDate}
                status={counterparty.accInfo.status}
                terminationDate={counterparty.accInfo.terminationDate}
              />
              <Card>
                <CardHeader>
                  <CardTitle>DDC History</CardTitle>
                </CardHeader>
                <CardBody>
                  <table className={styles.ddcHistoryTable}>
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Initial DDC</th>
                        <th>Previous DDC</th>
                        <th>Latest DDC</th>
                        <th>Next DDC</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Date of Submission</td>
                        <td>
                          {formatAsQuartal(
                            counterparty.ddcHistory.initialDDC.submissionDate
                          )}
                        </td>
                        <td>
                          {formatAsQuartal(
                            counterparty.ddcHistory.previousDDC.submissionDate
                          )}
                        </td>
                        <td>
                          {formatAsQuartal(
                            counterparty.ddcHistory.latestDDC.submissionDate
                          )}
                        </td>
                        <td>
                          {formatAsQuartal(
                            counterparty.ddcHistory.nextDDC.submissionDate
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Risk Scoring</td>
                        <td
                          className={getRiskColorClass(
                            counterparty.ddcHistory.initialDDC.arr
                          )}
                        >
                          {formatRiskScoreValue(
                            counterparty.ddcHistory.initialDDC.arr
                          )}
                        </td>
                        <td
                          className={getRiskColorClass(
                            counterparty.ddcHistory.previousDDC.arr
                          )}
                        >
                          {formatRiskScoreValue(
                            counterparty.ddcHistory.previousDDC.arr
                          )}
                        </td>
                        <td
                          className={getRiskColorClass(
                            counterparty.ddcHistory.latestDDC.arr
                          )}
                        >
                          {formatRiskScoreValue(
                            counterparty.ddcHistory.latestDDC.arr
                          )}
                        </td>
                        <td
                          className={getRiskColorClass(
                            counterparty.ddcHistory.nextDDC.arr
                          )}
                        >
                          {formatRiskScoreValue(
                            counterparty.ddcHistory.nextDDC.arr
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle>Documents</CardTitle>
                </CardHeader>
                <CardBody>
                  <Grid
                    className={styles.grid}
                    {...gridState}
                    pageable
                    data={process(counterparty.documents, gridState)}
                    onDataStateChange={(e) => {
                      setGridState(e.dataState);
                    }}
                    onRowClick={onDDCClick}
                  >
                    <Column
                      field="type"
                      title="Document type"
                      minResizableWidth={100}
                    />
                    <Column
                      field="submissionDate"
                      title="Date of Creation"
                      cell={ReportingDateGridCell}
                      minResizableWidth={100}
                    />
                    <Column
                      field="status"
                      title="Document Status"
                      minResizableWidth={100}
                    />
                  </Grid>
                </CardBody>
              </Card>
              {dialogMessage && (
                <Dialog
                  title="Error"
                  type="confirmation"
                  positiveButtonText="Ok"
                  onPositiveButtonClick={() => {
                    setDialogMessage("");
                  }}
                  onClose={() => {
                    setDialogMessage("");
                  }}
                >
                  <>{dialogMessage}</>
                </Dialog>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CounterpartyDetails;
