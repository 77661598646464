import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CreateDDC from "../CreateDDC";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";

import styles from "./CounterpartySearch.module.scss";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { FilterDescriptor } from "@progress/kendo-data-query";

import { getErrorMessage, getOMNIOptions } from "../../common/util";
import Loading from "../../components/Loading";
import axiosInstance, {
  createCancelTokenSource,
  isRequestCanceled,
} from "../../api/axios";
import { DDCStub, OMNIOptions } from "../CreateDDC/CreateDDC";
import Dialog from "../../components/Dialog";
import { useHistory } from "react-router-dom";
import { COMPLIANCE_API_ORIGIN } from "../../config";
import { Button } from "@progress/kendo-react-buttons";
import { Option } from "../../components/common/formik/FormikWrappers";

interface Counterparty {
  id: string;
  name: string;
  isExclusive?: boolean;
}

const emptyOptions: OMNIOptions = {
  contractTypes: [],
  serviceTypes: [],
  ddcTypes: [],
  acolinCounterparties: [],
};

const InvalidField: React.FC<{
  fieldName: string;
}> = ({ fieldName }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle style={{ color: "red" }}>
          {`[MISSING INFO] => ${fieldName}`}
        </CardTitle>
      </CardHeader>
    </Card>
  );
};

const getCounterparties = async (): Promise<Counterparty[]> => {
  const { data } = await axiosInstance.get(
    `${COMPLIANCE_API_ORIGIN}/v1/counterparties`
  );
  return data;
};

const findOption = (options: Option[], id: string): Option | null =>
  options?.find((option) => option.id === id) ?? null;

const getDDCType = (
  riskiestDDCType: Option,
  riskiestServiceType: Option,
  options: OMNIOptions
): Option | null => {
  const TIED_AGENT_SERVICE_ID = "805220005";

  const isTiedAgentService =
    riskiestServiceType?.id?.toString() === TIED_AGENT_SERVICE_ID;

  if (isTiedAgentService) {
    const MANCO_DDC_TYPE_ID = "805220000";
    const isMancoDDCType =
      riskiestDDCType?.id?.toString() === MANCO_DDC_TYPE_ID;

    const FUND_DDC_TYPE_ID = "805220008";
    const isFundDDCType = riskiestDDCType?.id?.toString() === FUND_DDC_TYPE_ID;

    if (isMancoDDCType) {
      const FDP_MANCO_DDC_TYPE_ID = "100000001";
      return findOption(options.ddcTypes, FDP_MANCO_DDC_TYPE_ID);
    } else if (isFundDDCType) {
      const FDP_FUND_DDC_TYPE_ID = "100000002";
      return findOption(options.ddcTypes, FDP_FUND_DDC_TYPE_ID);
    }
  }
  return riskiestDDCType;
};

const getCounterpartyData = async (
  counterpartyId: string,
  options: OMNIOptions
): Promise<DDCStub> => {
  const { data } = await axiosInstance.get(
    `${COMPLIANCE_API_ORIGIN}/v1/account-information/${counterpartyId}`
  );
  const {
    counterPartyName: counterpartyName,
    counterPartyDomicile: domicile,
    contracts,
    counterPartyCountryISOCode: domicileISOCode,
  } = data;

  const riskiestContract =
    contracts.length > 0
      ? contracts?.reduce((acc: any, contract: any) => {
          const previousRisk = acc.contractTypeRiskRating;
          const risk = contract.contractTypeRiskRating;
          if (risk && risk > previousRisk) {
            return contract;
          } else {
            return acc;
          }
        })
      : null;

  const riskiestContractType = findOption(
    options.contractTypes,
    riskiestContract?.contractTypeCode?.toString()
  ) as Option;

  const riskiestServiceType = findOption(
    options.serviceTypes,
    riskiestContract?.serviceId?.toString()
  ) as Option;

  const riskiestDDCType = findOption(
    options.ddcTypes,
    riskiestContract?.contractDDCType?.toString()
  ) as Option;

  const ddcType = getDDCType(riskiestDDCType, riskiestServiceType, options);

  const stub: DDCStub = {
    counterpartyId,
    counterpartyName,
    contractType: riskiestContractType,
    serviceType: riskiestServiceType,
    ddcType,
    domicile,
    domicileISOCode,
  };
  return stub;
};

const CounterpartySearch: React.FC<{ counterpartyId?: string }> = ({
  counterpartyId,
}) => {
  const [options, setOptions] = useState<OMNIOptions>(emptyOptions);
  const [counterparties, setCounterparties] = useState<Counterparty[]>([]);
  const [counterparty, setCounterparty] = useState<Counterparty>();

  const [counterpartyData, setCounterpartyData] = useState<DDCStub>();

  const [loading, setLoading] = useState(true);
  const [loadingCounterpartyData, setLoadingCounterpartyData] = useState(false);
  const [filter, setFilter] = useState<FilterDescriptor | undefined>();
  const search = useRef<DropDownList>(null);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const [dialogErrorMessage, setDialogErrorMessage] = useState<string>("");
  const history = useHistory();

  const resetSelectedCounterparty = () => {
    setCounterparty(undefined);
    setCounterpartyData(undefined);
    setShowSuccessDialog(true);
  };

  const isValidStub: boolean = useMemo(() => {
    if (!counterpartyData) {
      return true;
    }
    const {
      counterpartyId,
      counterpartyName,
      contractType,
      ddcType,
      domicile,
      serviceType,
    } = counterpartyData;
    return (
      !!counterpartyId &&
      !!counterpartyName &&
      !!domicile &&
      !!contractType &&
      !!ddcType &&
      !!serviceType
    );
  }, [counterpartyData]);

  const filteredCounterparties = React.useMemo(() => {
    if (filter) {
      return counterparties.filter((counterparty) => {
        return (
          counterparty.id.includes(filter.value) ||
          counterparty.name.toLowerCase().includes(filter.value.toLowerCase())
        );
      });
    } else {
      return counterparties;
    }
  }, [counterparties, filter]);

  useEffect(() => {
    const source = createCancelTokenSource();
    setLoading(true);
    Promise.all([getCounterparties(), getOMNIOptions()])
      .then(([allCounterparties, omniOptions]) => {
        setCounterparties(allCounterparties);
        setOptions(omniOptions);

        if (counterpartyId) {
          const counterparty = allCounterparties.find(
            (cp) => cp.id === counterpartyId
          );
          if (counterparty) {
            setCounterparty(counterparty);
          }
        }
      })
      .catch((e) => {
        if (isRequestCanceled(e)) {
          return;
        }
        const message = getErrorMessage(e);
        alert(message);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => source.cancel();
  }, [counterpartyId]);

  useLayoutEffect(() => {
    search.current?.focus();
  }, [loading]);

  const closeErrorDialog = useCallback(() => {
    setDialogErrorMessage("");
    history.goBack();
  }, [history]);

  const updateCounterpartyData = useCallback(async () => {
    if (!counterparty) {
      return;
    }

    setLoadingCounterpartyData(true);
    getCounterpartyData(counterparty?.id, options)
      .then((data) => setCounterpartyData(data))
      .catch((error) => alert(error))
      .finally(() => setLoadingCounterpartyData(false));
  }, [counterparty, options]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h1>Due Diligence Checklist</h1>
      </div>
      {loading ? (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      ) : (
        <>
          <Card>
            <CardHeader>
              <CardTitle>Search counterparty id or name</CardTitle>
            </CardHeader>
            <CardBody className={styles.cardBody}>
              <DropDownList
                ref={search}
                value={counterparty}
                className={styles.search}
                textField="name"
                dataItemKey="id"
                data={filteredCounterparties}
                filterable={true}
                filter={filter?.value}
                onFilterChange={(e) => {
                  setFilter(e.filter);
                }}
                onChange={(e: DropDownListChangeEvent) => {
                  const selectedCounterparty = e.target.value;
                  setCounterparty(selectedCounterparty);
                  setCounterpartyData(undefined);
                }}
              />
            </CardBody>
            <CardFooter className={styles.cardBody}>
              {counterparty && (
                <Button
                  onClick={updateCounterpartyData}
                  className={styles.button}
                >
                  {`Load data for ${counterparty?.name}`}
                </Button>
              )}
            </CardFooter>
          </Card>

          {showSuccessDialog && (
            <Dialog
              title="Success"
              type="confirmation"
              positiveButtonText="Ok"
              onPositiveButtonClick={() => {
                setShowSuccessDialog(false);
              }}
              onClose={() => {
                setShowSuccessDialog(false);
              }}
            >
              <>STUB creation successfull.</>
            </Dialog>
          )}
          {dialogErrorMessage && (
            <Dialog
              title="Error"
              type="confirmation"
              positiveButtonText="Ok"
              onPositiveButtonClick={closeErrorDialog}
              onClose={closeErrorDialog}
            >
              <>{dialogErrorMessage}</>
            </Dialog>
          )}
        </>
      )}
      {loadingCounterpartyData && (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      )}

      {counterpartyData && !loadingCounterpartyData && (
        <CreateDDC
          stubData={counterpartyData}
          isValidStub={isValidStub}
          onCreate={resetSelectedCounterparty}
        />
      )}
      {counterpartyData && (
        <>
          {!counterpartyData?.counterpartyId && (
            <InvalidField fieldName="ID Number (OMNI ID)" />
          )}
          {!counterpartyData?.counterpartyName && (
            <InvalidField fieldName="Counterparty Name" />
          )}
          {!counterpartyData?.domicile && <InvalidField fieldName="Domicile" />}
          {!counterpartyData?.contractType && (
            <InvalidField fieldName="Contract Type" />
          )}
          {!counterpartyData?.ddcType && <InvalidField fieldName="DDC Type" />}
          {!counterpartyData?.serviceType && (
            <InvalidField fieldName="Acolin Service" />
          )}
        </>
      )}
    </div>
  );
};

export default CounterpartySearch;
