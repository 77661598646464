import React, { ChangeEvent, useCallback, useMemo, useState } from "react";

import { ArrayHelpers, FieldArray } from "formik";
import SectionHeader from "../../SectionHeader";
import SectionFooter from "../../SectionFooter";

import styles from "./ClientComplaints.module.scss";

import { Complaint, Complaints, InvestmentAdvisorComplaint } from "../../types";
import { v4 as uuidv4 } from "uuid";
import TableInput from "../../TableInput";

const validateInput = (value: string) => {
  return value ? "" : "Required";
};

const DistributorComplaintsTable: React.FC<{
  distributorComplaints: Complaint[];
}> = React.memo(({ distributorComplaints }) => {
  const emptyInputValues = useMemo(
    () => ({
      id: "",
      complaints: "",
      solved: "",
      unresolved: "",
      pending: "",
      editable: true,
    }),
    []
  );

  const emptyInputErrors = useMemo(
    () => ({
      complaints: "",
      solved: "",
      unresolved: "",
      pending: "",
    }),
    []
  );
  const [inputValues, setInputValues] = useState<Complaint>({
    ...emptyInputValues,
    id: uuidv4(),
  });

  const [inputErrors, setInputErrors] = useState(emptyInputErrors);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    const error = validateInput(value);
    setInputValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setInputErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  }, []);

  const onAddClick = (arrayHelpers: ArrayHelpers) => {
    const complaintsError = validateInput(inputValues.complaints);
    const solvedError = validateInput(inputValues.solved);
    const unresolvedError = validateInput(inputValues.unresolved);
    const pendingError = validateInput(inputValues.pending);

    const allInputsValid =
      complaintsError === "" &&
      solvedError === "" &&
      unresolvedError === "" &&
      pendingError === "";

    if (allInputsValid) {
      arrayHelpers.push(inputValues);
      setInputValues({ ...emptyInputValues, id: uuidv4() });
      setInputErrors(emptyInputErrors);
    } else {
      setInputErrors({
        complaints: complaintsError,
        solved: solvedError,
        unresolved: unresolvedError,
        pending: pendingError,
      });
    }
  };

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>No.</th>
          <th>Complaints</th>
          <th>Solved</th>
          <th>Unresolved</th>
          <th>Pending</th>
        </tr>
      </thead>
      <tbody>
        <FieldArray
          name="clientComplaints.distributorComplaints"
          render={(arrayHelper) => (
            <>
              {distributorComplaints.length > 0 ? (
                distributorComplaints.map((row, index) => (
                  <tr key={row.id}>
                    <td className={styles.index}>{index + 1}.</td>
                    <td>{row.complaints}</td>
                    <td>{row.solved}</td>
                    <td>{row.unresolved}</td>
                    <td>
                      {row.pending}
                      {row.editable && (
                        <button
                          className={styles.remove}
                          type="button"
                          onClick={() => {
                            arrayHelper.remove(index);
                          }}
                        >
                          -
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td />
                  <td colSpan={4}>None</td>
                </tr>
              )}
              <tr className={styles.inputRow}>
                <td />
                <td className={styles.inputRow}>
                  <TableInput
                    name="complaints"
                    placeholder="Complaints"
                    error={inputErrors.complaints}
                    value={inputValues.complaints}
                    onChange={handleChange}
                  />
                </td>
                <td className={styles.inputRow}>
                  <TableInput
                    name="solved"
                    placeholder="Solved"
                    value={inputValues.solved}
                    error={inputErrors.solved}
                    onChange={handleChange}
                  />
                </td>
                <td className={styles.inputRow}>
                  <TableInput
                    name="unresolved"
                    placeholder="Unresolved"
                    error={inputErrors.unresolved}
                    value={inputValues.unresolved}
                    onChange={handleChange}
                  />
                </td>
                <td className={styles.inputRow}>
                  <TableInput
                    name="pending"
                    placeholder={"Pending"}
                    error={inputErrors.pending}
                    value={inputValues.pending}
                    onChange={handleChange}
                  />
                  <button
                    className={styles.add}
                    type="button"
                    onClick={() => onAddClick(arrayHelper)}
                  >
                    +
                  </button>
                </td>
              </tr>
            </>
          )}
        />
      </tbody>
    </table>
  );
});

const InvestmentAdvisorsComplaintsTable: React.FC<{
  investmentAdvisorsComplaints: InvestmentAdvisorComplaint[];
}> = React.memo(({ investmentAdvisorsComplaints }) => {
  const emptyInputValues = useMemo(
    () => ({
      id: "",
      complaints: "",
      editable: true,
    }),
    []
  );
  const emptyInputErrors = useMemo(
    () => ({
      complaints: "",
    }),
    []
  );
  const [inputValues, setInputValues] = useState({
    ...emptyInputValues,
    id: uuidv4(),
  });
  const [inputErrors, setInputErrors] = useState(emptyInputErrors);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    const error = validateInput(value);
    setInputValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setInputErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  }, []);

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>No.</th>
          <th>Reported complaints about investment advisors</th>
        </tr>
      </thead>
      <tbody>
        <FieldArray
          name="clientComplaints.investmentAdvisorsComplaints"
          render={(arrayHelpers) => (
            <>
              {investmentAdvisorsComplaints.length > 0 ? (
                investmentAdvisorsComplaints.map((row, index) => (
                  <tr key={row.id}>
                    <td className={styles.index}>{index + 1}.</td>
                    <td>
                      {row.complaints}{" "}
                      {row.editable && (
                        <button
                          className={styles.remove}
                          type="button"
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                        >
                          -
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td />
                  <td>None</td>
                </tr>
              )}
              <tr className={styles.inputRow}>
                <td />
                <td className={styles.inputRow}>
                  <TableInput
                    name="complaints"
                    placeholder="Complaints"
                    error={inputErrors.complaints}
                    value={inputValues.complaints}
                    onChange={handleChange}
                  />
                  <button
                    className={styles.add}
                    type="button"
                    onClick={() => {
                      const error = validateInput(inputValues.complaints);
                      if (error) {
                        setInputErrors({
                          complaints: validateInput(inputValues.complaints),
                        });
                      } else {
                        arrayHelpers.push(inputValues);
                        setInputValues({ ...emptyInputValues, id: uuidv4() });
                        setInputErrors(emptyInputErrors);
                      }
                    }}
                  >
                    +
                  </button>
                </td>
              </tr>
            </>
          )}
        />
      </tbody>
    </table>
  );
});

const ClientComplaints: React.FC<{
  id: number;
  quartal: string;
  clientComplaints: Complaints;
}> = ({ id, quartal, clientComplaints }) => {
  return (
    <>
      <SectionHeader id={id}>{`${id} Client complaints`}</SectionHeader>
      <p>
        ACOLIN systematically maintains a Complaint Management system. This
        implies a transparent and fair complaints procedure for the reasonable
        and prompt handling of complaints.
      </p>
      <p>
        As complaints could have legal and economic effects on the respective
        relationship, it is of great importance to give our cooperation partners
        an insight into our complaints management.
      </p>
      <p>
        ACOLIN´s group policies define the process for the treatment of
        complaints involving investors, authorities, business partners or other
        stakeholders concerning ACOLIN itself or ACOLIN counterparties. The aim
        is to protect the legitimate interests of investors and the
        counterparties of ACOLIN as well as ACOLIN Group in the best possible
        way.
      </p>
      <p>
        Any incoming alleged complaint, whether oral, by telephone, or written
        will be handled with diligence and seriousness. The response to alleged
        complaints, whether they are legitimate or not, is always concluded
        after a detailed review. All complaints and responses are reported to
        the Management Company without undue delay.
      </p>
      <p>
        {`The table below shows a review of all complaints (from distributors and
        sub-distributors) and their process stages ACOLIN recorded in  ${quartal}.`}
      </p>
      <DistributorComplaintsTable
        distributorComplaints={clientComplaints.distributorComplaints}
      />
      <InvestmentAdvisorsComplaintsTable
        investmentAdvisorsComplaints={
          clientComplaints.investmentAdvisorsComplaints
        }
      />
      <SectionFooter />
    </>
  );
};

export default React.memo(ClientComplaints);
