import React, { useEffect, useState } from "react";

import RiskConfigurationForm from "../../components/RiskConfigurationForm";
import Loading from "../../components/Loading";

import styles from "./RiskConfiguration.module.scss";

import { COMPLIANCE_API_ORIGIN } from "../../config";
import { getErrorMessage } from "../../common/util";
import { Template } from "../../common/risk/types";
import axiosInstance, {
  createCancelTokenSource,
  isRequestCanceled,
} from "../../api/axios";

const RiskConfiguration: React.FC<{}> = () => {
  const [loading, setLoading] = useState(true);
  const [configuration, setConfiguration] = useState<{
    templates: Template[];
    comment: string;
  }>({ templates: [], comment: "" });
  useEffect(() => {
    const source = createCancelTokenSource();
    axiosInstance
      .get(`${COMPLIANCE_API_ORIGIN}/v1/configurations/risk/latest`, {
        cancelToken: source.token,
      })
      .then((response) => {
        setConfiguration({
          templates: response.data.content,
          comment: "",
        });
        setLoading(false);
      })
      .catch((e) => {
        if (isRequestCanceled(e)) {
          return;
        }
        const message = getErrorMessage(e);
        alert(message);
      });
    return () => source.cancel();
  }, []);

  const handleSubmit = (values: { templates: Template[]; comment: string }) => {
    setLoading(true);
    axiosInstance
      .post(`${COMPLIANCE_API_ORIGIN}/v1/configurations/risk/`, {
        riskConfiguration: {
          content: values.templates,
          comment: values.comment,
        },
      })
      .then((response) => {
        setConfiguration({
          templates: response.data.riskConfiguration.content,
          comment: "",
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        const message = getErrorMessage(error);
        alert(message);
      });
  };

  return (
    <div className={styles.root}>
      <h1>Risk Scoring Configuration</h1>
      {loading ? (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      ) : (
        <RiskConfigurationForm
          templates={configuration.templates}
          comment={configuration.comment}
          disabled={false}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default RiskConfiguration;
