/*
  Explanation how DDC state changes and how it affects
  visibility of signing checks (Due Diligence and World
  Check One). 

  <ROLE_NAME> -> <SIGNING CHECKS VISIBILITY> -> <DDC STATE AFTER SUBMIT>

  Initial submit:
    MANAGER -> Both checks active -> ACTIVE
    OFFICER -> DD check active, WCO hidden -> DRAFT

  DRAFT:
    MANAGER -> Both checks active -> ACTIVE
    OFFICER -> Original submitter: DD check disabled, WCO hidden -> DRAFT
            -> Reviewer: DD check disabled, WCO active -> PENDING

  PENDING:
    MANAGER -> DD check disabled, WCO check is active if it wasn't
               checked by any of the previous officers, if it was
               checked, it's disabled -> ACTIVE
    OFFICER -> Both checks disabled -> PENDING if it's assigned officer,
                                       non assigned officer can't submit

  ACTIVE/OUTDATED:
    MANAGER -> Both checks inactive -> Can't submit
    OFFICER -> Both checks inactive -> Can't submit
*/

import { FormikValues } from "formik";
import { Option } from "../../components/common/formik/FormikWrappers";
import { formatAsDate } from "../util";
import {
  TierRiskCategories,
  CountryResponse,
  Country,
  DDCResponse,
  DDC,
  DDCRequest,
  AMLCTFBinaryOptions,
  UBOModel,
  AMLCTFOptions,
  UBO,
  AMLCTFAnswers,
  AMLCTFBinaryOption,
} from "./types";

// AML/CTF values are not included in the initial form values, because
// during inital DDC submit officer can't fill them. Adding them here and
// setting to undefined would result in values not being sent to the backend,
// on form submit, an as a result they won't be part of a response for
// subsequent DDC submits, which produces validtion bugs in Formik.
// Therefore, we will add AML/CTF values during inital submit only
// if submitter is MANAGER, and in every following DDC submit (update) if
// submitter is second officer or MANAGER.

// Default empty values used when creating DDC
export const emptyFormValues = {
  relationshipToOtherParties: "",
  typeOfDd: undefined,
  dueDiligenceDocuments: [],
  cooperationWithCounterparty: undefined,
  comments: "",
  recommendation: undefined,
  additionalMeasures: "",
  frequency: undefined,
  dueDiligenceConducted: false,
  worldCheckOneConducted: false,
  O: "",
  A: "",
  L: "",
  S: "",
  RM: "",
  C: "",
  D: "",
  arr: 0,
  links: [],
  nextDDDate: undefined,
};

// Country options are not prefilled, so they are set to undefined.
// Binary options are prefilled with second option.
export const createInitialAmlCtfValues = (
  binaryOptions: AMLCTFBinaryOptions,
  domicileISOCode: string,
  countriesOfIncorporation: Country[]
) => {
  const countryOfIncorporation = countriesOfIncorporation.find(
    (country) => country.isoAlpha2Code === domicileISOCode
  );

  return {
    countryOfIncorporation,
    countryOfResidence: undefined,
    countriesOfBusinessActivities: undefined,
    onSanctionList: binaryOptions.onSanctionList[1],
    dealingWithCustomersSubjectToSanctions:
      binaryOptions.dealingWithCustomersSubjectToSanctions[1],
    informationRelatedToFinancialCrime:
      binaryOptions.informationRelatedToFinancialCrime[1],
    informationRegardingOtherRegulatoryFailings:
      binaryOptions.informationRegardingOtherRegulatoryFailings[1],
    opaqueShareholdingStructure: binaryOptions.opaqueShareholdingStructure[1],
    exemptFromUBOIdentification: binaryOptions.exemptFromUBOIdentification[1],
    applicableCountryOfResidence: binaryOptions.applicableCountryOfResidence[1],
  };
};

export const allAmlCtfOptionsSelected = (
  values: FormikValues,
  amlCtfVersion = ""
  //isNewVersion = false
) => {
  const isNewVersion = amlCtfVersion !== "";
  if (!isNewVersion) {
    return (
      values.countriesOfBusinessActivities !== undefined &&
      values.countriesOfBusinessActivities.length > 0 &&
      values.countryOfIncorporation !== undefined &&
      values.countryOfResidence !== undefined &&
      values.nationalityUBO !== undefined
    );
  } else {
    const isExempt = values.exemptFromUBOIdentification?.value === "yes";
    const isApplicable = values.applicableCountryOfResidence?.value === "yes";
    console.log(
      `isExempt: ${isExempt} isApplicable: ${isApplicable} countryOfResidence: ${values.countryOfResidence}`
    );

    const conditionalFieldsSelected: boolean = ((
      isExempt,
      isApplicable,
      amlCtfVersion
    ): boolean => {
      if (isExempt) {
        return isApplicable ? values.countryOfResidence : true;
      } else {
        const UBO: UBO[] = values.UBO;
        return (
          UBO?.length > 0 &&
          UBO?.every((ubo) => {
            const {
              name,
              countryOfTaxResidence,
              hasAdverseInformationRelatedToFinancialCrime,
              isOnSanctionList,
              isPEP,
              isRCA,
              nationality,
            } = ubo;
            return (
              name &&
              countryOfTaxResidence &&
              hasAdverseInformationRelatedToFinancialCrime &&
              isOnSanctionList &&
              nationality &&
              isPEP &&
              (amlCtfVersion !== "v1" ? isRCA : true)
            );
          })
        );
      }
    })(isExempt, isApplicable, amlCtfVersion);

    return (
      values.countriesOfBusinessActivities !== undefined &&
      values.countriesOfBusinessActivities.length > 0 &&
      values.countryOfIncorporation !== undefined &&
      conditionalFieldsSelected
    );
  }
};

export const responseToDDC = (ddcResponse: DDCResponse): DDC => {
  const riskConfigCategories = {
    A: ddcResponse.riskConfigCategories?.A ?? "",
    C: ddcResponse.riskConfigCategories?.C ?? "",
    D: ddcResponse.riskConfigCategories?.D ?? "",
    L: ddcResponse.riskConfigCategories?.L ?? "",
    O: ddcResponse.riskConfigCategories?.O ?? "",
    RM: ddcResponse.riskConfigCategories?.RM ?? "",
    S: ddcResponse.riskConfigCategories?.S ?? "",
  };

  const isNewVersion =
    ddcResponse?.amlctfConfiguration?.content?.version !== undefined;

  const notApplicableOption = ddcResponse?.amlctfConfiguration?.content?.binary?.applicableCountryOfResidence?.find(
    (option: AMLCTFBinaryOption) => option.value === "no"
  );

  const amlCtfAnswers = isNewVersion
    ? {
        applicableCountryOfResidence: notApplicableOption,
        UBO: [],
        ...ddcResponse.amlCtfAnswers,
      }
    : { ...ddcResponse.amlCtfAnswers };

  return {
    acolinCounterparties: ddcResponse.acolinCounterparties,
    additionalMeasures: ddcResponse.additionalMeasures,
    contracts: ddcResponse.contracts,
    cooperationWithCounterparty:
      ddcResponse.cooperationWithCounterparty || undefined,
    classification: ddcResponse.wphgClassification,
    comments: ddcResponse.comments,
    counterpartyName: ddcResponse.counterpartyName,
    counterpartyId: ddcResponse.counterpartyId,
    domicile: ddcResponse.domicile,
    domicileISOCode: ddcResponse.domicileISOCode,
    domicileGroup: ddcResponse.domicileGroup,
    submissionDate: ddcResponse.submissionDate,
    lastModified: ddcResponse.lastModified,
    ddcType: ddcResponse.ddcType,
    dueDiligenceConducted: ddcResponse.dueDiligenceConducted,
    worldCheckOneConducted: ddcResponse.worldCheckOneConducted,
    dueDiligenceDocuments: ddcResponse.dueDiligenceDocuments.map(
      (document: Option) => document.id
    ),
    links: ddcResponse.links ?? [],
    frequency: ddcResponse.frequency || undefined,
    nextDDDate: ddcResponse.nextDDDate,
    recommendation: ddcResponse.recommendation || undefined,
    relationshipToOtherParties: ddcResponse.relationshipToOtherParties,
    services: ddcResponse.services,
    typeOfDd: ddcResponse.typeOfDD || undefined,
    ...riskConfigCategories,
    arr: ddcResponse.arr,
    ...amlCtfAnswers,
    //...ddcResponse.amlCtfAnswers,
    amlCtfScore: ddcResponse.amlCtfScore,
    isRiskConfigurationSet: ddcResponse.isRiskConfigurationSet,
    reasonForRiskChange: ddcResponse.reasonForRiskChange,
    riskConfiguration: ddcResponse.riskConfiguration,
  };
};

export const ddcToRequest = (
  ddc: FormikValues,
  amlCtfVersion = ""
  //isNewVersion = false
): DDCRequest => {
  // TODO Maybe rename this in formik?
  let wphgClassification = ddc.classification ? ddc.classification : "";

  let A = ddc.A === "" ? undefined : ddc.A;
  let C = ddc.C === "" ? undefined : ddc.C;
  let D = ddc.D === "" ? undefined : ddc.D;
  let L = ddc.L === "" ? undefined : ddc.L;
  let O = ddc.O === "" ? undefined : ddc.O;
  let RM = ddc.RM === "" ? undefined : ddc.RM;
  let S = ddc.S === "" ? undefined : ddc.S;

  let amlCtfAnswers = undefined;
  let amlCtfScore = undefined;
  const isNewVersion = amlCtfVersion !== "";
  if (isNewVersion) {
    const conditionalField =
      ddc.exemptFromUBOIdentification.value === "no"
        ? { UBO: ddc.UBO }
        : {
            applicableCountryOfResidence: ddc.applicableCountryOfResidence,
          };
    amlCtfAnswers = {
      countriesOfBusinessActivities: ddc.countriesOfBusinessActivities,
      countryOfIncorporation: ddc.countryOfIncorporation,
      countryOfResidence: ddc.countryOfResidence,
      dealingWithCustomersSubjectToSanctions:
        ddc.dealingWithCustomersSubjectToSanctions,
      informationRegardingOtherRegulatoryFailings:
        ddc.informationRegardingOtherRegulatoryFailings,
      informationRelatedToFinancialCrime:
        ddc.informationRelatedToFinancialCrime,
      onSanctionList: ddc.onSanctionList,
      opaqueShareholdingStructure: ddc.opaqueShareholdingStructure,
      exemptFromUBOIdentification: ddc.exemptFromUBOIdentification,
      ...conditionalField,
    };
  } else {
    amlCtfAnswers = {
      PEPUBO: ddc.PEPUBO,
      countriesOfBusinessActivities: ddc.countriesOfBusinessActivities,
      countryOfIncorporation: ddc.countryOfIncorporation,
      countryOfResidence: ddc.countryOfResidence,
      dealingWithCustomersSubjectToSanctions:
        ddc.dealingWithCustomersSubjectToSanctions,
      informationRegardingOtherRegulatoryFailings:
        ddc.informationRegardingOtherRegulatoryFailings,
      informationRelatedToFinancialCrime:
        ddc.informationRelatedToFinancialCrime,
      nationalityUBO: ddc.nationalityUBO,
      onSanctionList: ddc.onSanctionList,
      opaqueShareholdingStructure: ddc.opaqueShareholdingStructure,
    };
  }
  amlCtfScore = ddc.amlCtfScore;

  return {
    cooperationWithCounterpartyId: ddc.cooperationWithCounterparty?.id,
    ddcTypeCode: ddc.ddcType?.id,
    frequencyId: ddc.frequency?.id,
    recommendationId: ddc.recommendation?.id,
    typeOfDDId: ddc.typeOfDd?.id,
    nextDDDate: ddc.nextDDDate,
    amlCtfAnswers,
    amlCtfScore,
    riskConfigCategories: {
      A,
      C,
      D,
      L,
      O,
      RM,
      S,
    },
    dueDiligenceDocumentsIds: ddc.dueDiligenceDocuments,
    serviceIds: ddc.services.map((service: Option) => service.id),
    contractTypeCodes: ddc.contracts.map((contract: Option) => contract.id),
    acolinCounterparties: ddc.acolinCounterparties
      // TODO Yet another temporary fix because of OMNI
      .filter((counterparty: Option) => counterparty.id !== null)
      .map((counterparty: Option) => counterparty.id),
    wphgClassification,
    submissionDate: ddc.submissionDate,
    additionalMeasures: ddc.additionalMeasures,
    comments: ddc.comments,
    counterpartyName: ddc.counterpartyName,
    counterpartyId: ddc.counterpartyId,
    worldCheckOneConducted: ddc.worldCheckOneConducted,
    dueDiligenceConducted: ddc.dueDiligenceConducted,
    domicile: ddc.domicile,
    domicileISOCode: ddc.domicileISOCode,
    domicileGroup: ddc.domicileGroup.id,
    links: ddc.links,
    relationshipToOtherParties: ddc.relationshipToOtherParties,
    arr: ddc.arr,
  };
};

// TODO Move this to backend, frontend shouldn't know anything about
// this blending stuff
export const mapResponseToCountry = (
  tierRiskCategories: TierRiskCategories,
  snapshot: CountryResponse[]
): { [key: string]: Country[] } => {
  const acc: { [key: string]: Country[] } = {
    countryOfIncorporation: [],
    countryOfResidence: [],
    nationalityUBO: [],
    countriesOfBusinessActivities: [],
  };
  const countries = snapshot.reduce(
    (acc: { [key: string]: Country[] }, countryResponse: CountryResponse) => {
      const tier = countryResponse.tier;
      acc.countryOfIncorporation.push({
        ...countryResponse,
        riskCategory: tierRiskCategories.countryOfIncorporation[tier],
      });
      acc.countryOfResidence.push({
        ...countryResponse,
        riskCategory: tierRiskCategories.countryOfResidence[tier],
      });
      acc.nationalityUBO.push({
        ...countryResponse,
        riskCategory: tierRiskCategories.nationalityUBO[tier],
      });
      acc.countriesOfBusinessActivities.push({
        ...countryResponse,
        riskCategory: tierRiskCategories.countriesOfBusinessActivities[tier],
      });

      return acc;
    },
    acc
  );

  return countries;
};

/**
 * Calculates the minimum, one year away and two years away
 * date for due diligence based on the submission date.
 *
 * @param submissionDate
 * @returns
 */
export const calculateDDDates = (submissionDate: Date) => {
  if (!submissionDate) {
    return [undefined, undefined, undefined, undefined];
  }
  const minDate = new Date(submissionDate);
  minDate.setDate(minDate.getDate() + 1);
  const yearAwayDate = new Date(submissionDate);
  yearAwayDate.setFullYear(submissionDate.getFullYear() + 1);
  const twoYearsAwayDate = new Date(submissionDate);
  twoYearsAwayDate.setFullYear(submissionDate.getFullYear() + 2);
  const threeYearsAwayDate = new Date(submissionDate);
  threeYearsAwayDate.setFullYear(submissionDate.getFullYear() + 3);
  const fiveYearsAwayDate = new Date(submissionDate);
  fiveYearsAwayDate.setFullYear(submissionDate.getFullYear() + 5);
  return [
    minDate,
    yearAwayDate,
    twoYearsAwayDate,
    threeYearsAwayDate,
    fiveYearsAwayDate,
  ];
};

// TODO Replace name compare with ID compare
/**
 * Calculates the next review date based on the
 * frequency set in the DDC
 *
 * @param frequency
 * @param otherDate
 * @param nextReviewDateMin
 * @param nextReviewDateMax
 * @returns
 */

export const calculateNextReviewDate = (
  frequency: Option,
  yearAwayDate: Date | undefined,
  twoYearsAwayDate: Date | undefined,
  threeYearsAwayDate: Date | undefined,
  fiveYearsAwayDate: Date | undefined,
  otherDate: Date | undefined
) => {
  if (!frequency) {
    return undefined;
  }
  let nextDDDate;
  switch (frequency.name) {
    case "12 Months": {
      nextDDDate = yearAwayDate;
      break;
    }
    case "24 Months": {
      nextDDDate = twoYearsAwayDate;
      break;
    }
    case "36 Months": {
      nextDDDate = threeYearsAwayDate;
      break;
    }
    case "60 Months": {
      nextDDDate = fiveYearsAwayDate;
      break;
    }
    case "None": {
      nextDDDate = undefined;
      break;
    }
    case "Other": {
      nextDDDate = otherDate;
      break;
    }
  }
  return nextDDDate;
};

const createUBODetails = (ubo: UBOModel): string => {
  const {
    dateOfBirth,
    placeOfBirth,
    tin,
    professionalStatus,
    percentOfShares,
  } = ubo;
  return `[Birth date]: ${
    dateOfBirth ? formatAsDate(new Date(dateOfBirth)) : "N/A"
  } [Birth place]: ${placeOfBirth ?? "N/A"} [TIN]: ${
    tin ?? "N/A"
  } [Professional status]: ${
    professionalStatus ?? "N/A"
  } [Percent of shares]: ${percentOfShares ? percentOfShares + "%" : "N/A"}`;
};

export const createUBOs = (ubos: UBOModel[], options: AMLCTFOptions): UBO[] => {
  if (!ubos || ubos.length === 0) {
    return [];
  }
  const {
    onSanctionList,
    informationRelatedToFinancialCrime,
    PEPUBO,
    RCAUBO,
  } = options.binary;

  const { countryOfResidence, nationalityUBO } = options.countries;

  return ubos.map((ubo: UBOModel) => {
    const mappedUBO: UBO = {
      id: ubo.omniContactId,
      name:
        ubo.firstName && ubo.lastName ? `${ubo.firstName} ${ubo.lastName}` : "",
      countryOfTaxResidence:
        countryOfResidence?.find(
          (country) => country.isoAlpha2Code === ubo.countryCodeTaxResidence
        ) ?? null,
      hasAdverseInformationRelatedToFinancialCrime: informationRelatedToFinancialCrime?.find(
        (info) => info.value === "no"
      ),
      isOnSanctionList: onSanctionList?.find((option) => option.value === "no"),
      isPEP:
        ubo.pep !== undefined && ubo.pep !== null
          ? PEPUBO?.find((pep) => pep.value === (ubo.pep ? "yes" : "no"))
          : undefined,
      isRCA:
        ubo.rca !== undefined && ubo.rca !== null
          ? RCAUBO?.find((rca) => rca.value === (ubo.rca ? "yes" : "no"))
          : undefined,
      nationality:
        nationalityUBO?.find(
          (country) => country.isoAlpha2Code === ubo.countryCodeNationality
        ) ?? null,
      details: createUBODetails(ubo),
    };
    return mappedUBO;
  });
};
