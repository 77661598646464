import { AxiosResponse } from "axios";
import { FormikValues } from "formik";
import { Template } from "../../common/risk/types";
import { Option } from "../../components/common/formik/FormikWrappers";

export type ComponentState = "active" | "disabled" | "hidden";

export interface DDCStatus {
  id: string;
  status: string;
}

interface Role {
  id: string;
  name: string;
}

export interface UpdatedBy {
  id: string;
  email: string;
  role: Role;
}

export interface LogItemResponse {
  id: string;
  status: DDCStatus;
  updatedBy: UpdatedBy;
  changeset: Changeset;
  timestamp: string;
}

export interface Link {
  id: string;
  content: string;
  url: string;
}

// TODO This will be redundant when
// backend takes over tier blending.
// Remove it later on.
export interface CountryResponse {
  id: string;
  name: string;
  tier: number;
  isoAlpha2Code: string;
  isoAlpha3Code: string;
  group: string;
}

export interface Country {
  id: string;
  name: string;
  tier: number;
  riskCategory: string;
  isoAlpha2Code: string;
  isoAlpha3Code: string;
}

export interface AMLCTFOptions {
  version?: string;
  categories: TierRiskCategories;
  binary: AMLCTFBinaryOptions;
  countries: { [key: string]: Country[] };
}

export interface AMLCTFBinaryOptions {
  [key: string]: AMLCTFBinaryOption[];
}

export interface AMLCTFBinaryOption {
  label: string;
  value: string;
  riskCategory: string;
}

export interface TierRiskCategories {
  [key: string]: string;
}

export interface TierConfiguration {
  id: string;
  configuration: Country[];
}

export interface FormData {
  initialValues: FormikValues;
  logItems: LogItem[];
  showBack: boolean;
  showTitle?: boolean;
  showCancel?: boolean;
  showRevert?: boolean;
  readOnly: boolean;
  ddConfirmationState: ComponentState;
  amlConfirmationState: ComponentState;
  riskConfigTemplates: Template[];
  amlCtfOptions: AMLCTFOptions;
  options: DDCOptions;
  ddcStatus?: string;
  save: (formSubmission: any) => Promise<AxiosResponse<any>>;
  submit: (formSubmission: any) => Promise<AxiosResponse<any>>;
  cancel?: (formSubmission: any) => Promise<AxiosResponse<any>>;
  revert?: (formSubmission: any) => Promise<AxiosResponse<any>>;
}

export interface DDCOptions {
  cooperationsWithCounterparty: Option[];
  dueDiligenceDocuments: Option[];
  frequencies: Option[];
  recommendations: Option[];
  typesOfDD: Option[];
  ddcTypes: Option[];
  domicileGroups: Option[];
}

export type Changeset = {
  [key: string]: { previousValue: any; newValue: any };
};

export interface LogItem {
  id: string;
  email: string;
  status: string;
  changeset: Changeset;
  date: Date;
}

// UI model
export interface DDC {
  acolinCounterparties: Option[];
  additionalMeasures: string;
  contracts: Option[];
  cooperationWithCounterparty: Option;
  classification?: string;
  comments: string;
  counterpartyName: string;
  counterpartyId: string;
  domicile: string;
  domicileISOCode: string;
  domicileGroup: string;
  submissionDate: Date;
  lastModified: Date;
  ddcType: Option;
  dueDiligenceConducted: boolean;
  worldCheckOneConducted: boolean;
  isRiskConfigurationSet: boolean;
  dueDiligenceDocuments: string[];
  links: Link[];
  frequency: Option;
  nextDDDate?: Date;
  recommendation: Option;
  relationshipToOtherParties: string;
  services: Option[];
  typeOfDd: Option;
  O: string;
  A: string;
  L: string;
  S: string;
  RM: string;
  C: string;
  D: string;
  arr: number;
  reasonForRiskChange: string;
  PEPUBO?: AMLCTFBinaryOption;
  countriesOfBusinessActivities: Country[];
  countryOfIncorporation: Country;
  countryOfResidence: Country;
  dealingWithCustomersSubjectToSanctions: AMLCTFBinaryOption;
  informationRegardingOtherRegulatoryFailings: AMLCTFBinaryOption;
  informationRelatedToFinancialCrime: AMLCTFBinaryOption;
  nationalityUBO?: Country;
  onSanctionList: AMLCTFBinaryOption;
  opaqueShareholdingStructure: AMLCTFBinaryOption;
  amlCtfScore: string;
  exemptFromUBOIdentification?: AMLCTFBinaryOption;
  applicableCountryOfResidence?: AMLCTFBinaryOption;
  UBO?: UBO[];
  riskConfiguration?: any;
}

export interface DDCResponse {
  acolinCounterparties: Option[];
  additionalMeasures: string;
  contracts: Option[];
  amlCtfAnswers: AMLCTFAnswers;
  amlCtfScore: string;
  arr: number;
  reasonForRiskChange: string;
  comments: string;
  cooperationWithCounterparty: Option;
  counterpartyId: string;
  counterpartyName: string;
  dueDiligenceConducted: boolean;
  ddcType: Option;
  domicile: string;
  domicileISOCode: string;
  domicileGroup: string;
  dueDiligenceDocuments: Option[];
  frequency: Option;
  id: string;
  isFromETL: boolean;
  isRiskConfigurationSet: boolean;
  links: Link[];
  lastModified: Date;
  log: LogItemResponse[];
  nextDDDate: Date;
  recommendation: Option;
  riskConfigCategories: RiskTemplateCategories;
  services: Option[];
  status: DDCStatus;
  submissionDate: Date;
  typeOfDD: Option;
  worldCheckOneConducted: boolean;
  wphgClassification?: string;
  relationshipToOtherParties: string;
  owner: UpdatedBy;
  amlctfConfiguration?: any;
  riskConfiguration?: any;
}

export interface DDCRequest {
  acolinCounterparties: string[];
  additionalMeasures: string;
  contractTypeCodes: string[];
  cooperationWithCounterpartyId: string;
  wphgClassification?: string;
  comments: string;
  counterpartyName: string;
  domicileISOCode: string;
  counterpartyId: string;
  domicile: string;
  domicileGroup: string;
  submissionDate: Date;
  ddcTypeCode: string;
  dueDiligenceConducted: boolean;
  worldCheckOneConducted: boolean;
  dueDiligenceDocumentsIds: string[];
  links: Link[];
  frequencyId: string;
  nextDDDate?: Date;
  recommendationId: string;
  relationshipToOtherParties: string;
  serviceIds: string[];
  typeOfDDId: string;
  riskConfigCategories: RiskTemplateCategories;
  arr: number;
  amlCtfAnswers?: AMLCTFAnswers;
  amlCtfScore: string;
}

export interface AMLCTFAnswers {
  PEPUBO?: AMLCTFBinaryOption | undefined;
  countriesOfBusinessActivities: Country[];
  countryOfIncorporation: Country;
  countryOfResidence: Country;
  dealingWithCustomersSubjectToSanctions: AMLCTFBinaryOption;
  informationRegardingOtherRegulatoryFailings: AMLCTFBinaryOption;
  informationRelatedToFinancialCrime: AMLCTFBinaryOption;
  nationalityUBO?: Country;
  onSanctionList: AMLCTFBinaryOption;
  opaqueShareholdingStructure: AMLCTFBinaryOption;
  exemptFromUBOIdentification?: AMLCTFBinaryOption;
  applicableCountryOfResidence?: AMLCTFBinaryOption;
  UBO?: UBO[];
}

export type UBO = {
  id: string;
  name: string;
  nationality: Country | null;
  countryOfTaxResidence: Country | null;
  isOnSanctionList: AMLCTFBinaryOption | undefined;
  isPEP: AMLCTFBinaryOption | undefined;
  isRCA?: AMLCTFBinaryOption | undefined;
  hasAdverseInformationRelatedToFinancialCrime: AMLCTFBinaryOption | undefined;
  details: string;
};

export type UBOModel = {
  omniContactId: string;
  firstName: string;
  lastName: string;
  statecode: number;
  statuscode: number;
  professionalStatus: string;
  percentOfShares: number;
  dateOfBirth: string;
  placeOfBirth: string;
  tin: string;
  pep: boolean;
  rca?: boolean;
  countryNationality: string;
  countryCodeNationality: string;
  countryTaxResidence: string;
  countryCodeTaxResidence: string;
};

export interface RiskTemplateCategories {
  O: string | null;
  A: string | null;
  L: string | null;
  S: string | null;
  RM: string | null;
  C: string | null;
  D: string | null;
}

export const ROLE_NAME_MANAGER = "MANAGER";
export const ROLE_NAME_OFFICER = "OFFICER";
export const DDC_STATUS_DRAFT = "DRAFT";
export const DDC_STATUS_PENDING = "PENDING";
export const DDC_STATUS_ACTIVE = "ACTIVE";
export const DDC_STATUS_OUTDATED = "OUTDATED";
export const DDC_STATUS_CANCELLED = "CANCELLED";
export const DDC_STATUS_ARCHIVED = "ARCHIVED";
export const DDC_STATUS_STUB = "STUB";
