import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { Error, Label } from "@progress/kendo-react-labels";
import {
  ErrorMessage,
  FastField,
  FieldArray,
  FieldAttributes,
  Form,
  Formik,
  FormikProps,
} from "formik";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { TextArea } from "@progress/kendo-react-inputs";
import styles from "./TierConfiguration.module.scss";
import { Button } from "@progress/kendo-react-buttons";
import Loading from "../../components/Loading";
import { COMPLIANCE_API_ORIGIN } from "../../config";
import { getErrorMessage } from "../../common/util";
import { UpdatedBy, Changeset, LogItem } from "../../common/ddc/types";
import {} from "../../components/Log/types";
import Log from "../../components/Log";
import * as yup from "yup";
import axiosInstance, {
  createCancelTokenSource,
  isRequestCanceled,
} from "../../api/axios";
import { isEqual } from "lodash";

interface Country {
  name: string;
  tier: number;
}

interface TierConfigChangeResponse {
  id: string;
  changeset: Changeset;
  updatedBy: UpdatedBy;
  comment: string;
  timestamp: string;
}

const getTierConfigChangesFromResponse = (
  items: TierConfigChangeResponse[]
): LogItem[] => {
  return items.map((item: TierConfigChangeResponse) => ({
    id: item.id,
    email: item.updatedBy.email,
    status: "",
    changeset: {
      ...item.changeset,
      comment: { previousValue: "", newValue: item.comment },
    },
    date: new Date(item.timestamp),
  }));
};

const TierConfiguration: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState<{
    countries: Country[];
  }>({ countries: [] });
  const [tierConfigChanges, setTierConfigChanges] = useState<LogItem[]>([]);

  useEffect(() => {
    const source = createCancelTokenSource();
    const getConfig = axiosInstance.get(
      `${COMPLIANCE_API_ORIGIN}/v1/configurations/tier`,
      { cancelToken: source.token }
    );
    const getConfigChanges = axiosInstance.get(
      `${COMPLIANCE_API_ORIGIN}/v1/configurations/tier/changes`,
      { cancelToken: source.token }
    );

    Promise.all([getConfig, getConfigChanges])
      .then((responses) => {
        setCountries({ countries: responses[0].data });
        setTierConfigChanges(
          getTierConfigChangesFromResponse(responses[1].data)
        );
        setLoading(false);
      })
      .catch((e) => {
        if (isRequestCanceled(e)) {
          return;
        }
        const message = getErrorMessage(e);
        alert(message);
        setLoading(false);
      });
    return () => source.cancel();
  }, []);

  const FormikDropDown = ({
    field,
    form,
    options,
    ...props
  }: FieldAttributes<any>) => {
    return (
      <DropDownList
        {...field}
        {...props}
        data={options}
        onChange={(event: DropDownListChangeEvent) => {
          const newValue = event.target.value;
          form.setFieldValue(field.name, newValue);
        }}
      />
    );
  };

  const FormikTextArea = ({
    field,
    form,
    disabled,
    ...props
  }: FieldAttributes<any>) => {
    return (
      <TextArea
        {...field}
        {...props}
        rows={3}
        disabled={disabled}
        onChange={(event) => {
          form.setFieldValue(field.name, event.value);
        }}
      />
    );
  };

  const schema = yup.object().shape({
    comment: yup
      .string()
      .required("Required")
      .max(1000, "Maximum size is 1000 characters"),
  });

  return (
    <div className={styles.root}>
      <h1>Tier Configuration</h1>
      {loading ? (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      ) : (
        <Formik
          initialValues={{ ...countries, comment: "" }}
          validationSchema={schema}
          onSubmit={async (values) => {
            setLoading(true);
            try {
              let response = await axiosInstance.put(
                `${COMPLIANCE_API_ORIGIN}/v1/configurations/tier`,
                {
                  tierConfiguration: {
                    countries: values.countries,
                    comment: values.comment,
                  },
                }
              );
              setCountries({
                countries: response.data.tierConfiguration,
              });
              response = await axiosInstance.get(
                `${COMPLIANCE_API_ORIGIN}/v1/configurations/tier/changes`
              );
              setTierConfigChanges(
                getTierConfigChangesFromResponse(response.data)
              );
              setLoading(false);
            } catch (error) {
              setLoading(false);
              const message = getErrorMessage(error);
              alert(message);
            }
          }}
        >
          {({ values, initialValues }: FormikProps<any>) => (
            <Form>
              {tierConfigChanges.length !== 0 && (
                <Card>
                  <CardHeader>
                    <CardTitle>Tier Configuration Changes</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Log items={tierConfigChanges} showStatus={false} />
                  </CardBody>
                </Card>
              )}
              <Card>
                <CardHeader>
                  <CardTitle>Countries</CardTitle>
                </CardHeader>
                <CardBody>
                  <table className={styles.table}>
                    <thead>
                      <tr className={styles.tableRow}>
                        <th>Country</th>
                        <th>Tier</th>
                      </tr>
                    </thead>
                    <tbody>
                      <FieldArray
                        name="countries"
                        render={() => (
                          <>
                            {values.countries.map(
                              (data: Country, index: number) => {
                                return (
                                  <tr
                                    key={data.name}
                                    className={styles.tableRow}
                                  >
                                    <td>
                                      <Label>{data.name}</Label>
                                    </td>
                                    <td>
                                      <FastField
                                        name={`countries[${index}].tier`}
                                        options={[1, 2, 3, 4, 5]}
                                        component={FormikDropDown}
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </>
                        )}
                      />
                    </tbody>
                  </table>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle>Comments</CardTitle>
                </CardHeader>
                <CardBody className={styles.commentsCardBody}>
                  <div className={styles.comments}>
                    <Error>
                      <ErrorMessage name="comment" />
                    </Error>
                    <FastField
                      name="comment"
                      component={FormikTextArea}
                      placeholder={"Please enter relevant information"}
                    />
                  </div>
                </CardBody>
              </Card>
              <div className={styles.submit}>
                <Button
                  type="submit"
                  className={styles.submit}
                  disabled={isEqual(initialValues.countries, values.countries)}
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default TierConfiguration;
