import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import Login from "./pages/Login";
import Home from "./pages/Home";
import "./App.scss";

import {
  AcolinMSALAuthProvider,
  useAuthContext,
} from "./auth/AcolinAuthContext";

import { useNotificationContext } from "./common/Context";
import { register } from "./common/workers";
import Reload from "./components/Reload";

const AppRoutes = React.memo(() => {
  const isAuthenticated = useIsAuthenticated();
  const [authState] = useAuthContext();
  return (
    <Switch>
      {isAuthenticated && authState.state === "AUTHORIZED" ? (
        <Home />
      ) : (
        <>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="*">
            <Redirect to="/login" />
          </Route>
        </>
      )}
    </Switch>
  );
});

function App() {
  const sendNotification = useNotificationContext();

  useEffect(() => {
    console.log("Register reload notification.");
    register({
      onUpdate: () => sendNotification({ type: "none", message: <Reload /> }),
    });
  }, [sendNotification]);

  return (
    <Router>
      <AcolinMSALAuthProvider>
        <AppRoutes />
      </AcolinMSALAuthProvider>
    </Router>
  );
}

export default App;
