import React from "react";

import SectionHeader from "../../SectionHeader";
import SectionFooter from "../../SectionFooter";

import styles from "./ExclusiveRiskScoringChangeReasons.module.scss";

import { RiskScoreChangeReasons } from "../../types";
import { Field, FieldArray } from "formik";
import { FormikTableInput } from "../../../../components/common/formik/FormikWrappers";

const ExclusiveRiskScoringChangeReasons: React.FC<{
  id: number;
  riskScoreChangeReasons: RiskScoreChangeReasons[];
}> = ({ id, riskScoreChangeReasons }) => {
  return (
    <>
      <SectionHeader id={id}>
        {`${id} Reasons for the change of risk scoring`}
      </SectionHeader>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Reason No.</th>
            <th>Distribution partner</th>
            <th>Reasons for a change</th>
          </tr>
        </thead>
        <tbody>
          <FieldArray
            name="exclusiveRiskScoreChangeReasons"
            render={() => (
              <>
                {riskScoreChangeReasons.length > 0 ? (
                  riskScoreChangeReasons.map((row, index) => (
                    <tr key={index}>
                      <td className={styles.index}>{index + 1}.</td>
                      <td>{row.distributorPartner}</td>
                      <td>
                        <Field
                          name={`exclusiveRiskScoreChangeReasons[${index}].reasons`}
                          placeholder="Risk score change reason"
                          component={FormikTableInput}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3}>None</td>
                  </tr>
                )}
              </>
            )}
          />
        </tbody>
      </table>
      <SectionFooter />
    </>
  );
};

export default ExclusiveRiskScoringChangeReasons;
