import React from "react";

import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartLegend,
} from "@progress/kendo-react-charts";
import SectionHeader from "../../SectionHeader";
import SectionFooter from "../../SectionFooter";
import { RiskScoring } from "../../types";

const RiskScoringChart: React.FC<{ id: number; riskScoring: RiskScoring }> = ({
  id,
  riskScoring,
}) => {
  return (
    <>
      <SectionHeader id={id}>{`${id} Risk scoring chart`}</SectionHeader>
      <p>
        The chart below shows each distribution partner and the respective risk
        scoring of the ongoing and previous due diligences. If the ongoing due
        diligence is still in progress, only the column of the latest due
        diligence appears.
      </p>
      <Chart
        style={{ width: "1000px" }}
        seriesColors={["#5cb7c7", "#0093ad"]}
        transitions={false}
      >
        <ChartLegend position="bottom" orientation="horizontal" />
        <ChartValueAxis>
          <ChartValueAxisItem labels={{ format: "{0}%" }} />
        </ChartValueAxis>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            categories={riskScoring.categories}
            labels={{ rotation: -45 }}
          />
        </ChartCategoryAxis>
        <ChartSeries>
          {riskScoring.series.map((item, idx) => (
            <ChartSeriesItem
              key={idx}
              type="column"
              data={item.values}
              name={item.category}
            />
          ))}
        </ChartSeries>
      </Chart>
      <SectionFooter />
    </>
  );
};

export default React.memo(RiskScoringChart);
