import React, { useCallback, useState } from "react";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { Error } from "@progress/kendo-react-labels";
import {
  Field,
  FieldArray,
  FormikProps,
  ErrorMessage,
  FieldAttributes,
  FormikValues,
} from "formik";
import { InputChangeEvent } from "@progress/kendo-react-inputs/dist/npm/input/interfaces/InputChangeEvent";

import styles from "./Links.module.scss";

import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import { Link } from "../../../common/ddc/types";

// TODO All formik wrapper components should probably
// be put inside of a separate module
const FormikInput = ({ field, ...props }: FieldAttributes<any>) => {
  return <Input {...field} {...props} autoComplete="off" />;
};

const emptyInputValues = {
  id: "",
  content: "",
  url: "",
};

const emptyInputErrors = {
  content: "",
  url: "",
};

const noLinks = (values: FormikValues) => {
  return values.links.length === 0;
};

const maxLinks = (values: FormikValues) => {
  return values.links.length === 5;
};

const requiredUrlSchema = yup.string().url().required();

const validateContent = (value: string): string => {
  return value ? "" : "Required";
};

const validateUrl = (value: string): string => {
  const isValidUrl = requiredUrlSchema.isValidSync(value);
  return isValidUrl ? "" : "URL is not valid";
};

const Links: React.FC<{
  readOnly: boolean;
  formikProps: FormikProps<any>;
}> = ({ readOnly, formikProps }) => {
  const [inputValues, setInputValues] = useState({
    ...emptyInputValues,
    id: uuidv4(),
  });
  const [inputErrors, setInputErrors] = useState(emptyInputErrors);

  const { values } = formikProps;

  const onContentInputChange = useCallback((e: InputChangeEvent) => {
    const value = e.target.value?.toString() ?? "";
    const error = validateContent(value);
    setInputValues((prev) => ({
      ...prev,
      content: value,
    }));
    setInputErrors((prev) => ({
      ...prev,
      content: error,
    }));
  }, []);

  const onUrlInputChange = useCallback((e: InputChangeEvent) => {
    const value = e.target.value?.toString() ?? "";
    const error = validateUrl(value);
    setInputValues((prev) => ({
      ...prev,
      url: value,
    }));
    setInputErrors((prev) => ({
      ...prev,
      url: error,
    }));
  }, []);

  const onLinkAdd = useCallback(
    (arrayHelpers) => {
      const contentError = validateContent(inputValues.content);
      const urlError = validateUrl(inputValues.url);

      if (contentError || urlError) {
        setInputErrors({
          content: contentError,
          url: urlError,
        });
      } else {
        arrayHelpers.push(inputValues);
        setInputValues({ ...emptyInputValues, id: uuidv4() });
        setInputErrors(emptyInputErrors);
      }
    },
    [inputValues]
  );

  return (
    <div className={styles.root}>
      <FieldArray
        name="links"
        render={(arrayHelpers) => (
          <>
            {readOnly && noLinks(values) && <span>No links</span>}

            {values.links.map((item: Link, index: number) => {
              return (
                <div key={item.id} className={styles.row}>
                  <Label className={styles.label}>Content:</Label>

                  {readOnly ? (
                    <span className={styles.content}>{item.content}</span>
                  ) : (
                    <div className={styles.inputContainer}>
                      <Error>
                        <ErrorMessage name={`links[${index}].content`} />
                      </Error>
                      <Field
                        name={`links[${index}].content`}
                        placeholder="Place URL content"
                        component={FormikInput}
                      />
                    </div>
                  )}

                  <Label className={styles.label}>Url:</Label>

                  {readOnly ? (
                    <span className={styles.url}>
                      <a href={item.url} rel="noreferrer" target="_blank">
                        {item.url}
                      </a>
                    </span>
                  ) : (
                    <>
                      <div className={styles.inputContainer}>
                        <Error>
                          <ErrorMessage name={`links[${index}].url`} />
                        </Error>
                        <Field
                          name={`links[${index}].url`}
                          placeholder="Place connected URL here"
                          component={FormikInput}
                        />
                      </div>

                      <Button
                        className={styles.button}
                        type="button"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        Remove
                      </Button>
                    </>
                  )}
                </div>
              );
            })}

            {!readOnly && (
              <div className={styles.row}>
                <Label className={styles.label}>Content:</Label>

                <div className={styles.inputContainer}>
                  {inputErrors.content && <Error>{inputErrors.content}</Error>}
                  <Input
                    className={styles.input}
                    placeholder="Place URL content"
                    type="text"
                    value={inputValues.content}
                    onChange={onContentInputChange}
                    disabled={maxLinks(values)}
                  />
                </div>

                <Label className={styles.label}>Url:</Label>

                <div className={styles.inputContainer}>
                  {inputErrors.url && <Error>{inputErrors.url}</Error>}
                  <Input
                    className={styles.input}
                    placeholder="Place connected URL here"
                    type="text"
                    value={inputValues.url}
                    onChange={onUrlInputChange}
                    disabled={maxLinks(values)}
                  />
                </div>

                <Button
                  className={styles.button}
                  type="button"
                  onClick={() => onLinkAdd(arrayHelpers)}
                  disabled={maxLinks(values)}
                >
                  Add
                </Button>
              </div>
            )}
          </>
        )}
      />
    </div>
  );
};

export default Links;
