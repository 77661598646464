import React, { useEffect, useState } from "react";

import { Button } from "@progress/kendo-react-buttons";
import { Form, Formik, FormikProps } from "formik";

import Loading from "../../components/Loading";
import TableOfContents from "./sections/TableOfContents";
import Cover from "./sections/Cover";
import DueDiligenceProcess from "./sections/DueDiligenceProcess";
import DistributionAgreements from "./sections/DistributionAgreements";
import DistributionNetworkChanges from "./sections/DistributionNetworkChanges";
import DistributionPartnersDueDiligence from "./sections/DistributionPartnersDueDiligence";
import RiskScoringChangeReasons from "./sections/RiskScoringChangeReasons";
import RiskScoringChart from "./sections/RiskScoringChart";
import MeasuresTaken from "./sections/MeasuresTaken";
import KYCAndPEP from "./sections/KYCAndPEP";
import HighRiskJurisdictions from "./sections/HighRiskJurisdictions";
import AMLCTFPolicies from "./sections/AMLCTFPolicies";
import ClientComplaints from "./sections/ClientComplaints";
import IdentifiedInfringements from "./sections/IdentifiedInfringements";
import TargetMarketReporting from "./sections/TargetMarketReporting";
import Violations from "./sections/Violations";
import DistributionPartnersMonitoring from "./sections/DistributionPartnersMonitoring";
import FATCARegistration from "./sections/FATCARegistration";
import FATCARegistrationCoverage from "./sections/FATCARegistrationCoverage";
import ExclusiveContracts from "./sections/ExclusiveContracts";
import ExclusiveRiskScoringChangeReasons from "./sections/ExclusiveRiskScoringChangeReasons";
import ExclusiveDueDilligenceRiskScoringChart from "./sections/ExclusiveDueDilligenceRiskScoringChart";
import ExclusiveTerminatedAgreements from "./sections/ExclusiveTerminatedAgreements";
import "hammerjs";

import styles from "./QCORTemplate.module.scss";

import { COMPLIANCE_API_ORIGIN } from "../../config";
import { getErrorMessage } from "../../common/util";
import * as yup from "yup";
import axiosInstance, {
  createCancelTokenSource,
  isRequestCanceled,
} from "../../api/axios";
import { useParams } from "react-router";
import LegalDisputes from "./sections/LegalDisputes";
import RejectedDistributionPartners from "./sections/RejectedDistributionPartners";
import OperationalErrors from "./sections/OperationalErrors";
import BusinessContinuity from "./sections/BusinessContinuity";

const QCORSchema = yup.object().shape({
  agreements: yup.object().shape({
    terminated: yup.array().of(
      yup.object().shape({
        terminationReason: yup.string().required("Required"),
      })
    ),
  }),
  riskScoringChangeReasons: yup.array().of(
    yup.object().shape({
      reasons: yup.string().required("Required"),
    })
  ),
  highRiskScoringPartners: yup.array().of(
    yup.object().shape({
      measuresTaken: yup.string().required("Required"),
    })
  ),
  exclusiveRiskScoreChangeReasons: yup.array().of(
    yup.object().shape({
      reasons: yup.string().required("Required"),
    })
  ),
});

const QCORTemplate: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);
  const [qcorData, setQCORData] = useState({ id: "", content: {} });
  const [tocItems, setTOCItems] = useState<string[]>([]);

  useEffect(() => {
    const source = createCancelTokenSource();
    axiosInstance
      .get(`${COMPLIANCE_API_ORIGIN}/v1/qcor/${id}`, {
        cancelToken: source.token,
      })
      .then((response) => {
        setQCORData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        if (isRequestCanceled(error)) {
          return;
        }
        const message = getErrorMessage(error);
        alert(message);
      });
    return () => source.cancel();
  }, [id]);

  useEffect(() => {
    if (qcorData && !loading) {
      const reportElement = document.querySelector("#report");
      // Select all h3 elements inside of report element, since
      // they represent section titles
      const headersNodeList = reportElement?.querySelectorAll("h3");
      const headers = headersNodeList ? Array.from(headersNodeList) : [];
      // Extract text from each header element
      const headerTexts = headers.map((header) => header.innerText);
      // First header inside of the report is TOC header, and we don't
      // need that item, so we have to remove it
      headerTexts.shift();
      setTOCItems(headerTexts);
    }
  }, [setTOCItems, loading, qcorData]);

  return (
    <>
      <div className={styles.root}>
        <h1 className={styles.title}>QCOR Template</h1>
        {loading ? (
          <div className={styles.loadingContainer}>
            <Loading />
          </div>
        ) : (
          <Formik
            initialValues={qcorData.content}
            onSubmit={(values) => {
              setLoading(true);
              axiosInstance
                .put(`${COMPLIANCE_API_ORIGIN}/v1/qcor/${qcorData.id}`, {
                  qcorSubmission: values,
                })
                .then((response) => {
                  setQCORData((prev) => ({
                    ...prev,
                    content: response.data.content,
                  }));
                  setLoading(false);
                })
                .catch((error) => {
                  const message = getErrorMessage(error);
                  alert(message);
                });
            }}
            validationSchema={QCORSchema}
          >
            {({ values }: FormikProps<any>) => (
              <Form>
                <Cover
                  quartal={values.quartal}
                  title="Quarterly Compliance Oversight"
                  showLogo={true}
                />
                {/**
                 * Whole QCOR content is wrapped inside of a table so that
                 * header is printed on every page. This is a workaround
                 * for missing css3 print media features. Read more:
                 * https://medium.com/@Idan_Co/the-ultimate-print-html-template-with-header-footer-568f415f6d2a
                 */}
                <table>
                  <thead>
                    <tr>
                      <th className={styles.header}>
                        <div className={styles.headerLogo} />
                        <div
                          className={styles.headerNote}
                        >{`Quarterly Compliance Oversight Report – Version ${values.quartal}`}</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td id="report">
                        <TableOfContents items={tocItems} />
                        <h1>{`Quarterly Compliance Oversight Report ${values.quartal}`}</h1>
                        <p>
                          As regulations are constantly evolving, investment
                          companies are required to closely monitor outsourced
                          distribution providers. In order to respond to the
                          demand for greater certainty and higher transparency,
                          ACOLIN Group Compliance has developed a quarterly
                          oversight report for our partners. This report
                          provides our partners with a detailed overview of the
                          relevant changes within our distribution network, such
                          as the completed due diligence and monitoring
                          activities and the measures taken to address them.
                        </p>
                        <DueDiligenceProcess id={1} />
                        <DistributionAgreements
                          id={2}
                          agreements={values.agreements}
                          quartal={values.quartal}
                        />
                        <DistributionNetworkChanges
                          id={3}
                          quartal={values.quartal}
                          previousQuartal={values.previousQuartal}
                          changes={values.distributionNetworkChanges}
                        />
                        <DistributionPartnersDueDiligence
                          id={4}
                          quartal={values.quartal}
                          dueDiligence={
                            values.distributionPartnersDueDiligence.dueDiligence
                          }
                          overview={
                            values.distributionPartnersDueDiligence.overview
                          }
                        />
                        <RiskScoringChangeReasons
                          id={5}
                          quartal={values.quartal}
                          changeReasons={values.riskScoringChangeReasons}
                        />
                        <RiskScoringChart
                          id={6}
                          riskScoring={values.riskScoring}
                        />
                        <MeasuresTaken
                          id={7}
                          quartal={values.quartal}
                          highRiskScoringPartners={
                            values.highRiskScoringPartners
                          }
                        />
                        <KYCAndPEP id={8} />
                        <HighRiskJurisdictions id={9} />
                        <AMLCTFPolicies id={10} />
                        <ClientComplaints
                          id={11}
                          quartal={values.quartal}
                          clientComplaints={values.clientComplaints}
                        />
                        <IdentifiedInfringements
                          id={12}
                          infrigements={values.infrigements}
                        />
                        <LegalDisputes
                          id={13}
                          legalDisputes={values.legalDisputes}
                        />
                        <RejectedDistributionPartners
                          id={14}
                          rejectedDistributionPartners={
                            values.rejectedDistributionPartners
                          }
                        />
                        <OperationalErrors
                          id={15}
                          quartal={values.quartal}
                          operationalErrors={values.operationalErrors}
                        />
                        <BusinessContinuity
                          id={16}
                          quartal={values.quartal}
                          businessContinuity={values.businessContinuity}
                        />
                        <TargetMarketReporting
                          id={17}
                          quartal={values.quartal}
                          distributions={values.distributions}
                        />
                        <Violations
                          id={18}
                          reportedConflicts={values.reportedConflicts}
                        />
                        <DistributionPartnersMonitoring id={19} />
                        <FATCARegistration id={20} />
                        <FATCARegistrationCoverage
                          id={21}
                          fatcaCoverage={values.fatcaCoverage}
                        />
                        <Cover
                          quartal={values.quartal}
                          title="Quarterly Compliance Oversight"
                          subtitle={"Exclusive version for XXX"}
                          breakBefore={true}
                        />
                        <ExclusiveContracts
                          id={22}
                          clientName={"XXX"}
                          exclusiveInformations={values.exclusiveInformations}
                        />
                        <ExclusiveRiskScoringChangeReasons
                          id={23}
                          riskScoreChangeReasons={
                            values.exclusiveRiskScoreChangeReasons
                          }
                        />
                        <ExclusiveDueDilligenceRiskScoringChart
                          id={24}
                          partners={
                            values.exclusiveDueDilligenceRiskScore
                              .partnersWithChangedRiskScore
                          }
                          riskScores={
                            values.exclusiveDueDilligenceRiskScore.riskScores
                          }
                        />
                        <ExclusiveTerminatedAgreements
                          id={25}
                          quartal={values.quartal}
                          terminatedAgreements={
                            values.exclusiveTerminatedAgreements
                          }
                        />
                        <div className={styles.controls}>
                          <Button type="submit">Save</Button>
                          <Button
                            type="button"
                            onClick={() => {
                              window.print();
                            }}
                          >
                            Print
                          </Button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};

export default QCORTemplate;
