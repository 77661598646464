import React from "react";
import { Button } from "@progress/kendo-react-buttons";

import styles from "./CSSFTemplate.module.scss";

import { v4 as uuidv4 } from "uuid";

interface CSSF {
  records: CSSFRecord[];
  isins: string[];
}

interface CSSFRecord {
  id: string;
  distributionPartner: string;
  domicile: string;
  classification: string;
  agreementType: string;
  distributionCountry: string;
  licenseType: string;
  fundNotification: string;
  initialDueDiligenceConducted: string;
  dateOfPreviousDueDiligence: string;
  dateOfCurrentDueDiligence: string;
  dueDiligenceFrequency: string;
  previousRiskScoring: string;
  currentRiskScoring: string;
  reasonsForChangeOfRiskScoring: string;
  complaints: string;
  reportedRegulatoryIssues: string;
  negativeTargetMarketReporting: string;
  yeHoldings: string[];
}

const records = Array<CSSFRecord>(5)
  .fill({
    id: "",
    distributionPartner: "string",
    domicile: "string",
    classification: "string",
    agreementType: "Something",
    distributionCountry: "string",
    licenseType: "string",
    fundNotification: "string",
    initialDueDiligenceConducted: "string",
    dateOfPreviousDueDiligence: "string",
    dateOfCurrentDueDiligence: "string",
    dueDiligenceFrequency: "string",
    previousRiskScoring: "string",
    currentRiskScoring: "string",
    reasonsForChangeOfRiskScoring: "string",
    complaints: "string",
    reportedRegulatoryIssues: "string",
    negativeTargetMarketReporting: "string",
    yeHoldings: [
      "isin1",
      "isin2",
      "isin3",
      "isin4",
      "isin5",
      "isin6",
      "isin7",
      "isin8",
      "isin9",
      "isin0",
    ],
  })
  .map((item) => ({ ...item, id: uuidv4() }));

const data: CSSF = {
  isins: [
    "LU1785301511",
    "LU1785301512",
    "LU1785301513",
    "LU1785301514",
    "LU1785301515",
    "LU1785301516",
    "LU1785301517",
    "LU1785301518",
    "LU1785301519",
    "LU1785301510",
  ],
  records: records,
};

const CSSFTemplate2 = () => {
  const maxNumberOfISINs = data.isins.length;

  return (
    <div className={styles.root}>
      <h1 className={styles.title}>CSSF Template</h1>
      <div className={styles.printButtonContainer}>
        <Button onClick={() => window.print()}>Export</Button>
      </div>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>
                <span>Nr.</span>
              </th>
              <th>
                <span>Distribution Partner</span>
              </th>
              <th>
                <span>Domicile of Distribution Partner</span>
              </th>
              <th>
                <span>
                  Classification of Relationship in accordance with CSSF
                  Circular 18/698
                </span>
              </th>
              <th>
                <span>Agreement Type</span>
              </th>
              <th>
                <span>Distribution Country</span>
              </th>
              <th>
                <span>License Type</span>
              </th>
              <th>
                <span>Fund notification</span>
              </th>
              <th>
                <span>Initial Due Dilligence conducted</span>
              </th>
              <th colSpan={2}>
                <div>
                  <div className={styles.topSubHeader}>
                    <span>Ongoing Due Diligence</span>
                  </div>
                  <div className={styles.bottomSubHeader}>
                    <div className={styles.bottomSubHeaderCell}>
                      <span>Date of Previous Due Dilience</span>
                    </div>
                    <div className={styles.bottomSubHeaderCell}>
                      <span>Date of Current Due Diligence</span>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <span>Due Diligence Frequency (Months)</span>
              </th>
              <th>
                <span>Previous Risk Scoring</span>
              </th>
              <th>
                <span>Current Risk Scoring</span>
              </th>
              <th>
                <span>Reasons for change of Risk Scoring</span>
              </th>
              <th>
                <span>Complaints received within the reporting period</span>
              </th>
              <th>
                <span>Reported regulatory / investigation issues</span>
              </th>
              <th>
                <span>Negative Target Market Reporting</span>
              </th>
              <th colSpan={maxNumberOfISINs}>
                <div>
                  <div className={styles.topSubHeader}>
                    <span>YE Holdings 2020</span>
                  </div>
                  <div className={styles.bottomSubHeader}>
                    {data.isins.map((item) => (
                      <div key={item} className={styles.bottomSubHeaderCell}>
                        <div>
                          <span>{item}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.records.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.distributionPartner}</td>
                <td>{item.domicile}</td>
                <td className={styles.wideColumn}>{item.classification}</td>
                <td>{item.agreementType}</td>
                <td>{item.distributionCountry}</td>
                <td>{item.licenseType}</td>
                <td>{item.fundNotification}</td>
                <td>{item.initialDueDiligenceConducted}</td>
                <td>{item.dateOfPreviousDueDiligence}</td>
                <td>{item.dateOfCurrentDueDiligence}</td>
                <td>{item.dueDiligenceFrequency}</td>
                <td>{item.previousRiskScoring}</td>
                <td>{item.currentRiskScoring}</td>
                <td>{item.reasonsForChangeOfRiskScoring}</td>
                <td>{item.complaints}</td>
                <td>{item.reportedRegulatoryIssues}</td>
                <td>{item.negativeTargetMarketReporting}</td>
                {item.yeHoldings.map((isin) => (
                  <td key={isin}>{isin}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CSSFTemplate2;
