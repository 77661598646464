import React from "react";

import SectionHeader from "../../SectionHeader";
import SectionFooter from "../../SectionFooter";

const FATCARegistration: React.FC<{ id: number }> = ({ id }) => {
  return (
    <>
      <SectionHeader id={id}>
        {`${id} FATCA registration countries of distribution partners`}
      </SectionHeader>
      <p>
        Please find the list of the current FATCA registration countries of the
        distribution partner under the following link:
        <br />
        <a
          href="https://home.treasury.gov/policy-issues/tax-policy/foreign-account-tax-compliance-act"
          rel="noreferrer"
          target="_blank"
        >
          https://home.treasury.gov/policy-issues/tax-policy/foreign-account-tax-compliance-act
        </a>
      </p>
      <SectionFooter />
    </>
  );
};

export default React.memo(FATCARegistration);
