import { RiskScore } from "./types";

// Caller should provide styles object with required classes
export const getRiskCellStyle = (riskScore: RiskScore, styles: any) => {
  if (riskScore.status === "inProgress") {
    return styles.whiteBackground;
  }

  if (riskScore.value <= 19) {
    return styles.darkGreenBackground;
  } else if (riskScore.value <= 24) {
    return styles.greenBackground;
  } else if (riskScore.value <= 29) {
    return styles.lightGreenBackground;
  } else if (riskScore.value <= 34) {
    return styles.yellowBackground;
  } else if (riskScore.value <= 39) {
    return styles.orangeBackground;
  } else if (riskScore.value <= 44) {
    return styles.darkOrangeBack;
  } else if (riskScore.value <= 47) {
    return styles.darkerOrangeBackground;
  } else {
    return styles.redBackground;
  }
};
