import { DataResult, process, State } from "@progress/kendo-data-query";
import {
  setExpandedState,
  setGroupIds,
} from "@progress/kendo-react-data-tools";
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridExpandChangeEvent,
  GridRowClickEvent,
} from "@progress/kendo-react-grid";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axiosInstance, {
  createCancelTokenSource,
  isRequestCanceled,
} from "../../api/axios";
import {
  getErrorMessage,
  formatAsDate,
  formatAsQuartal,
} from "../../common/util";
import Loading from "../../components/Loading";
import { COMPLIANCE_API_ORIGIN } from "../../config";

import styles from "./WhatsNew.module.scss";

const initialDataState: State = {
  group: [{ field: "counterparty" }, { field: "ddc" }],
};

const processWithGroups = (data: any[], dataState: State) => {
  const newDataState = process(data, dataState);
  setGroupIds({ data: newDataState.data, group: dataState.group });
  return newDataState;
};

const PAGE_SIZE = 20;

const WhatsNew: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [expiredDDCS, setExpiredDDCS] = useState<any[]>([]);

  const history = useHistory();

  useEffect(() => {
    const source = createCancelTokenSource();
    Promise.all([
      axiosInstance.get(`${COMPLIANCE_API_ORIGIN}/v1/check-tier-risk`, {
        cancelToken: source.token,
      }),
      axiosInstance.get(
        `${COMPLIANCE_API_ORIGIN}/v1/expired-ddcs?offsetMonths=3`,
        {
          cancelToken: source.token,
        }
      ),
    ])
      .then(([{ data: expiredTiers }, { data: ddcs }]) => {
        const remappedDDCS = ddcs
          .map((ddc: any) => {
            const { id, counterpartyId, counterpartyName, nextDDDate } = ddc;
            const expiryDate: Date = new Date(nextDDDate);
            const isExpired: boolean =
              new Date().getTime() > expiryDate.getTime();
            return {
              id,
              counterpartyId,
              counterpartyName,
              expiryDate,
              expiryQuartal: formatAsQuartal(expiryDate),
              isExpired,
            };
          })
          .sort(
            (first: any, second: any) =>
              first.expiryDate.getTime() - second.expiryDate.getTime()
          );
        setExpiredDDCS(remappedDDCS);
        const remappedExpiredTiers = expiredTiers?.flatMap((item: any) => {
          return item.answers?.map((answer: any) => {
            const { id, omniId, name, status } = item;
            const [question, countryChange] = answer?.split(":");
            return {
              counterparty: `${name} (${omniId})`,
              ddc: `${status}`,
              id,
              counterpartyId: omniId,
              status,
              question,
              countryChange,
            };
          });
        });
        const groupIds = remappedExpiredTiers?.map(
          ({ counterparty }: { counterparty: string }) =>
            `${counterparty}counterparty`
        );
        setCollapsedState(groupIds);
        setExpiredTiers(
          processWithGroups(remappedExpiredTiers, initialDataState)
        );
      })
      .catch((e) => {
        if (isRequestCanceled(e)) {
          return;
        }
        const message = getErrorMessage(e);
        alert(message);
      })
      .finally(() => {
        setLoading(false);
      });
    return () => source.cancel();
  }, []);

  const [expiredTiers, setExpiredTiers] = React.useState<DataResult>();
  const [collapsedState, setCollapsedState] = React.useState<string[]>([]);

  const onExpandChange = React.useCallback(
    (event: GridExpandChangeEvent) => {
      const item = event.dataItem;

      if (item.groupId) {
        const collapsedIds = !event.value
          ? [...collapsedState, item.groupId]
          : collapsedState.filter((groupId) => groupId !== item.groupId);
        setCollapsedState(collapsedIds);
      }
    },
    [collapsedState]
  );

  const newData = setExpandedState({
    data: expiredTiers?.data ?? [],
    collapsedIds: collapsedState,
  });

  const onRowClick = useCallback(
    (e: GridRowClickEvent) => {
      const ddcId = e.dataItem.id;
      const counterpartyId = e.dataItem.counterpartyId;
      if (ddcId && counterpartyId) {
        history.push(`/ddc/review/${counterpartyId}/${ddcId}`);
      }
    },
    [history]
  );

  const ExpiryDateField = (props: GridCellProps) => {
    const { expiryDate, isExpired } = props.dataItem;
    const formattedDate = formatAsDate(expiryDate);
    return (
      <td style={{ color: isExpired ? "red" : "orange" }}>{formattedDate}</td>
    );
  };

  const [page, setPage] = useState({
    take: PAGE_SIZE,
    skip: 0,
  });

  return (
    <div className={styles.root}>
      <h1>What's New</h1>
      {loading ? (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      ) : (
        <>
          <Card>
            <CardHeader>
              <CardTitle>Review DDC</CardTitle>
            </CardHeader>
            <CardBody>
              <Grid
                resizable
                data={expiredDDCS.slice(page.skip, page.take + page.skip)}
                total={expiredDDCS?.length}
                pageable
                pageSize={PAGE_SIZE}
                {...page}
                onRowClick={onRowClick}
                onPageChange={(e) => {
                  setPage(e.page);
                }}
              >
                <Column
                  field="counterpartyId"
                  title="Counterparty Id"
                  width={200}
                  minResizableWidth={200}
                />
                <Column
                  field="counterpartyName"
                  title="Counterparty Name"
                  minResizableWidth={200}
                />
                <Column
                  field="nextDDDate"
                  title="Expiry Date"
                  format="{0:dd/MM/yyyy}"
                  minResizableWidth={200}
                  cell={ExpiryDateField}
                />
                <Column
                  field="expiryQuartal"
                  title="Expiry Quartal"
                  minResizableWidth={200}
                />
              </Grid>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Outdated Country Tiers</CardTitle>
            </CardHeader>
            <CardBody>
              <Grid
                resizable
                groupable
                data={newData}
                total={expiredTiers?.total}
                {...initialDataState}
                onExpandChange={onExpandChange}
                onRowClick={onRowClick}
                expandField="expanded"
              >
                <Column
                  field="question"
                  title="Question"
                  minResizableWidth={200}
                />
                <Column
                  field="countryChange"
                  title="Country change"
                  minResizableWidth={200}
                />
              </Grid>
            </CardBody>
          </Card>
        </>
      )}
    </div>
  );
};

export default WhatsNew;
