import React, { useState, useCallback, ChangeEvent } from "react";

import { ArrayHelpers, FieldArray } from "formik";
import SectionHeader from "../../SectionHeader";
import SectionFooter from "../../SectionFooter";

import styles from "./TargetMarketReporting.module.scss";

import { v4 as uuidv4 } from "uuid";
import { Distribution } from "../../types";
import TableInput from "../../TableInput";

const emptyInputValues = {
  id: "",
  nameOfDistributor: "",
  measuresTaken: "",
  editable: true,
};

const emptyInputErrors = {
  nameOfDistributor: "",
  measuresTaken: "",
};

const validateInput = (value: string) => {
  return value ? "" : "Required";
};

const DistributionsTable: React.FC<{
  distributions: Distribution[];
}> = React.memo(({ distributions }) => {
  const [inputValues, setInputValues] = useState({
    ...emptyInputValues,
    id: uuidv4(),
  });
  const [inputErrors, setInputErrors] = useState(emptyInputErrors);
  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    const error = validateInput(value);
    setInputValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setInputErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  }, []);

  const onAddClick = (arrayHelpers: ArrayHelpers) => {
    const nameError = validateInput(inputValues.nameOfDistributor);
    const measuresError = validateInput(inputValues.measuresTaken);

    const allInputsValid = nameError === "" && measuresError === "";

    if (allInputsValid) {
      arrayHelpers.push(inputValues);
      setInputValues({ ...emptyInputValues, id: uuidv4() });
      setInputErrors(emptyInputErrors);
    } else {
      setInputErrors({
        nameOfDistributor: nameError,
        measuresTaken: measuresError,
      });
    }
  };

  return (
    <>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>No.</th>
            <th>Name of distributor</th>
            <th>Measures taken</th>
          </tr>
        </thead>
        <tbody>
          <FieldArray
            name="distributions"
            render={(arrayHelpers) => (
              <>
                {distributions.length > 0 ? (
                  distributions.map((row, index) => (
                    <tr key={row.id}>
                      <td className={styles.index}>{index + 1}.</td>
                      <td>{row.nameOfDistributor}</td>
                      <td>
                        {row.measuresTaken}
                        {row.editable && (
                          <button
                            className={styles.remove}
                            type="button"
                            onClick={() => {
                              arrayHelpers.remove(index);
                            }}
                          >
                            -
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td />
                    <td colSpan={2}>None</td>
                  </tr>
                )}
                <tr className={styles.inputRow}>
                  <td />
                  <td>
                    <TableInput
                      name="nameOfDistributor"
                      placeholder="Name"
                      value={inputValues.nameOfDistributor}
                      error={inputErrors.nameOfDistributor}
                      onChange={handleChange}
                    />
                  </td>
                  <td className={styles.inputRow}>
                    <TableInput
                      name="measuresTaken"
                      placeholder="Measures taken"
                      value={inputValues.measuresTaken}
                      error={inputErrors.measuresTaken}
                      onChange={handleChange}
                    />
                    <button
                      className={styles.add}
                      type="button"
                      onClick={() => onAddClick(arrayHelpers)}
                    >
                      +
                    </button>
                  </td>
                </tr>
              </>
            )}
          />
        </tbody>
      </table>
    </>
  );
});

const TargetMarketReporting: React.FC<{
  id: number;
  quartal: string;
  distributions: Distribution[];
}> = ({ id, quartal, distributions }) => {
  return (
    <>
      <SectionHeader id={id}>{`${id} Target Market reporting`}</SectionHeader>
      <p>
        ACOLIN ensures that all counterparties comply with the obligations
        regarding target market reporting according to the MIFID II
        requirements. If distribution has taken place outside of a positive
        target market or into a negative target market, partners are obliged to
        inform ACOLIN immediately. For negative market reporting, we shared the
        following email address with all our distribution partners:
        targetmarket@acolin.com
      </p>
      <p>
        Based on the MiFID II Product Governance requirements there are two
        reporting types applicable to ACOLIN:
      </p>
      <ol>
        <li>
          NIL return –During the reporting period there have been no sales into
          the negative target market or salesinto negative distribution channel;
        </li>
        <li>
          Exceptions only reporting –Only report total sales and sales into
          negative target market or negative distribution channel.
        </li>
      </ol>
      <p>
        The table below shows all reported distributions outside of the positive
        target market in {quartal}.
      </p>
      <DistributionsTable distributions={distributions} />
      <p>
        Regarding the reporting into the negative target market or outside of
        the positive target market other distribution partners confirmed no
        violations or did not report anything.
      </p>
      <SectionFooter />
    </>
  );
};

export default React.memo(TargetMarketReporting);
