import { Button, ButtonProps } from "@progress/kendo-react-buttons";
import { DateRangePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { FilterDescriptor } from "@progress/kendo-react-dropdowns/dist/npm/common/filterDescriptor";
import {
  Checkbox,
  CheckboxChangeEvent,
  Input,
} from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { debounce } from "lodash";
import React, { useState, useMemo, useCallback } from "react";
import { incrementDay } from "../../../common/util";
import { Option } from "../../../components/common/formik/FormikWrappers";
import styles from "./OverviewFilter.module.scss";
import { FilterOptions, FilterQueryValues, FilterValues } from "../types";

const ResetFilterButton = (props: ButtonProps) => {
  return (
    <Button className={styles.resetFilterItem} {...props}>
      <span className="k-icon k-i-filter-clear" />
    </Button>
  );
};

const initialFilterValues: FilterValues = {
  counterparty: null,
  ddcType: null,
  assignedUser: null,
  statuses: [],
  acolinCounterparties: [],
  recommendations: [],
  services: [],
  submissionDateRange: {
    start: null,
    end: null,
  },
  lastModifiedDateRange: {
    start: null,
    end: null,
  },
  dueDateRange: {
    start: null,
    end: null,
  },
  arrRange: { start: "", end: "" },
  hideArchived: true,
};

const OverviewFilter: React.FC<{
  filterOptions: FilterOptions;
  setFilterQueryValues: (filterQueryValues: FilterQueryValues) => void;
  resetFilterQueryValues: () => void;
  resetGridState: () => void;
}> = ({
  filterOptions,
  setFilterQueryValues,
  resetFilterQueryValues,
  resetGridState,
}) => {
  const [counterpartyIdOrName, setCounterpartyIdOrName] = useState("");

  const [filterValues, setFilterValues] = useState<FilterValues>(
    initialFilterValues
  );

  const archivedDDCStatusId = useMemo(() => {
    const { statuses } = filterOptions;
    const status = statuses.find((status) => status.name === "ARCHIVED");
    return status ? status.id : null;
  }, [filterOptions]);

  const filteredCounterparties = React.useMemo(() => {
    if (counterpartyIdOrName) {
      const lowerCaseFilter = counterpartyIdOrName.toLowerCase();
      return filterOptions.companies.filter(
        (item) =>
          item.name.toLowerCase().includes(lowerCaseFilter) ||
          item.id.includes(lowerCaseFilter)
      );
    } else {
      return filterOptions.companies;
    }
  }, [filterOptions.companies, counterpartyIdOrName]);

  const [counterpartyIdOrNameFilter, setCounterpartyIdOrNameFilter] = useState<
    FilterDescriptor | undefined
  >();

  const debouncedSetCounterpartyIdOrName = useMemo(
    () =>
      debounce(
        (counterpartyIdOrName) => setCounterpartyIdOrName(counterpartyIdOrName),
        300
      ),
    []
  );

  const applyFilters = useCallback(() => {
    // Date range picker component returns Date objects
    // with time set to 00:00:00 of the selected day,
    // in the local timezone.
    // Since we want dates to be inclusive, we have to
    // add one day to the end dates.
    // Also note that since the dates are created with
    // local timezone, if 5.9. is selected in Belgrade,
    // server will receive 4.9. 22:00, because that's
    // the UTC represantation of the selected time on
    // the client side.
    let lastModifiedEnd = incrementDay(filterValues.lastModifiedDateRange.end);
    let submissionDateEnd = incrementDay(filterValues.submissionDateRange.end);
    let dueDateEnd = incrementDay(filterValues.dueDateRange.end);

    const filterQueryValues: FilterQueryValues = {
      counterpartyId: filterValues.counterparty?.id,
      ddcTypeCode: filterValues.ddcType?.id,
      serviceIds: filterValues.services.map((service) => service.id),
      ownerId: filterValues.assignedUser?.id,
      statusIds: filterValues.statuses.map((status) => status.id),
      acolinCounterparties: filterValues.acolinCounterparties.map(
        (counterparty) => counterparty.id
      ),
      recommendationIds: filterValues.recommendations.map(
        (recommendation) => recommendation.id
      ),
      arrMin:
        filterValues.arrRange.start === ""
          ? undefined
          : filterValues.arrRange.start,
      arrMax:
        filterValues.arrRange.end === ""
          ? undefined
          : filterValues.arrRange.end,
      submissionDateStart: filterValues.submissionDateRange.start ?? undefined,
      submissionDateEnd,
      lastModifiedStart: filterValues.lastModifiedDateRange.start ?? undefined,
      lastModifiedEnd,
      dueDateStart: filterValues.dueDateRange.start ?? undefined,
      dueDateEnd,
      hideStatusId:
        filterValues.hideArchived && archivedDDCStatusId
          ? archivedDDCStatusId
          : undefined,
    };

    resetGridState();
    setFilterQueryValues(filterQueryValues);
  }, [filterValues, setFilterQueryValues, resetGridState, archivedDDCStatusId]);

  const resetFilters = useCallback(() => {
    resetGridState();
    setFilterValues(initialFilterValues);
    resetFilterQueryValues();
  }, [resetFilterQueryValues, resetGridState]);

  return (
    <>
      <div className={styles.filter}>
        <div className={styles.filterContent}>
          <div className={styles.filterItem}>
            <Label className={styles.filterItemLabel}>Name or ID:</Label>
            <DropDownList
              data={filteredCounterparties}
              value={filterValues.counterparty}
              dataItemKey="id"
              textField="name"
              filterable={true}
              filter={counterpartyIdOrNameFilter?.value}
              onFilterChange={(e) => {
                setCounterpartyIdOrNameFilter(e.filter);
                debouncedSetCounterpartyIdOrName(e.filter?.value);
              }}
              onChange={(e) => {
                setFilterValues((prevState) => ({
                  ...prevState,
                  counterparty: e.value,
                }));
              }}
              focusedItemIndex={(data: Option[], inputText: string) => {
                const lowerCaseInput = inputText.toLowerCase();
                return data.findIndex(
                  (item) =>
                    item.id.includes(lowerCaseInput) ||
                    item.name.toLowerCase().includes(lowerCaseInput)
                );
              }}
            />
            <ResetFilterButton
              onClick={() => {
                setFilterValues((prevState) => ({
                  ...prevState,
                  counterparty: null,
                }));
              }}
              disabled={!filterValues.counterparty}
            />
          </div>
          <div className={styles.filterItem}>
            <Label className={styles.filterItemLabel}>Type:</Label>
            <DropDownList
              data={filterOptions?.ddcTypes}
              value={filterValues.ddcType}
              dataItemKey="id"
              textField="name"
              onChange={(e) => {
                setFilterValues((prevState) => ({
                  ...prevState,
                  ddcType: e.value,
                }));
              }}
            />
            <ResetFilterButton
              onClick={() => {
                setFilterValues((prevState) => ({
                  ...prevState,
                  ddcType: null,
                }));
              }}
              disabled={!filterValues.ddcType}
            />
          </div>
          <div className={styles.filterItem}>
            <Label className={styles.filterItemLabel}>Service:</Label>
            <MultiSelect
              data={filterOptions?.serviceTypes}
              value={filterValues.services}
              dataItemKey="id"
              textField="name"
              onChange={(e) => {
                setFilterValues((prevState) => ({
                  ...prevState,
                  services: e.value,
                }));
              }}
            />
          </div>
          <div className={styles.filterItem}>
            <Label className={styles.filterItemLabel}>Owner:</Label>
            <DropDownList
              data={filterOptions?.users}
              value={filterValues.assignedUser}
              dataItemKey="id"
              textField="name"
              onChange={(e) => {
                setFilterValues((prevState) => ({
                  ...prevState,
                  assignedUser: e.value,
                }));
              }}
            />
            <ResetFilterButton
              onClick={() => {
                setFilterValues((prevState) => ({
                  ...prevState,
                  assignedUser: null,
                }));
              }}
              disabled={!filterValues.assignedUser}
            />
          </div>
          <div className={styles.filterItem}>
            <Label className={styles.filterItemLabel}>Status:</Label>
            <MultiSelect
              data={filterOptions?.statuses}
              value={filterValues.statuses}
              dataItemKey="id"
              textField="name"
              onChange={(e) => {
                setFilterValues((prevState) => ({
                  ...prevState,
                  statuses: e.value,
                }));
              }}
            />
          </div>
          <div className={styles.filterItem}>
            <Label className={styles.filterItemLabel}>Counterparty:</Label>
            <MultiSelect
              data={filterOptions?.acolinCounterparties}
              value={filterValues.acolinCounterparties}
              dataItemKey="id"
              textField="name"
              onChange={(e) => {
                setFilterValues((prevState) => ({
                  ...prevState,
                  acolinCounterparties: e.value,
                }));
              }}
            />
          </div>
          <div className={styles.filterItem}>
            <Label className={styles.filterItemLabel}>Recommendation:</Label>
            <MultiSelect
              data={filterOptions?.recommendations}
              value={filterValues.recommendations}
              dataItemKey="id"
              textField="name"
              onChange={(e) => {
                setFilterValues((prevState) => ({
                  ...prevState,
                  recommendations: e.value,
                }));
              }}
            />
          </div>
          <div className={styles.filterItem}>
            <Label className={styles.filterItemLabel}>Reporting Date:</Label>
            <DateRangePicker
              value={filterValues.submissionDateRange}
              format="dd/MM/yyyy"
              onChange={(e) =>
                setFilterValues((prevState) => ({
                  ...prevState,
                  submissionDateRange: e.value,
                }))
              }
              max={new Date()}
            />
          </div>
          <div className={styles.filterItem}>
            <Label className={styles.filterItemLabel}>Modification Date:</Label>
            <DateRangePicker
              value={filterValues.lastModifiedDateRange}
              format="dd/MM/yyyy"
              onChange={(e) =>
                setFilterValues((prevState) => ({
                  ...prevState,
                  lastModifiedDateRange: e.value,
                }))
              }
              max={new Date()}
            />
          </div>
          <div className={styles.filterItem}>
            <Label className={styles.filterItemLabel}>Due Date:</Label>
            <DateRangePicker
              value={filterValues.dueDateRange}
              format="dd/MM/yyyy"
              onChange={(e) =>
                setFilterValues((prevState) => ({
                  ...prevState,
                  dueDateRange: e.value,
                }))
              }
            />
          </div>

          {archivedDDCStatusId && (
            <div className={styles.filterItem}>
              <Label className={styles.filterItemLabel}>Hide Archived:</Label>
              <Checkbox
                // name={field.name}
                checked={filterValues.hideArchived}
                onChange={(event: CheckboxChangeEvent) => {
                  setFilterValues((prevState) => ({
                    ...prevState,
                    hideArchived: event.value,
                  }));
                }}
              />
            </div>
          )}
          <div className={styles.filterItem}>
            <Label className={styles.filterItemLabel}>ARR:</Label>
            <Label className={styles.arrLabel}>Minimum:</Label>
            <Input
              maxLength={2}
              className={styles.arrInput}
              value={filterValues.arrRange.start}
              onChange={(e) => {
                const value = e.target.value?.toString() ?? "";
                const safeValue = value.replace(/\D/, "");
                setFilterValues((prevState) => ({
                  ...prevState,
                  arrRange: {
                    ...prevState.arrRange,
                    start: safeValue,
                  },
                }));
              }}
            />
            <Label className={styles.arrLabel}>Maximum:</Label>
            <Input
              maxLength={3}
              className={styles.arrInput}
              value={filterValues.arrRange.end}
              onChange={(e) => {
                const value = e.target.value?.toString() ?? "";
                const safeValue = value.replace(/\D/, "");
                setFilterValues((prevState) => ({
                  ...prevState,
                  arrRange: {
                    ...prevState.arrRange,
                    end: safeValue,
                  },
                }));
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.filterButtonsContainer}>
        <Button className={styles.filterButton} onClick={resetFilters}>
          Reset filters
        </Button>
        <Button className={styles.filterButton} onClick={applyFilters}>
          Apply filters
        </Button>
      </div>
    </>
  );
};

export default OverviewFilter;
