import React, { useEffect, useState } from "react";
import {
  Grid,
  GridColumn,
  GridRowClickEvent,
} from "@progress/kendo-react-grid";
import { State as GridState } from "@progress/kendo-data-query";
import { Card, CardBody } from "@progress/kendo-react-layout";

import { useHistory } from "react-router";
import axiosInstance, { createCancelTokenSource } from "../../api/axios";
import { COMPLIANCE_API_ORIGIN } from "../../config";

import styles from "./QCOROverview.module.scss";
import Loading from "../../components/Loading";
import { getErrorMessage } from "../../common/util";

const initialGridState: GridState = {
  skip: 0,
  take: 12,
};

const QCOROverview = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [gridState, setGridState] = useState(initialGridState);

  useEffect(() => {
    const source = createCancelTokenSource();
    axiosInstance
      .get(`${COMPLIANCE_API_ORIGIN}/v1/qcor`, {
        cancelToken: source.token,
      })
      .then((response) => {
        setReports(response.data);
      })
      .catch((e) => {
        const message = getErrorMessage(e);
        alert(message);
      })
      .finally(() => setLoading(false));
    return () => source.cancel();
  }, []);

  const onRowClick = (e: GridRowClickEvent) => {
    history.push(`/qcor/${e.dataItem.id}`);
  };

  return (
    <div className={styles.root}>
      <h1>QCOR Overview</h1>
      <Card>
        <CardBody>
          <div className={styles.gridContainer}>
            <Grid
              {...gridState}
              pageable
              data={reports}
              onRowClick={onRowClick}
              onDataStateChange={(e) => {
                setGridState(e.dataState);
              }}
              total={reports.length}
            >
              <GridColumn
                field="content.quartal"
                title="QCOR Report"
                minResizableWidth={100}
                sortable={false}
              />
            </Grid>
            {loading && (
              <div className={styles.scrim}>
                <Loading />
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default QCOROverview;
