import React, { ChangeEvent, useCallback, useMemo, useState } from "react";

import { ArrayHelpers, FieldArray } from "formik";
import SectionHeader from "../../SectionHeader";
import SectionFooter from "../../SectionFooter";

import styles from "./RejectedDistributionPartners.module.scss";

import { RejectedDistributionPartner } from "../../types";
import { v4 as uuidv4 } from "uuid";
import TableInput from "../../TableInput";

const validateInput = (value: string) => {
  return value ? "" : "Required";
};

const RejectedDistributionPartnersTable: React.FC<{
  rejectedDistributionPartners: RejectedDistributionPartner[];
}> = React.memo(({ rejectedDistributionPartners }) => {
  const emptyInputValues = useMemo(
    () => ({
      id: "",
      nameOfDistributor: "",
      reason: "",
      editable: true,
    }),
    []
  );

  const emptyInputErrors = useMemo(
    () => ({
      nameOfDistributor: "",
      reason: "",
    }),
    []
  );
  const [inputValues, setInputValues] = useState<RejectedDistributionPartner>({
    ...emptyInputValues,
    id: uuidv4(),
  });

  const [inputErrors, setInputErrors] = useState(emptyInputErrors);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    const error = validateInput(value);
    setInputValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setInputErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  }, []);

  const onAddClick = (arrayHelpers: ArrayHelpers) => {
    const nameOfDistributorError = validateInput(inputValues.nameOfDistributor);
    const reasonError = validateInput(inputValues.reason);

    const allInputsValid = nameOfDistributorError === "" && reasonError === "";

    if (allInputsValid) {
      arrayHelpers.push(inputValues);
      setInputValues({ ...emptyInputValues, id: uuidv4() });
      setInputErrors(emptyInputErrors);
    } else {
      setInputErrors({
        nameOfDistributor: nameOfDistributorError,
        reason: reasonError,
      });
    }
  };

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>No.</th>
          <th>Name of distributor</th>
          <th>Reason</th>
        </tr>
      </thead>
      <tbody>
        <FieldArray
          name="rejectedDistributionPartners"
          render={(arrayHelper) => (
            <>
              {rejectedDistributionPartners?.length > 0 ? (
                rejectedDistributionPartners.map((row, index) => (
                  <tr key={row.id}>
                    <td className={styles.index}>{index + 1}.</td>
                    <td>{row.nameOfDistributor}</td>
                    <td>
                      {row.reason}
                      {row.editable && (
                        <button
                          className={styles.remove}
                          type="button"
                          onClick={() => {
                            arrayHelper.remove(index);
                          }}
                        >
                          -
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td />
                  <td colSpan={2}>None</td>
                </tr>
              )}
              <tr className={styles.inputRow}>
                <td />
                <td className={styles.inputRow}>
                  <TableInput
                    name="nameOfDistributor"
                    placeholder="Name"
                    error={inputErrors.nameOfDistributor}
                    value={inputValues.nameOfDistributor}
                    onChange={handleChange}
                  />
                </td>
                <td className={styles.inputRow}>
                  <TableInput
                    name="reason"
                    placeholder="Reason"
                    value={inputValues.reason}
                    error={inputErrors.reason}
                    onChange={handleChange}
                  />
                  <button
                    className={styles.add}
                    type="button"
                    onClick={() => onAddClick(arrayHelper)}
                  >
                    +
                  </button>
                </td>
              </tr>
            </>
          )}
        />
      </tbody>
    </table>
  );
});

const RejectedDistributionPartners: React.FC<{
  id: number;
  rejectedDistributionPartners: RejectedDistributionPartner[];
}> = ({ id, rejectedDistributionPartners }) => {
  return (
    <>
      <SectionHeader
        id={id}
      >{`${id} Number of rejected distribution partner`}</SectionHeader>
      <p>
        Due to violations of money laundering and terrorist financing
        regulations, there may be significant sanctions that can be imposed on a
        Distributor. ACOLIN uses various sources to detect AML/CTF violations
        and adjust the risk rating. This may also result in ACOLIN refusing to
        enter a contract or terminating an existing contract.
      </p>
      <p>
        The following table provides a summary of all distributors whose
        contract was terminated or not completed due to AML/CTF violations.
      </p>
      <RejectedDistributionPartnersTable
        rejectedDistributionPartners={rejectedDistributionPartners}
      />
      <SectionFooter />
    </>
  );
};

export default React.memo(RejectedDistributionPartners);
