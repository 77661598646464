import React, { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import DDCForm from "../../components/DDCForm";

import { useAuthContext } from "../../auth/AcolinAuthContext";
import { useHistory, useParams } from "react-router-dom";
import { COMPLIANCE_API_ORIGIN } from "../../config";
import {
  LogItemResponse,
  ComponentState,
  FormData,
  CountryResponse,
  UBO,
  UBOModel,
} from "../../common/ddc/types";
import { LogItem } from "../../common/ddc/types";
import { getErrorMessage } from "../../common/util";
import {
  createInitialAmlCtfValues,
  createUBOs,
  mapResponseToCountry,
  responseToDDC,
} from "../../common/ddc/util";
import axiosInstance from "../../api/axios";
import { Option } from "../../components/common/formik/FormikWrappers";

const ReviewStub: React.FC = () => {
  const { counterpartyId, ddcId } = useParams<{
    counterpartyId: string;
    ddcId: string;
  }>();
  const history = useHistory();
  const [authState] = useAuthContext();
  const roleName = authState?.user?.role?.name;
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<FormData>();

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      axiosInstance.get(`${COMPLIANCE_API_ORIGIN}/v1/ddc/${ddcId}`),
      axiosInstance.get(`${COMPLIANCE_API_ORIGIN}/v1/ddc-options/`),
      axiosInstance.get(
        `${COMPLIANCE_API_ORIGIN}/v1/ddc-template/${counterpartyId}`
      ),
    ])
      .then((responses) => {
        const ddcResponse = responses[0].data;
        const UBOList: UBOModel[] = ddcResponse.UBOList;
        const ddc = responseToDDC(ddcResponse);
        const logItems: LogItem[] = ddcResponse.log
          .map((item: LogItemResponse) => ({
            id: item.id,
            email: item.updatedBy.email,
            status: item.status.status,
            changeset: item.changeset,
            date: new Date(item.timestamp),
          }))
          .sort(
            (first: LogItem, second: LogItem) =>
              second.date.getTime() - first.date.getTime()
          );

        const ddConfirmationState: ComponentState = "active";

        const amlConfirmationState: ComponentState = "hidden";

        // Submission date is reset/set to current date
        // on STUB review.
        ddc.submissionDate = new Date();

        // In phase one we cannot determine next DD date
        // upfront and it will be selected by the officers
        // during the DD process.
        ddc.nextDDDate = undefined;

        const ddcTemplate = responses[2].data;

        // TODO This should be done on the backend
        const countries = mapResponseToCountry(
          ddcTemplate.amlctfConfiguration.content.categories,
          // newConfig.categories,
          ddcTemplate.tierConfiguration.snapshot
        );

        const domicileISOCode = ddc.domicileISOCode
          ? ddc.domicileISOCode
          : ddcTemplate.accountInformation.domicileISOCode;

        const initialAmlCtfValues = createInitialAmlCtfValues(
          ddcTemplate.amlctfConfiguration.content.binary,
          domicileISOCode,
          countries.countryOfIncorporation
        );

        const country = ddcTemplate.tierConfiguration.snapshot.find(
          (country: any) => country.isoAlpha2Code === domicileISOCode
        );

        const defaultDomicileGroup: Option = country?.group
          ? { id: country.group, name: country.group }
          : { id: "EU/UK", name: "EU/UK" };

        const domicileGroup = ddc.domicileGroup
          ? { id: ddc.domicileGroup, name: ddc.domicileGroup }
          : defaultDomicileGroup;

        const amlCtfOptions = {
          categories: ddcTemplate.amlctfConfiguration.content.categories,
          binary: ddcTemplate.amlctfConfiguration.content.binary,
          countries,
          version: ddcTemplate.amlctfConfiguration.content.version,
        };

        const UBO: UBO[] = createUBOs(UBOList, amlCtfOptions);

        // Blend in data from the stored DDC and inital AML values
        // to create initial form values.
        let initialValues = {
          ...initialAmlCtfValues,
          ...ddc,
          UBO,
          domicileGroup,
          // This information is currently non existant for STUB DDCs.
          // Note that this is only for phase one, and will probably
          // change in the future.
          latestFrequency: undefined,
          latestNextDDDate: undefined,
        };

        const save = (formSubmission: any) => {
          const body: any = {
            formSubmission,
            amlctfConfigurationId: ddcTemplate.amlctfConfiguration.id,
            tierConfigurationId: ddcTemplate.tierConfiguration.id,
          };

          if (!ddc.isRiskConfigurationSet) {
            body.riskConfigurationId = ddcTemplate.riskConfiguration.id;
          }
          setIsLoading(true);
          return axiosInstance
            .put(`${COMPLIANCE_API_ORIGIN}/v1/ddc/${ddcId}`, body)
            .then((resp) => {
              setIsLoading(false);
              history.replace(`/ddc/review/${counterpartyId}/${ddcId}`);
              return resp;
            });
        };

        const submit = (formSubmission: any) => {
          const body: any = {
            formSubmission,
            amlctfConfigurationId: ddcTemplate.amlctfConfiguration.id,
            tierConfigurationId: ddcTemplate.tierConfiguration.id,
          };

          if (!ddc.isRiskConfigurationSet) {
            body.riskConfigurationId = ddcTemplate.riskConfiguration.id;
          }

          return axiosInstance.put(
            `${COMPLIANCE_API_ORIGIN}/v1/ddc/${ddcId}`,
            body,
            {
              params: {
                isSubmit: true,
              },
            }
          );
        };

        const cancel = (formSubmission: any) => {
          const body: any = {
            formSubmission,
            amlctfConfigurationId: ddcTemplate.amlctfConfiguration.id,
            tierConfigurationId: ddcTemplate.tierConfiguration.id,
          };
          if (!ddc.isRiskConfigurationSet) {
            body.riskConfigurationId = ddcTemplate.riskConfiguration.id;
          }
          return axiosInstance.put(
            `${COMPLIANCE_API_ORIGIN}/v1/ddc/${ddcId}`,
            body,
            {
              params: {
                action: "cancel",
              },
            }
          );
        };

        const domicileGroupsSet: Set<string> = ddcTemplate.tierConfiguration.snapshot.reduce(
          (acc: Set<string>, country: CountryResponse) => {
            if (country.group) {
              acc.add(country.group);
            }
            return acc;
          },
          new Set<string>()
        );

        const domicileGroups: Option[] = Array.from(
          domicileGroupsSet
        ).map((group) => ({ id: group, name: group }));

        setFormData({
          initialValues,
          logItems,
          showBack: true,
          showCancel: true,
          ddConfirmationState,
          amlConfirmationState,
          readOnly: false,
          riskConfigTemplates: ddc.isRiskConfigurationSet
            ? ddc.riskConfiguration.content
            : ddcTemplate.riskConfiguration.content,
          amlCtfOptions,
          options: {
            ...responses[1].data,
            domicileGroups,
          },
          save,
          submit,
          cancel,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        const message = getErrorMessage(error);
        alert(message);
      });
  }, [ddcId, roleName, counterpartyId]);

  return isLoading ? (
    <Loading />
  ) : formData ? (
    <DDCForm
      initialValues={formData.initialValues}
      logItems={formData.logItems}
      showTitle
      showBack={formData.showBack}
      showCancel={formData.showCancel}
      readOnly={formData.readOnly}
      ddConfirmationState={formData.ddConfirmationState}
      amlConfirmationState={formData.amlConfirmationState}
      riskConfigTemplates={formData.riskConfigTemplates}
      amlCtfOptions={formData.amlCtfOptions}
      options={formData.options}
      save={formData.save}
      submit={formData.submit}
      cancel={formData.cancel}
    />
  ) : (
    <></>
  );
};

export default ReviewStub;
