import React, { useEffect, useState, useContext, useCallback } from "react";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { msalInstance } from "./msal";
import {
  AuthenticationResult,
  IPublicClientApplication,
} from "@azure/msal-browser";
import { COMPLIANCE_API_ORIGIN } from "../config";
import axiosInstance from "../api/axios";

type AppUser = {
  fullName: string;
  email: string;
  role: { id: string; name: string } | undefined;
  token: string;
};

export interface AuthState {
  user?: AppUser;
  state: "IN PROGRESS" | "AUTHORIZED" | "UNAUTHORIZED" | "ERROR";
}

type AuthContextValue = [AuthState, { logout: () => void }];

const AuthContext = React.createContext<AuthContextValue>([
  { state: "IN PROGRESS" },
  { logout: () => {} },
]);

export const tokenRequest = { scopes: ["User.Read"] };

export async function acquireToken(
  msal: IPublicClientApplication
): Promise<AuthenticationResult> {
  try {
    return await msal.acquireTokenSilent(tokenRequest);
  } catch (e) {
    throw e;
  }
}

const AuthContextProvider: React.FC = ({ children }) => {
  const { instance: msalInstance } = useMsal();
  const [authState, setAuthState] = useState<AuthState>({
    state: "IN PROGRESS",
  });

  const logout = useCallback(() => {
    setAuthState({ state: "IN PROGRESS" });
    msalInstance
      .logout({ account: msalInstance.getActiveAccount() || undefined })
      .then((e) => console.log(e));
  }, [setAuthState, msalInstance]);

  // TODO: this is currently not working :(
  // useEffect(() => {
  //   const accounts = msalInstance.getAllAccounts();
  //   const acolinAccount = findAcolinAccount(accounts);
  //   if (acolinAccount) {
  //     try {
  //       msalInstance.setActiveAccount(acolinAccount);
  //       msalInstance.acquireTokenSilent(tokenRequest);
  //     } catch (e) {}
  //   }
  // }, []);

  useEffect(() => {
    acquireToken(msalInstance)
      .then(async (msalResponse) => {
        axiosInstance.defaults.headers[
          "Authorization"
        ] = `Bearer ${msalResponse.idToken}`;
        try {
          let userDetailsResponse = (
            await axiosInstance.get(`${COMPLIANCE_API_ORIGIN}/v1/user-info`)
          ).data;
          setAuthState({
            state: "AUTHORIZED",
            user: {
              fullName: msalResponse.account?.name as string,
              email: msalResponse.account?.username as string,
              role: userDetailsResponse.role,
              token: msalResponse.idToken,
            },
          });
        } catch (e: any) {
          if (e.response?.status === 404) {
            console.log("UNAUTHORIZED!");
            setAuthState({ state: "UNAUTHORIZED" });
          } else {
            console.log("AUTHORIZATION ERROR!");
            setAuthState({ state: "ERROR" });
            setTimeout(() => {
              logout();
            }, 5000);
          }
        }
      })
      .catch((e) => {
        // This catch will be executed multiple times
        // during the login procedure, so it's not usefull
        // to use it to set state to UNAUTHORIZED. We're
        // not currently aware of all usecases where this
        // callback will get executed, so we're ignoring it.
        console.log("Failed to acquire token", e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msalInstance.getActiveAccount()?.homeAccountId]);

  return (
    <AuthContext.Provider value={[authState, { logout }]}>
      {children}
    </AuthContext.Provider>
  );
};

export const AcolinMSALAuthProvider: React.FC<{}> = React.memo(
  ({ children }) => (
    <MsalProvider instance={msalInstance}>
      <AuthContextProvider>{children}</AuthContextProvider>
    </MsalProvider>
  )
);

export function useAuthContext() {
  return useContext(AuthContext);
}
