import React from "react";
import { DialogProps } from "./types";

import styles from "./Dialog.module.scss";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog as KendoDialog } from "@progress/kendo-react-dialogs";

const Dialog: React.FC<DialogProps> = ({
  title,
  children,
  type,
  positiveButtonText = "Yes",
  negativeButtonText = "No",
  onClose,
  onNegativeButtonClick,
  onPositiveButtonClick,
}) => {
  return (
    <KendoDialog title={title} onClose={onClose}>
      <p className={styles.text}>{children}</p>
      <div className={styles.controls}>
        <>
          {type === "choice" && (
            <Button
              type="button"
              className={styles.button}
              onClick={onNegativeButtonClick}
            >
              {negativeButtonText}
            </Button>
          )}
          <Button
            type="button"
            className={styles.button}
            onClick={onPositiveButtonClick}
          >
            {positiveButtonText}
          </Button>
        </>
      </div>
    </KendoDialog>
  );
};

export default Dialog;
