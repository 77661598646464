import React, { useCallback } from "react";
import { Button } from "@progress/kendo-react-buttons";

import "./Reload.scss";

const Reload: React.FC<{}> = () => {
  const handleClick = useCallback(() => window.location.reload(), []);

  return (
    <div className={"reload"} data-testid="Reload">
      <p>There's a new version of this app!</p>
      <Button primary onClick={handleClick}>
        Reload
      </Button>
    </div>
  );
};

export default Reload;
