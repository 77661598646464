import React from "react";

import SectionHeader from "../../SectionHeader";

import styles from "./TableOfContents.module.scss";

const TOCItem: React.FC<{ sectionNumber: number }> = ({
  children,
  sectionNumber,
}) => {
  return (
    <div>
      <a href={`#${sectionNumber + 1}`}>{children}</a>
    </div>
  );
};

const TableOfContents: React.FC<{ items: string[] }> = ({ items }) => {
  return (
    <div className={styles.root}>
      {/* Put TOC into table so it retains section name
       * on each page when toc is broken onto multiple pages */}
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <td>
              <SectionHeader id={0}>Table of Contents</SectionHeader>
            </td>
          </tr>
        </thead>
        <tbody>
          {items.map((item: string, index: number) => (
            <tr key={`${index}${item}`}>
              <td>
                <TOCItem sectionNumber={index}>{item}</TOCItem>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default React.memo(TableOfContents);
