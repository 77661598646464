import React, { memo } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { formatAsDate, isCounterpartyTerminated } from "../../common/util";

import styles from "./AccountInformation.module.scss";
import { useMemo } from "react";
import { Option } from "../common/formik/FormikWrappers";

const hasACEUCounterparty = (acolinCounterparties: string[]) => {
  return acolinCounterparties.includes("ACEU");
};

export const formatFrequency = (frequency: string, nextDDDate: Date) => {
  if (frequency === "Other") {
    return `${frequency} - ${formatAsDate(nextDDDate)}`;
  } else {
    return frequency;
  }
};

export const AccountInformation: React.FC<{
  counterpartyName: string;
  counterpartyId: string;
  domicile: string;
  ddcType?: string;
  services: string[];
  acolinCounterparties: string[];
  classification?: string;
  contracts: string[];
  frequency?: string;
  contacts?: string[];
  sharePointFolder?: string;
  nextDDDate: Date;
  status: Option;
  terminationDate?: Date;
  showOMNI?: boolean;
}> = memo((props) => {
  const contractsWithCounterparties = useMemo(
    () =>
      props.contracts.map(
        (contract, index) =>
          `${contract} - ${props.acolinCounterparties[index] || "N/A"}`
      ),
    [props.contracts, props.acolinCounterparties]
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle>Account Information {props?.showOMNI && "- OMNI"}</CardTitle>
      </CardHeader>
      <CardBody className={styles.root}>
        <div className={styles.item}>
          <dt>Counterparty Name:</dt>
          <dd>{props.counterpartyName}</dd>
        </div>
        <div className={styles.item}>
          <dt>ID Number:</dt>
          <dd>{props.counterpartyId}</dd>
        </div>
        <div
          className={`${styles.item} ${styles.vertical} ${styles.indentContent}`}
        >
          <dt>Acolin Services:</dt>
          <br />
          {props.services.length ? (
            props.services.map((service, index) => (
              <dd key={index}>{service}</dd>
            ))
          ) : (
            <dd>N/A</dd>
          )}
        </div>
        <div
          className={`${styles.item} ${styles.vertical} ${styles.indentContent}`}
        >
          <dt>Contract Types:</dt>
          <br />
          {contractsWithCounterparties.length ? (
            contractsWithCounterparties.map((contract, index) => (
              <dd key={index}>{contract}</dd>
            ))
          ) : (
            <dd>N/A</dd>
          )}
        </div>
        <div className={styles.item}>
          <dt>Domicile:</dt>
          <dd>{props.domicile || "N/A"}</dd>
        </div>
        {props.ddcType && (
          <div className={styles.item}>
            <dt>DDC Type:</dt>
            <dd>{props.ddcType}</dd>
          </div>
        )}
        <div className={styles.item}>
          <dt>Latest DD Frequency:</dt>
          <dd>
            {props.frequency
              ? formatFrequency(props.frequency, props.nextDDDate)
              : "N/A"}
          </dd>
        </div>
        {hasACEUCounterparty(props.acolinCounterparties) && (
          <div className={`${styles.item} ${styles.fullWidth}`}>
            <dt>Classification:</dt>
            <dd>{props.classification || "N/A"}</dd>
          </div>
        )}
        {props.status && (
          <div className={styles.item}>
            <dt>Status:</dt>
            <dd>{props.status.name}</dd>
          </div>
        )}
        {isCounterpartyTerminated(props.status) && (
          <div className={styles.item}>
            <dt>Termination Date:</dt>
            <dd>{formatAsDate(props.terminationDate)}</dd>
          </div>
        )}
        {props.contacts && (
          <div className={`${styles.item} ${styles.vertical}`}>
            <dt>Contacts:</dt>
            <br />
            {props.contacts.map((contact, index) => (
              <dd key={index} className={styles.link}>
                <a href={`mailto: ${contact}`}>{contact}</a>
              </dd>
            ))}
          </div>
        )}
        {props.sharePointFolder && (
          <div className={`${styles.item} ${styles.vertical}`}>
            <dt>SharePoint Folder:</dt>
            <br />
            <dd className={styles.link}>
              <a href={props.sharePointFolder} rel="noreferrer" target="_blank">
                {props.sharePointFolder}
              </a>
            </dd>
          </div>
        )}
      </CardBody>
    </Card>
  );
});
