import React, { useEffect, useMemo } from "react";
import { Error } from "@progress/kendo-react-labels";
import {
  ErrorMessage,
  Field,
  FormikProps,
  FormikValues,
  useField,
} from "formik";
import { FormikInput } from "../../common/formik/FormikWrappers";

import styles from "./RiskCalculator.module.scss";

import { Template } from "../../../common/risk/types";

/**  
  RISK CALCULATION ALGORITHM

  Each ddc type has it's own template used during
  risk calculation.

  Final goal of risk calculation is to calculate
  ARR, value which determines if Due Diligence
  check is successful or not. Lower ARR means there
  is less risk in dealing with particular client.

  ARR's value must be between 100 (value above 100
  is regarded as an error) and a minimum value defined
  in the template (it can't go lower than that).

  A) Configuration is collection of templates
  for each ddc type.

  B) Template

  Template is composed of
  a) min - minimum ARR value
  b) multi - magical multiplier
  c) breakpointSuccess/breakpointFail - if ARR is above
  breakpointSuccess risk is really low, countrary for
  breakpointFail. This value is used for coloring on the UI.
  d) list of categories, where each category represents
  area of interest during DDC check. Compliance officer
  awards certain number of points to each of the categories
  during the check.
  Category consists of
    a) description/abbrev
    b) maxPoints - maximum value that can be awarded to category
    by the compliance officer
    c) tresshold - Only for UI and validation
    d) weightInPercentOfTotal - represents how important category is
    compared to other categories. It's as a percentage. Sum of all category
    weight's is 100 (percent). It's used to calculate result for a category.
  d) List of all ddc type ids related to this template.

  C) Calculation

  NOTE: ARR can be calculated only if compliance officer has
  filled results points for all categories.

  1. Calculate result percentage for each category. Calculation is based
  on result points that officer filled in, maximum points for that
  category and weightInPercentOfTotal.

  Formula is resultPoints * (weightInPercentOfTotal / maxPoints)

  2. Calculate TRR. TRR is an intermediate result which represents amount
  of total points lost. Maxium TRR value is 100 (all result points are 0),
  and minimum value is 0 (all result points are equal to category's maxPoints).

  When we sum all of the result percentages, we substract that sum from 100,
  and that number is TRR.

  3. Calculate ARR. ARR is the final result of risk calculation and it's
  based on TRR and min parameter that comes from configuration.

  If ARR is less than a min, ARR is assigned min value. Otherwise,
  we multiply TRR by a multi parameter from configuration and add
  TRR again on top of it. If that result is higher than 100 it's
  considered as an error, otherwise we have a valid ARR.
*/

interface RiskCalculatorProps {
  ddcTypeId: string;
  formikProps: FormikProps<any>;
  disabled: boolean;
  templates: Template[];
  domicileGroup: string;
}

interface ArrFieldProps {
  name: string;
  template: Template;
  values: FormikValues;
}

/* 
  Component responsible for displaying and validating
  final risk calculation value - ARR.
*/
const ArrField = ({ name, template, values }: ArrFieldProps) => {
  const [, meta] = useField({
    name,
    /*
      Validation logic is moved to the field level
      because it depends on the selected configuration,
      and not only form values.
    */
    validate: (value: number) => {
      return allResultPointsFilledAndValid(template, values) ? undefined: "Error";
    },
  });

  const numericValue = parseInt(meta.value);
  const notValid = meta.touched && meta.error;

  let colorClass;

  if (notValid) {
    colorClass = styles.riskHigh;
  } else if (numericValue > template.breakpointFail) {
    colorClass = styles.riskHigh;
  } else if (numericValue < template.breakpointSuccess) {
    colorClass = styles.riskLow;
  } else {
    colorClass = styles.riskMedium;
  }
  return (
    <span className={`${styles.arrValue}  ${colorClass}`}>
      {notValid ? meta.error : meta.value}
    </span>
  );
};

const calculateResultPercentage = (
  resultPoints: number,
  maxPoints: number,
  weightInPercentOfTotal: number
) => {
  resultPoints = Number(resultPoints);
  maxPoints = Number(maxPoints);
  weightInPercentOfTotal = Number(weightInPercentOfTotal);
  if (resultPoints <= maxPoints) {
    return resultPoints * (weightInPercentOfTotal / maxPoints);
  }
  return 0;
};

const allResultPointsFilledAndValid = (
  template: Template,
  values: FormikValues
) => {
  return template.categories.every((c) => {
    let value = values[c.abbreviation];
    let integerValue = parseInt(value);
    let isInteger = !isNaN(integerValue);
    let belowMax = integerValue <= c.maxPoints;
    let filledAndValid = value !== "" && isInteger && belowMax;
    return filledAndValid;
  });
};

/* 
  This method should only be invoked as a helper during ARR calculation
  since it doesn't employ any safeguards, becaues all of the checks are
  already done as a part of preparation for ARR calculation.
*/
const calculateTrr = (template: Template, values: FormikValues) => {
  const resultPercentagesSum = template.categories.reduce((acc, current) => {
    let resultPointsValue = values[current.abbreviation];
    const resultPoints = parseInt(resultPointsValue);
    const resultPercentage = calculateResultPercentage(
      resultPoints,
      current.maxPoints,
      current.weightInPercentOfTotal
    );
    return acc + resultPercentage;
  }, 0);

  return 100 - resultPercentagesSum;
};

const calculateArr = (template: Template | undefined, values: FormikValues) => {
  if (template === undefined) {
    return 0;
  }

  if (!allResultPointsFilledAndValid(template, values)) {
    return 0;
  }
  const trr = calculateTrr(template, values);

  const arr = trr + trr * template.multi;

  if (arr <= template.min) {
    return Number(template.min);
  }
  return arr > 100 ? 100 : Math.round(arr);
};

const getFieldMaxLength = (maxPoints: number) => {
  if (maxPoints < 10) {
    return 1;
  } else if (maxPoints < 100) {
    return 2;
  } else {
    return 3;
  }
};

const RiskCalculator = ({
  domicileGroup,
  ddcTypeId,
  formikProps,
  disabled,
  templates,
}: RiskCalculatorProps) => {
  const { values, setFieldTouched, setFieldValue } = formikProps;
  let template: Template | undefined = useMemo(() => {
    return templates.find(
      (template: Template) =>
        template.ddcTypeIds.includes(ddcTypeId) &&
        (template.group ? template.group === domicileGroup : true)
    );
  }, [ddcTypeId, templates, domicileGroup]);

  /* 
    https://github.com/formium/formik/issues/2204

    Since value of the ARR depends on a set of Result points
    field's values, there's no more reliable way
    of doing the calculation and validation than this.
  */
  useEffect(() => {
    if (template === undefined) {
      throw new TypeError("Template must not be undefined!");
    }
    setFieldTouched("arr", true, false);
    setFieldValue("arr", calculateArr(template, values));
  }, [values, template, setFieldValue, setFieldTouched]);

  // if (template!!.categories.length === 0) {
  //   return <div className={styles.riskInfo}>No counterparty type selected</div>;
  // }

  if (!template) {
    return <div className={styles.riskInfo}>No counterparty type selected</div>;
  }

  return (
    <>
      <table>
        <tbody>
          <tr className={styles.tableRow}>
            <th>Cat.</th>
            <th>Description</th>
            <th>Max Points</th>
            <th>Result Points</th>
            <th>Result %</th>
            <th>{"Weight in %\nof total"}</th>
          </tr>
          {template!!.categories.map((category) => {
            const resultPercentage = calculateResultPercentage(
              values[category.abbreviation],
              category.maxPoints,
              category.weightInPercentOfTotal
            );
            const roundedResultPercentage = Math.round(resultPercentage);
            let percentageColorClass;
            if (roundedResultPercentage > category.threshold) {
              percentageColorClass = styles.riskLow;
            } else if (roundedResultPercentage < category.threshold) {
              percentageColorClass = styles.riskHigh;
            } else {
              percentageColorClass = styles.riskMedium;
            }
            return (
              <tr key={category.abbreviation} className={styles.tableRow}>
                <td>{category.abbreviation}</td>
                <td>{category.description}</td>
                <td>{category.maxPoints}</td>
                <td>
                  <Error>
                    <ErrorMessage name={category.abbreviation} />
                  </Error>
                  {/* 
                    Validation is moved to the field level here
                    because it depends not only on form values,
                    but on configuration parameters too.
                    Also every configuration has it's own set
                    of result point fields to be validated.
                    By moving the validation out of the schema,
                    validation logic is simplified a lot, since
                    we don't have to track what fields should be
                    validated and what should not, based on the
                    selected configuration.
                */}
                  <Field
                    name={category.abbreviation}
                    maxLength={getFieldMaxLength(category.maxPoints)}
                    component={FormikInput}
                    disabled={disabled}
                    validate={(value: string) => {
                      let error;
                      if (!value) {
                        error = "Required";
                      } else if (!value.match(/^\d+$/)) {
                        error = "Digits only";
                      } else if (parseInt(value) > category.maxPoints) {
                        error = `Maximum is ${category.maxPoints}`;
                      }
                      return error;
                    }}
                  />
                </td>
                <td className={percentageColorClass}>
                  {roundedResultPercentage}
                </td>
                <td>{category.weightInPercentOfTotal}</td>
              </tr>
            );
          })}
          <tr className={styles.tableRow}>
            <td />
            <td />
            <td />
            <td />
            <td>
              <span className={styles.arrLabel}>ARR:</span>
              <ArrField
                name="arr"
                template={template!!}
                values={formikProps.values}
              />
            </td>
            <td />
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default RiskCalculator;
