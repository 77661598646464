import React from "react";

import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
} from "@progress/kendo-react-charts";
import { Field, FieldArray } from "formik";
import { FormikTableInput } from "../../../../components/common/formik/FormikWrappers";
import SectionHeader from "../../SectionHeader";
import SectionFooter from "../../SectionFooter";

import styles from "./DistributionAgreements.module.scss";

import {
  Agreements,
  ChartItem,
  ConcludedAgreement,
  RiskScore,
} from "../../types";

const formatLabelContent = (props: any) => {
  return `${props.dataItem.value}`;
};

const getRiskCellStyle = (riskScore: RiskScore) => {
  if (riskScore.status === "inProgress") {
    return styles.whiteBackground;
  }

  if (riskScore.value <= 19) {
    return styles.darkGreenBackground;
  } else if (riskScore.value <= 24) {
    return styles.greenBackground;
  } else if (riskScore.value <= 29) {
    return styles.lightGreenBackground;
  } else if (riskScore.value <= 34) {
    return styles.yellowBackground;
  } else if (riskScore.value <= 39) {
    return styles.orangeBackground;
  } else if (riskScore.value <= 44) {
    return styles.darkOrangeBack;
  } else if (riskScore.value <= 47) {
    return styles.darkerOrangeBackground;
  } else {
    return styles.redBackground;
  }
};

const ConcludedAgreementsTable = React.memo<{
  concludedAgreements: ConcludedAgreement[];
  quartal: string;
}>(({ concludedAgreements, quartal }) => {
  return (
    <>
      <p>
        <span
          className={styles.subtitle}
        >{`Agreements concluded in ${quartal}`}</span>
      </p>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>No.</th>
            <th>Distribution partner</th>
            <th>Domicile of Distributor</th>
            <th>Type of the agreement</th>
            <th>CSSF Classification</th>
            <th>Sub-distribution</th>
            <th>Conclusion date of agreement</th>
            <th>Date agreement came into force</th>
            <th>Initial DD</th>
            <th>Initial risk scoring</th>
            <th>Next ongoing DD</th>
          </tr>
        </thead>
        <tbody>
          {concludedAgreements.map((agreement, index) => (
            <tr key={index}>
              <td className={styles.index}>{`${index + 1}.`}</td>
              <td>{agreement.partner}</td>
              <td>{agreement.domicile}</td>
              <td>{agreement.type}</td>
              <td>{agreement.classification}</td>
              <td>{agreement.subDistribution}</td>
              <td>{agreement.conclucsionDate}</td>
              <td>{agreement.cameIntoForceDate}</td>
              <td className={styles.initialDDColumn}>
                {agreement.initialDDDate}
              </td>
              <td className={getRiskCellStyle(agreement.initialRiskScoring)}>
                {agreement.initialRiskScoring.status === "inProgress"
                  ? "In progress"
                  : `${agreement.initialRiskScoring.value}%`}
              </td>
              <td>{agreement.ongoing}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
});

const TypesChart = React.memo<{ types: ChartItem[]; overview: ChartItem[] }>(
  ({ types, overview }) => {
    return (
      <div className={styles.chartsContainer}>
        <div className={styles.chartContainer}>
          <div className={styles.subtitle}>Types of concluded agreements</div>
          <Chart seriesColors={["#BCBEC0", "#0093ad"]} transitions={false}>
            <ChartLegend position="bottom" />
            <ChartSeries>
              <ChartSeriesItem
                type="pie"
                data={types}
                field="value"
                categoryField="category"
                labels={{ visible: true, content: formatLabelContent }}
              />
            </ChartSeries>
          </Chart>
        </div>
        <div className={styles.chartContainer}>
          <div className={styles.subtitle}>Overview of total agreements</div>
          <Chart
            seriesColors={["#BCBEC0", "#0093ad", "#8ADFFF"]}
            transitions={false}
          >
            <ChartLegend position="bottom" />
            <ChartSeries>
              <ChartSeriesItem
                type="pie"
                data={overview}
                field="value"
                categoryField="category"
                labels={{ visible: true, content: formatLabelContent }}
              />
            </ChartSeries>
          </Chart>
        </div>
      </div>
    );
  }
);

const DistributionAgreements: React.FC<{
  id: number;
  quartal: string;
  agreements: Agreements;
}> = ({ id, quartal, agreements }) => {
  return (
    <>
      <SectionHeader id={id}>
        {`${id} Distribution agreements concluded and terminated in ${quartal}`}
      </SectionHeader>
      <ConcludedAgreementsTable
        quartal={quartal}
        concludedAgreements={agreements.concluded}
      />
      <TypesChart types={agreements.types} overview={agreements.overview} />
      <p>
        <span
          className={styles.subtitle}
        >{`Agreements terminated in ${quartal}`}</span>
      </p>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>No.</th>
            <th>Distribution partner</th>
            <th>Domicile</th>
            <th>Type of agreement</th>
            <th>Termination reason</th>
          </tr>
        </thead>
        <tbody>
          <FieldArray
            name="agreements.terminated"
            render={() => (
              <>
                {agreements.terminated.map((row, index) => (
                  <tr key={row.id}>
                    <td className={styles.index}>{index + 1}.</td>
                    <td>{row.partner}</td>
                    <td>{row.domicile}</td>
                    <td>{row.typeOfAgreement}</td>
                    <td>
                      <Field
                        name={`agreements.terminated[${index}].terminationReason`}
                        placeholder="Termination reason"
                        component={FormikTableInput}
                      />
                    </td>
                  </tr>
                ))}
              </>
            )}
          />
        </tbody>
      </table>
      <SectionFooter />
    </>
  );
};

export default React.memo(DistributionAgreements);
