import React from "react";
import { useParams } from "react-router-dom";
import CounterpartySearch from "../CounterpartySearch";

const CreateDDCForCounterparty: React.FC = () => {
  const { counterpartyId } = useParams<{ counterpartyId: string }>();
  return <CounterpartySearch counterpartyId={counterpartyId} />;
};

export default CreateDDCForCounterparty;
