import React from "react";

import SectionHeader from "../../SectionHeader";
import SectionFooter from "../../SectionFooter";

import styles from "./ExclusiveContracts.module.scss";

import { ExclusiveInformation } from "../../types";
import { getRiskCellStyle } from "../../util";

const ExclusiveContracts: React.FC<{
  id: number;
  clientName: string;
  exclusiveInformations: ExclusiveInformation[];
}> = ({ id, clientName, exclusiveInformations }) => {
  return (
    <>
      <SectionHeader id={id}>
        {`${id} Exclusive contracts: ${clientName}`}
      </SectionHeader>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>No.</th>
            <th>Distribution partner</th>
            <th>Domicile of distributor</th>
            <th>Type of agreement</th>
            <th>CSSF Classification</th>
            <th>Sub-distribution</th>
            <th>Date of agreement</th>
            <th>Latest DD completed</th>
            <th>Previous risk scoring</th>
            <th>Recurring risk scoring</th>
            <th>Next ongoing DD</th>
          </tr>
        </thead>
        <tbody>
          {exclusiveInformations.map((row, index) => (
            <tr key={index}>
              <td className={styles.index}>{index + 1}.</td>
              <td>{row.partner}</td>
              <td>{row.domicile}</td>
              <td>{row.agreementType}</td>
              <td>{row.classification}</td>
              <td>{row.subDistribution}</td>
              <td>{row.agreementDate}</td>
              <td>{row.dateOfLatesComlpetedDD}</td>
              <td className={getRiskCellStyle(row.previousRiskScore, styles)}>
                {row.previousRiskScore.status === "inProgress"
                  ? "In progress"
                  : `${row.previousRiskScore.value}%`}
              </td>
              <td className={getRiskCellStyle(row.previousRiskScore, styles)}>
                {row.currentRiskScore.status === "inProgress" ? (
                  "In progress"
                ) : (
                  <>
                    {`${row.currentRiskScore.value}%`}
                    <sup>{row.crossReference ?? ""}</sup>
                  </>
                )}
              </td>
              <td>{row.dateOfNextOngoingDD}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <SectionFooter />
    </>
  );
};

export default ExclusiveContracts;
