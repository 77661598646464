import React, { useEffect, useMemo } from "react";
import { FormikProps, FormikValues, useField } from "formik";

import styles from "./Score.module.scss";
import { Country } from "../../../../common/ddc/types";

// We need to check only country fields because
// binary ones are prefilled with NO.
const allFieldsFilledDefault = (values: FormikValues): boolean => {
  return (
    values.countryOfIncorporation &&
    values.countryOfResidence &&
    values.nationalityUBO &&
    values.countriesOfBusinessActivities &&
    values.countriesOfBusinessActivities.length > 0
  );
};

const calculateScoreDefault = (values: FormikValues): string => {
  // Countries of business activities is an array of Countries,
  // so we have to include risk category of each country into risk
  // calculation.
  const COBARiskCategories = values.countriesOfBusinessActivities.map(
    (country: Country) => country.riskCategory
  );

  const riskCategories = [
    values.countryOfIncorporation.riskCategory,
    values.onSanctionList.riskCategory,
    values.countryOfResidence.riskCategory,
    values.nationalityUBO.riskCategory,
    values.PEPUBO.riskCategory,
    ...COBARiskCategories,
    values.dealingWithCustomersSubjectToSanctions.riskCategory,
    values.informationRelatedToFinancialCrime.riskCategory,
    values.informationRegardingOtherRegulatoryFailings.riskCategory,
    values.opaqueShareholdingStructure.riskCategory,
  ];

  const score = ((categories) => {
    if (categories.includes(undefined)) {
      return "error";
    } else if (categories.includes("c")) {
      return "c";
    } else if (categories.includes("b")) {
      return "b";
    } else {
      return "a";
    }
  })(riskCategories);

  return score;
};

const getColorClass = (score: string) => {
  switch (score) {
    case "a":
      return styles.riskLow;
    case "b":
      return styles.riskMedium;
    case "c":
      return styles.riskHigh;
    default:
      return styles.riskHigh;
  }
};

const Score = ({
  name,
  formikProps,
  allFieldsFilled,
  calculateScore,
  amlCtfVersion,
}: {
  name: string;
  formikProps: FormikProps<any>;
  allFieldsFilled?: (values: FormikValues, amlCtfVersion?: string) => boolean;
  calculateScore?: (values: FormikValues, amlCtfVersion?: string) => string;
  amlCtfVersion?: string;
}) => {
  const { values, setFieldValue, setFieldTouched } = formikProps;
  const [, meta] = useField(name);

  // Show nicely formatted risk score on UI
  const scoreMap = useMemo(() => {
    const map: Map<string, string> = new Map();
    map.set("a", "A (Low risk)");
    map.set("b", "B (Medium risk)");
    map.set("c", "C (High risk)");
    return map;
  }, []);

  //const score = allFieldsFilledDefault(values) ? calculateScore(values) : "";
  const validate = allFieldsFilled ?? allFieldsFilledDefault;

  //const score = validate(values) ? calculateScoreDefault(values) : "";
  const calculate = calculateScore ?? calculateScoreDefault;

  const score = validate(values, amlCtfVersion)
    ? calculate(values, amlCtfVersion)
    : "";

  const colorClass = getColorClass(score);

  const notValid = meta.touched && meta.error;
  /* 
    https://github.com/formium/formik/issues/2204
  
    Since value of the AML/CTF Risk score depends on a set of fields,
    there's no more reliable way of doing the calculation than this.
    On every dependant value change, recalculate score and set field
    value.

    Note that this will also set field to touched on the first render.
  */
  useEffect(() => {
    setFieldTouched(name, true, true);
    setFieldValue(name, score);
  }, [values, setFieldValue, setFieldTouched, score, name]);

  return (
    <div className={styles.root}>
      <span className={styles.label}>AML/CTF Risk Score:</span>
      <span className={`${styles.value} ${colorClass}`}>
        {notValid ? <b>{meta.error}</b> : <b>{scoreMap.get(score)}</b>}
      </span>
    </div>
  );
};

export default Score;
