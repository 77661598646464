import React from "react";

import { Field, FormikProps } from "formik";
import {
  FormRow,
  FormikRadioGroup,
  FormikMultiSelect,
  FormikDropDown,
  Option,
} from "../../common/formik/FormikWrappers";
import Score from "./Score";

import { AMLCTFOptions } from "../../../common/ddc/types";

const AMLCTFRiskCalculator: React.FC<{
  amlCtfOptions: AMLCTFOptions;
  formikProps: FormikProps<any>;
  readOnly: boolean;
}> = ({ amlCtfOptions, formikProps, readOnly }) => {
  const {
    onSanctionList,
    PEPUBO,
    dealingWithCustomersSubjectToSanctions,
    informationRelatedToFinancialCrime,
    informationRegardingOtherRegulatoryFailings,
    opaqueShareholdingStructure,
  } = amlCtfOptions.binary;

  const {
    countryOfIncorporation,
    countryOfResidence,
    nationalityUBO,
    countriesOfBusinessActivities,
  } = amlCtfOptions.countries;

  return (
    <>
      <FormRow label="Country of Incorporation of Counterparty:">
        <Field
          name="countryOfIncorporation"
          options={countryOfIncorporation}
          component={FormikDropDown}
          disabled={readOnly}
        />
      </FormRow>
      <FormRow label="Counterparty/UBO on Sanction List:">
        <Field
          name="onSanctionList"
          options={onSanctionList}
          component={FormikRadioGroup}
          disabled={readOnly}
        />
      </FormRow>
      <FormRow label="Country of Residence UBO:">
        <Field
          name="countryOfResidence"
          options={countryOfResidence}
          component={FormikDropDown}
          disabled={readOnly}
        />
      </FormRow>
      <FormRow label="Nationality UBO:">
        <Field
          name="nationalityUBO"
          options={nationalityUBO}
          component={FormikDropDown}
          disabled={readOnly}
        />
      </FormRow>
      <FormRow label="PEP UBO:">
        <Field
          name="PEPUBO"
          options={PEPUBO}
          component={FormikRadioGroup}
          disabled={readOnly}
        />
      </FormRow>
      <FormRow label="Countries of Business Activities:">
        <Field
          name="countriesOfBusinessActivities"
          options={countriesOfBusinessActivities}
          component={FormikMultiSelect}
          disabled={readOnly}
          sideEffect={(_: Option[], newValue: Option[]) => {
            if (newValue && newValue.length === 0) {
              formikProps.setFieldTouched("worldCheckOneConducted", false);
              formikProps.setFieldValue("worldCheckOneConducted", false, true);
            }
          }}
        />
      </FormRow>
      <FormRow label="Dealings with Customers Subject to Sanctions:">
        <Field
          name="dealingWithCustomersSubjectToSanctions"
          options={dealingWithCustomersSubjectToSanctions}
          component={FormikRadioGroup}
          disabled={readOnly}
        />
      </FormRow>
      <FormRow label="Adverse Information Related to Financial Crime UBO/Counterparty:">
        <Field
          name="informationRelatedToFinancialCrime"
          options={informationRelatedToFinancialCrime}
          component={FormikRadioGroup}
          disabled={readOnly}
        />
      </FormRow>
      <FormRow label="Adverse Information Counterparty Regarding Other Regulatory Failings:">
        <Field
          name="informationRegardingOtherRegulatoryFailings"
          options={informationRegardingOtherRegulatoryFailings}
          component={FormikRadioGroup}
          disabled={readOnly}
        />
      </FormRow>
      <FormRow label="Opaque Shareholding Structure:">
        <Field
          name="opaqueShareholdingStructure"
          options={opaqueShareholdingStructure}
          component={FormikRadioGroup}
          disabled={readOnly}
        />
      </FormRow>
      <Score name="amlCtfScore" formikProps={formikProps} />
    </>
  );
};

export default AMLCTFRiskCalculator;
