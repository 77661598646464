import React from "react";

import styles from "./Cover.module.scss";

const Cover: React.FC<{
  quartal: string;
  title: string;
  subtitle?: string;
  breakBefore?: boolean;
  showLogo?: boolean;
}> = ({ quartal, title, subtitle, breakBefore, showLogo }) => {
  let rootStyle = styles.root;
  if (breakBefore) {
    rootStyle = `${rootStyle} ${styles.breakBefore}`;
  }
  return (
    <div className={rootStyle}>
      <div className={styles.background} />
      {showLogo && <div className={styles.logo} />}
      <h1 className={styles.title}>
        {title}
        <br />
        Report: {quartal}
      </h1>
      {subtitle && <h1 className={styles.subtitle}>{subtitle}</h1>}
    </div>
  );
};

export default React.memo(Cover);
