import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridRowClickEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { formatDate } from "@progress/kendo-intl";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Button } from "@progress/kendo-react-buttons";
import { State as GridState } from "@progress/kendo-data-query";
import { Card, CardBody } from "@progress/kendo-react-layout";
import Loading from "../../components/Loading";
import OverviewExcelExport from "./OverviewExcelExport";
import Dialog from "../../components/Dialog";

import styles from "./DDCOverview.module.scss";

import { COMPLIANCE_API_ORIGIN } from "../../config";
import { useHistory } from "react-router-dom";
import { getErrorMessage } from "../../common/util";
import { responseToDDC } from "../../common/ddc/util";
import { Option } from "../../components/common/formik/FormikWrappers";
import { DDCOverviewEntry, FilterOptions, FilterQueryValues } from "./types";
import { DDCResponse } from "../../common/ddc/types";
import axiosInstance, {
  createCancelTokenSource,
  isRequestCanceled,
} from "../../api/axios";
import { createSortParam } from "./util";
import OverviewFilter from "./OverviewFilter";

const ClickableGridCell = (props: GridCellProps) => {
  const history = useHistory();

  return (
    <td
      className={styles.counterpartyName}
      onClick={(e) => {
        // Prevent click handling on the grid row
        e.stopPropagation();
        history.push(
          `/counterparty-details/${props.dataItem.ddc.counterpartyId}`
        );
      }}
    >
      {props.dataItem.ddc.counterpartyName}
    </td>
  );
};

const ServicesGridCell = (props: GridCellProps) => {
  return <td>{arrayToString(props.dataItem.ddc.services)}</td>;
};

const ReportingDateGridCell = (props: GridCellProps) => {
  const date = props.dataItem.ddc.submissionDate;
  return <td>{date ? formatDate(new Date(date), "dd/MM/yyyy") : ""}</td>;
};

// Component is temporarily not used, but will be used in
// the future.
// const CounterpartiesGridCell = (props: GridCellProps) => {
//   return <td>{arrayToString(props.dataItem.ddc.acolinCounterparties)}</td>;
// };

const arrayToString = (items: Option[]) => {
  return items.map((service: Option) => service.name).join(", ");
};

const initialFilterOptions: FilterOptions = {
  companies: [],
  recommendations: [],
  ddcTypes: [],
  serviceTypes: [],
  users: [],
  statuses: [],
  acolinCounterparties: [],
};

const initialGridState: GridState = {
  skip: 0,
  take: 20,
};

const mapDDCResponsesToEntries = (
  ddcResponses: DDCResponse[]
): DDCOverviewEntry[] => {
  return ddcResponses.map(
    (ddcResponse: DDCResponse): DDCOverviewEntry => {
      const ddc = responseToDDC(ddcResponse);
      if (ddc.nextDDDate) {
        ddc.nextDDDate = new Date(ddc.nextDDDate);
      }
      return {
        ddcId: ddcResponse.id,
        ddcStatus: ddcResponse.status,
        ddc: ddc,
        submissionDate: new Date(ddc.submissionDate),
        lastModified: new Date(ddc.lastModified),
        assignedUser: ddcResponse.owner,
      };
    }
  );
};

const Overview: React.FC<{}> = () => {
  const [loading, setLoading] = useState(true);
  const [loadingDDCs, setLoadingDDCs] = useState(false);
  const [gridState, setGridState] = useState<GridState>(initialGridState);
  const [ddcData, setDdcData] = useState<{
    count: number;
    items: DDCOverviewEntry[];
  }>({ count: 0, items: [] });
  const [filterOptions, setFilterOptions] = useState<FilterOptions>(
    initialFilterOptions
  );

  const archivedDDCStatusId = useMemo(() => {
    if (!filterOptions) {
      return null;
    }
    const { statuses } = filterOptions;
    const status = statuses.find((status) => status.name === "ARCHIVED");
    return status?.id;
  }, [filterOptions]);

  const initialFilterQueryValues: FilterQueryValues = useMemo(
    () => ({
      arrMin: undefined,
      arrMax: undefined,
      ddcTypeCode: undefined,
      statusIds: [],
      counterpartyId: undefined,
      serviceIds: [],
      ownerId: undefined,
      acolinCounterparties: [],
      recommendationIds: [],
      lastModifiedStart: null,
      lastModifiedEnd: null,
      submissionDateStart: null,
      submissionDateEnd: null,
      dueDateStart: null,
      dueDateEnd: null,
      hideStatusId: archivedDDCStatusId ?? undefined,
    }),
    [archivedDDCStatusId]
  );

  useEffect(() => {
    if (initialFilterQueryValues.hideStatusId) {
      setFilterQueryValues(initialFilterQueryValues);
    }
  }, [initialFilterQueryValues]);

  const [
    filterQueryValues,
    setFilterQueryValues,
  ] = useState<FilterQueryValues | null>(null);

  const resetGridState = React.useCallback(() => {
    setGridState(initialGridState);
  }, []);

  const resetFilterQueryValues = React.useCallback(() => {
    setFilterQueryValues(initialFilterQueryValues);
  }, [initialFilterQueryValues]);

  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [xlsExportInProgress, setXLSExportInProgress] = useState(false);
  const excelExport = useRef<ExcelExport>(null);
  const [exportMaxUBOS, setExportMaxUBOS] = useState<number>(0);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    const source = createCancelTokenSource();
    axiosInstance
      .get(`${COMPLIANCE_API_ORIGIN}/v1/overview/filter`, {
        cancelToken: source.token,
      })
      .then((filterResponse) => {
        const filterOptions = filterResponse?.data;
        filterOptions?.companies?.sort((c1: Option, c2: Option) =>
          c1.name.localeCompare(c2.name)
        );
        setFilterOptions(filterOptions);
        // }
      })
      .catch((error) => {
        if (isRequestCanceled(error)) {
          return;
        }
        const message = getErrorMessage(error);
        alert(message);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!filterQueryValues) {
      return;
    }
    setLoadingDDCs(true);
    const source = createCancelTokenSource();
    let sort = undefined;
    if (gridState && gridState.sort && gridState.sort.length) {
      sort = createSortParam(gridState.sort[0]);
    }
    axiosInstance
      .get(`${COMPLIANCE_API_ORIGIN}/v1/ddc`, {
        params: {
          limit: gridState.take,
          offset: gridState.skip,
          sort,
          ...filterQueryValues,
        },
        cancelToken: source.token,
      })

      .then((ddcResponse) => {
        const items: DDCOverviewEntry[] = mapDDCResponsesToEntries(
          ddcResponse.data.ddcs
        );
        const count: number = ddcResponse.data.count;
        setDdcData({ count, items });

        setLoadingDDCs(false);
      })
      .catch((error) => {
        if (isRequestCanceled(error)) {
          return;
        }
        const message = getErrorMessage(error);
        alert(message);
      })
      .finally(() => setLoadingDDCs(false));

    return () => source.cancel();
  }, [gridState, filterOptions, filterQueryValues]);

  const exportToXLS = useCallback(() => {
    setXLSExportInProgress(true);
    axiosInstance
      .get(`${COMPLIANCE_API_ORIGIN}/v1/ddc`, {
        params: {
          ...filterQueryValues,
        },
      })
      .then((response) => {
        const ddcOverviewEntries = mapDDCResponsesToEntries(response.data.ddcs);
        const maxUBOS = ddcOverviewEntries.reduce((acc, ddc) => {
          const uboCount = ddc.ddc.UBO?.length ?? 0;
          return uboCount > acc ? uboCount : acc;
        }, 0);
        setExportMaxUBOS(maxUBOS);
        // Some columns of the DDC are arrays, ExcelExport
        // component works only with single values, so we have
        // to map arrays to strings
        const exportableEntries = ddcOverviewEntries.map((ddcEntry) => {
          const UBOS = ddcEntry.ddc.UBO?.reduce((acc, ubo, index) => {
            const key = `UBO${index + 1}`;
            const {
              name,
              nationality,
              countryOfTaxResidence,
              hasAdverseInformationRelatedToFinancialCrime,
              isOnSanctionList,
              isPEP,
              isRCA,
            } = ubo;
            acc = {
              ...acc,
              [key]: {
                name,
                nationality: nationality?.name,
                countryOfTaxResidence: countryOfTaxResidence?.name,
                hasAdverseInformationRelatedToFinancialCrime:
                  hasAdverseInformationRelatedToFinancialCrime?.label,
                isOnSanctionList: isOnSanctionList?.label,
                isPEP: isPEP?.label,
                isRCA: isRCA?.label,
              },
            };
            return acc;
          }, {});
          return {
            ...ddcEntry,
            ...UBOS,
            ddc: {
              ...ddcEntry.ddc,
              service: arrayToString(ddcEntry.ddc.services),
              acolinCounterparties: arrayToString(
                ddcEntry.ddc.acolinCounterparties
              ),
              // Temporarily disabled export of documents
              // dueDiligenceDocuments: arrayToString(
              //   ddcEntry.ddc.dueDiligenceDocuments
              // ),
              submissionDate: new Date(ddcEntry.ddc.submissionDate),
              lastModified: new Date(ddcEntry.ddc.lastModified),
            },
          };
        });
        excelExport.current?.save(exportableEntries);
        setExportDialogOpen(false);
        setXLSExportInProgress(false);
      })
      .catch((error) => {
        setExportDialogOpen(false);
        setXLSExportInProgress(false);
        const message = getErrorMessage(error);
        alert(message);
      });
  }, [filterQueryValues]);

  const onRowClick = (e: GridRowClickEvent) => {
    if (e.dataItem.ddcStatus.status === "STUB") {
      history.push(
        `/ddc/review-stub/${e.dataItem.ddc.counterpartyId}/${e.dataItem.ddcId}`
      );
    } else if (e.dataItem.ddcStatus.status === "ARCHIVED") {
      history.push(
        `/ddc/review-archived/${e.dataItem.ddc.counterpartyId}/${e.dataItem.ddcId}`
      );
    } else {
      history.push(
        `/ddc/review/${e.dataItem.ddc.counterpartyId}/${e.dataItem.ddcId}`
      );
    }
  };

  return (
    <div className={styles.root}>
      <h1>Due Diligence Overview</h1>
      {loading ? (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      ) : (
        <>
          <Card>
            <CardBody>
              <OverviewFilter
                filterOptions={filterOptions}
                setFilterQueryValues={setFilterQueryValues}
                resetFilterQueryValues={resetFilterQueryValues}
                resetGridState={resetGridState}
              />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <OverviewExcelExport ref={excelExport} maxUBOS={exportMaxUBOS} />
              <div className={styles.gridContainer}>
                <Grid
                  {...gridState}
                  pageable
                  sortable
                  reorderable
                  resizable
                  data={ddcData.items}
                  onRowClick={onRowClick}
                  onDataStateChange={(e) => {
                    setGridState(e.dataState);
                  }}
                  total={ddcData.count}
                >
                  <GridToolbar>
                    <Button
                      title="Export Excel"
                      disabled={xlsExportInProgress}
                      onClick={() => setExportDialogOpen(true)}
                    >
                      {xlsExportInProgress
                        ? "Export in progress"
                        : "Export to Excel"}
                    </Button>
                  </GridToolbar>
                  <Column
                    field="ddc.counterpartyId"
                    title="Counterparty ID"
                    minResizableWidth={100}
                    width={150}
                  />
                  <Column
                    field="ddc.counterpartyName"
                    title="Counterparty name"
                    minResizableWidth={100}
                    cell={ClickableGridCell}
                  />
                  <Column
                    field="ddc.ddcType.name"
                    title="Type"
                    minResizableWidth={100}
                    sortable={false}
                  />
                  <Column
                    field="ddc.services"
                    title="Service"
                    minResizableWidth={100}
                    cell={ServicesGridCell}
                    sortable={false}
                  />
                  <Column
                    field="ddc.recommendation.name"
                    title="Recommendation"
                    minResizableWidth={100}
                    sortable={false}
                  />
                  <Column
                    field="ddcStatus.status"
                    title="Status"
                    minResizableWidth={100}
                    sortable={false}
                  />
                  <Column field="ddc.arr" title="ARR" minResizableWidth={100} />
                  <Column
                    field="submissionDate"
                    title="Reporting Date"
                    cell={ReportingDateGridCell}
                    minResizableWidth={100}
                  />
                  <Column
                    field="lastModified"
                    title="Modification Date"
                    format="{0:dd/MM/yyyy}"
                    minResizableWidth={100}
                  />
                </Grid>
                {loadingDDCs && (
                  <div className={styles.scrim}>
                    <Loading />
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </>
      )}
      {exportDialogOpen && (
        <Dialog
          title={"XLS Export"}
          type="choice"
          onNegativeButtonClick={() => {
            setExportDialogOpen(false);
          }}
          onPositiveButtonClick={() => {
            setExportDialogOpen(false);
            setImmediate(() => exportToXLS());
          }}
          onClose={() => {
            setExportDialogOpen(false);
          }}
        >
          <>
            Export to XLS might take significant time (above 20s per 1000 DDCs).
            <br />
            Are you sure you want to continue?
          </>
        </Dialog>
      )}
    </div>
  );
};

export default Overview;
