import React from "react";

import { Field, FieldArray } from "formik";
import { FormikTableInput } from "../../../../components/common/formik/FormikWrappers";
import SectionHeader from "../../SectionHeader";
import SectionFooter from "../../SectionFooter";

import styles from "./RiskScoringChangeReasons.module.scss";

import { RiskScoreChangeReasons } from "../../types";

const RiskScoringChangeReasons: React.FC<{
  id: number;
  quartal: string;
  changeReasons: RiskScoreChangeReasons[];
}> = ({ id, quartal, changeReasons }) => {
  return (
    <>
      <SectionHeader id={id}>
        {`${id} Reasons for the change of risk scoring`}
      </SectionHeader>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>No.</th>
            <th>Distribution partner</th>
            <th>Reasons for the change of the risk scoring</th>
          </tr>
        </thead>
        <tbody>
          <FieldArray
            name="riskScoringChangeReasons"
            render={() => (
              <>
                {changeReasons.map((row, index) => (
                  <tr key={row.id}>
                    <td className={styles.index}>{index + 1}.</td>
                    <td>{row.distributorPartner}</td>
                    <td>
                      <Field
                        name={`riskScoringChangeReasons[${index}].reasons`}
                        placeholder="Termination reason"
                        component={FormikTableInput}
                      />
                    </td>
                  </tr>
                ))}
              </>
            )}
          />
        </tbody>
      </table>
      <SectionFooter />
    </>
  );
};

export default React.memo(RiskScoringChangeReasons);
