import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import Loading from "../../components/Loading";

import styles from "./Login.module.scss";

import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { tokenRequest, useAuthContext } from "../../auth/AcolinAuthContext";

const Info = () => {
  const [authState] = useAuthContext();

  switch (authState.state) {
    case "IN PROGRESS": {
      return <Loading />;
    }
    case "UNAUTHORIZED": {
      return <p>You are not authorized to access this application.</p>;
    }
    case "ERROR": {
      return (
        <p>
          Error happened during the authorization. Please close the tab if you
          don't get logged out in 5 seconds.
        </p>
      );
    }
    case "AUTHORIZED": {
      return <p>You are authorized. Please wait a moment.</p>;
    }
    default: {
      return <p>Invalid state: {authState.state}</p>;
    }
  }
};

const Login: React.FC<{}> = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  async function handleLogin() {
    instance.handleRedirectPromise().then((result) => {
      console.log(result);
    });
    try {
      await instance.acquireTokenSilent(tokenRequest);
    } catch (e) {
      instance.acquireTokenRedirect(tokenRequest);
    }
  }

  return (
    <div className={styles.root}>
      <h1 className={styles.header}>ACOLIN Compliance</h1>
      <div className={styles.buttonContainer}>
        {isAuthenticated ? (
          <Info />
        ) : (
          <Button onClick={handleLogin}>Sign in with Microsoft</Button>
        )}
      </div>
    </div>
  );
};

export default Login;
