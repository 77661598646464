import { IS_PRODUCTION, PUBLIC_URL } from "../config";

const IS_LOCALHOST = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const SW_URL = `${PUBLIC_URL}/service-worker.js`;

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export const registerValidSW = async (config?: Config): Promise<void> => {
  try {
    const registration = await navigator.serviceWorker.register(SW_URL);

    registration.onupdatefound = (): void => {
      const installingWorker = registration.installing;
      if (!installingWorker) {
        return;
      }

      installingWorker.onstatechange = (): void => {
        if (installingWorker.state !== "installed") {
          return;
        }

        if (navigator.serviceWorker.controller) {
          // At this point, the updated precached content has been fetched,
          // but the previous service worker will still serve the older
          // content until all client tabs are closed.
          console.log(
            "New content is available and will be used when all tabs for this page are closed. See https://cra.link/PWA."
          );

          // Execute callback
          if (config && config.onUpdate) {
            console.warn("Service Worker updated.", registration);
            config.onUpdate(registration);
          }
        } else {
          // At this point, everything has been precached.
          // It's the perfect time to display a
          // "Content is cached for offline use." message.
          console.log("Content is cached for offline use.");

          // Execute callback
          if (config?.onSuccess) {
            console.warn("Service Worker registred.", registration);
            config.onSuccess(registration);
          }
        }
      };
    };
  } catch (error) {
    console.error("Error during service worker registration:", error);
  }
};

const checkValidServiceWorker = async (config?: Config): Promise<void> => {
  try {
    // Check if the service worker can be found. If it can't reload the page.
    const { headers, status } = await fetch(SW_URL, {
      headers: { "Service-Worker": "script" },
    });

    // Ensure service worker exists, and that we really are getting a JS file.
    const contentType = headers.get("content-type");
    if (
      status === 404 ||
      (contentType !== null && contentType.indexOf("javascript") === -1)
    ) {
      // No service worker found. Probably a different app. Reload the page.
      navigator.serviceWorker.ready.then((registration) => {
        registration.unregister().then(() => {
          window.location.reload();
        });
      });
    } else {
      // Service worker found. Proceed as normal.
      registerValidSW(config);
    }
  } catch (error) {
    console.warn(
      "No internet connection found. App is running in offline mode."
    );
  }
};

export const register = (config?: Config): void => {
  if (!(IS_PRODUCTION && "serviceWorker" in navigator)) {
    return;
  }

  // The URL constructor is available in all browsers that support SW.
  const publicUrl = new URL(PUBLIC_URL, window.location.href);
  if (publicUrl.origin !== window.location.origin) {
    // Our service worker won't work if PUBLIC_URL is on a different origin
    // from what our page is served on. This might happen if a CDN is used to
    // serve assets; see https://github.com/facebook/create-react-app/issues/2374
    return;
  }

  window.addEventListener("load", () => {
    if (IS_LOCALHOST) {
      // This is running on localhost. Let's check if a service worker still exists or not.
      checkValidServiceWorker(config);

      // Add some additional logging to localhost, pointing developers to the
      // service worker/PWA documentation.
      navigator.serviceWorker.ready.then(() => {
        console.warn(
          "This web app is being served cache-first by a service worker. To learn more, visit https://cra.link/PWA"
        );
      });
    } else {
      // Is not localhost. Just register service worker
      registerValidSW(config);
    }
  });
};
