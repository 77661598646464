import React from "react";

import SectionHeader from "../../SectionHeader";
import SectionFooter from "../../SectionFooter";

import styles from "./FATCARegistrationCoverage.module.scss";

import { FATCACoverage } from "../../types";

const FATCARegistrationCoverage: React.FC<{
  id: number;
  fatcaCoverage: FATCACoverage;
}> = ({ id, fatcaCoverage }) => {
  return (
    <>
      <SectionHeader id={id}>
        {`${id} FATCA registration coverage of ACOLIN distribution partners`}
      </SectionHeader>
      <p>
        We reconcile the complete list of our distribution partners with a list
        of all Foreign Financial Institutions (FFIs) currently registered with
        the US Internal revenue services (IRS) on a regular basis. The list is
        provided by the IRS itself and is publicly available.
      </p>
      <p>
        The following table shows the number ofdistribution partnersregistered
        and not registered with the IRS and the corresponding percentage of
        registered distribution partners.
      </p>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Registered with IRS</th>
            <th>Not registered</th>
            <th>Precentage registered</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{fatcaCoverage.registeredWithIrs}</td>
            <td>{fatcaCoverage.notRegistered}</td>
            <td>{`${fatcaCoverage.percentageRegistered}%`}</td>
          </tr>
        </tbody>
      </table>
      <SectionFooter />
    </>
  );
};

export default React.memo(FATCARegistrationCoverage);
