import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { Error } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import {
  ErrorMessage,
  FastField,
  FieldArray,
  FieldProps,
  Form,
  Formik,
  FormikProps,
} from "formik";
import { FormikInput, FormikTextArea } from "../common/formik/FormikWrappers";

import styles from "./RiskConfigurationForm.module.scss";

import * as yup from "yup";
import { Category, Template } from "../../common/risk/types";
import { v4 as uuidv4 } from "uuid";
import { Notification } from "../Notifications/types";
import Notifications from "../Notifications";
import { isEqual } from "lodash";

// Matches if number has at most 3 decimals
let multiRegExp: RegExp = /^(?!0\d|$)\d*(\.\d{1,3})?$/;

const schema = yup.object().shape({
  comment: yup.string().required("Required").max(1000, "Max 1000 characters"),
  templates: yup.array().of(
    yup.object().shape({
      min: yup
        .number()
        .typeError("Not a number")
        .required("Required")
        .integer("Not an integer")
        .min(0, "Negative")
        .max(100, "Max 100"),
      multi: yup
        .number()
        .typeError("Not a number")
        .required("Required")
        .min(0, "Negative")
        .max(1, "Max 1")
        .test("multi-decimal-check", "Max 3 decimals", (value: any) => {
          return multiRegExp.test(String(value));
        }),
      weightSum: yup
        .number()
        .test("weight-sum-test", "Error", function (weightSum) {
          return weightSum === 100;
        }),
      categories: yup.array().of(
        yup.object().shape({
          maxPoints: yup
            .number()
            .typeError("Not a number")
            .required("Required")
            .integer("Not integer")
            .min(0, "Negative")
            .max(999, "Max 999"),
          threshold: yup
            .number()
            .typeError("Not a number")
            .required("Required")
            .integer("Not an integer")
            .min(0, "Negative")
            .max(100, "Max 100")
            .test("threshold-test", "Check weight", function (threshold) {
              // Category's threshold can't be larger then the
              // weight in percent of total of a category
              const weightInPercentOfTotalString = this.parent
                .weightInPercentOfTotal;
              const weightInPercentOfTotal = Number(
                weightInPercentOfTotalString
              );
              if (isNaN(weightInPercentOfTotal)) {
                return true;
              }
              return Number(threshold) <= Number(weightInPercentOfTotal);
            }),
          weightInPercentOfTotal: yup
            .number()
            .typeError("Not a number")
            .required("Required")
            .integer("Not an integer")
            .min(0, "Negative")
            .max(100, "Max 100"),
        })
      ),
    })
  ),
});

const WeightSum = ({ field, form }: FieldProps) => {
  const { touched, error } = form.getFieldMeta(field.name);
  const notValid = touched && error;
  const labelStyle = notValid ? styles.error : "";
  return <div className={labelStyle}>{field.value}</div>;
};

const ErrorHandlingInput = React.memo<{
  name: string;
  disabled: boolean;
  sideEffect?: (oldValue: any, newValue: any) => void;
}>(({ name, disabled, sideEffect }) => {
  return (
    <>
      <Error>
        <ErrorMessage name={name} />
      </Error>
      <FastField
        name={name}
        component={FormikInput}
        disabled={disabled}
        sideEffect={sideEffect}
      />
    </>
  );
});

const TemplateItem = React.memo<{
  configuration: Template;
  index: number;
  disabled: boolean;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}>(({ configuration, index, disabled, setFieldTouched, setFieldValue }) => {
  const calculateWeightSum = useCallback(
    (categories: Category[], categoryIndex: number, newWeightValue: any) => {
      return categories.reduce(
        (acc: number, category: Category, index: number) => {
          if (index === categoryIndex) {
            acc = acc + Number(newWeightValue);
          } else {
            acc = acc + Number(category.weightInPercentOfTotal);
          }
          return acc;
        },
        0
      );
    },
    []
  );

  return (
    <Card key={configuration.type}>
      <CardHeader>
        <CardTitle>{configuration.name}</CardTitle>
      </CardHeader>
      <CardBody>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableRow}>
              <th>Cat.</th>
              <th>Description</th>
              <th>Max Points</th>
              <th>Threshold %</th>
              <th>Weight in % of total</th>
            </tr>
          </thead>
          <tbody>
            {configuration.categories.map((category, categoryIndex) => {
              return (
                <tr className={styles.tableRow} key={category.abbreviation}>
                  <td>{category.abbreviation}</td>
                  <td>{category.description}</td>
                  <td>
                    <ErrorHandlingInput
                      name={`templates[${index}].categories[${categoryIndex}].maxPoints`}
                      disabled={disabled}
                    />
                  </td>
                  <td>
                    <ErrorHandlingInput
                      name={`templates[${index}].categories[${categoryIndex}].threshold`}
                      disabled={disabled}
                    />
                  </td>
                  <td>
                    <ErrorHandlingInput
                      name={`templates[${index}].categories[${categoryIndex}].weightInPercentOfTotal`}
                      disabled={disabled}
                      sideEffect={(_, newValue) => {
                        // On every value change in Weight in % of Total field
                        // we have to sum values of this field in each category of
                        // parent configuration and set the sum as Weight sum field's
                        // value.
                        // Since the form values are not updated at this moment,
                        // we pass new value for Weight in % of Total field and
                        // index of the category it belongs to, so that we can
                        // use the new value during sum calculation.
                        const sum = calculateWeightSum(
                          configuration.categories,
                          categoryIndex,
                          newValue
                        );
                        setFieldTouched(
                          `templates[${index}].weightSum`,
                          true,
                          false
                        );
                        setFieldValue(
                          `templates[${index}].weightSum`,
                          sum,
                          false
                        );
                      }}
                    />
                  </td>
                </tr>
              );
            })}
            {!disabled && (
              <tr className={styles.tableRow}>
                <td />
                <td />
                <td />
                <td className={styles.sumLabel}>Weight sum:</td>
                <td className={styles.sumValue}>
                  <FastField
                    name={`templates[${index}].weightSum`}
                    component={WeightSum}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className={styles.tableFooter}>
          <div className={styles.footerInputContainer}>
            <span className={styles.label}>Minimum</span>
            <div className={styles.footerInput}>
              <ErrorHandlingInput
                name={`templates[${index}].min`}
                disabled={disabled}
              />
            </div>
          </div>
          <div className={styles.footerInputContainer}>
            <span className={styles.label}>Multiplier</span>
            <div className={styles.footerInput}>
              <ErrorHandlingInput
                name={`templates[${index}].multi`}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
});

interface RiskConfiguration {
  templates: Template[];
  comment: string;
}

const RiskConfigurationForm: React.FC<{
  templates: Template[];
  comment: string;
  disabled: boolean;
  onSubmit: (values: { templates: Template[]; comment: string }) => void;
  isCommentOnTop?: boolean;
}> = ({ templates, comment, disabled, onSubmit, isCommentOnTop = false }) => {
  const [commentState, setCommentState] = useState<string>(comment);
  const [templateMap, setTemplateMap] = useState<Record<string, Template[]>>(
    {}
  );
  const groupsRef = useRef<{ [group: string]: FormikProps<RiskConfiguration> }>(
    {}
  );
  const [selectedGroup, setSelectedGroup] = useState<number>(0);

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const hideNotification = useCallback((notification: Notification) => {
    setNotifications((notifications) =>
      notifications.filter((n) => n.uuid !== notification.uuid)
    );
  }, []);
  const showNotification = useCallback(
    (notification: Notification) => {
      setNotifications((notifications) => [...notifications, notification]);
      setTimeout(() => {
        // TODO unsubscribe or use isMounted
        hideNotification(notification);
      }, 3000);
    },
    [hideNotification]
  );

  const onGroupSelect = useCallback(
    (e: TabStripSelectEventArguments) => {
      const currentGroupKey = Array.from(Object.keys(templateMap))[
        selectedGroup
      ];

      const currentForm: FormikProps<RiskConfiguration> =
        groupsRef.current[currentGroupKey];

      const formHasErrors = currentForm.errors?.templates
        ? currentForm.errors.templates.length > 0
        : false;

      if (formHasErrors) {
        showNotification({
          uuid: uuidv4(),
          type: "warning",
          text: "One or more fields are not valid.",
        });
        return;
      }

      const comment = currentForm.values.comment;

      setTemplateMap((prevState) => ({
        ...prevState,
        [currentGroupKey]: currentForm.values.templates,
      }));
      setCommentState(comment);
      setSelectedGroup(e.selected);
    },
    [
      groupsRef,
      templateMap,
      selectedGroup,
      setSelectedGroup,
      setCommentState,
      setTemplateMap,
      showNotification,
    ]
  );

  useEffect(() => {
    const templateMap = templates.reduce(
      (acc: Record<string, Template[]>, template: Template, index: number) => {
        const group = template.group ?? "EU/UK";
        const groupTemplates: Template[] = acc[group] ?? [];
        acc[group] = [...groupTemplates, template];
        return acc;
      },
      {}
    );

    setTemplateMap(templateMap);
  }, [templates, setTemplateMap]);

  useEffect(() => {
    setCommentState(comment);
  }, [comment]);

  const onFormSubmission = useCallback(
    ({ templates, comment }) => {
      const currentGroupKey = Array.from(Object.keys(templateMap))[
        selectedGroup
      ];
      const allTemplates = Object.values({
        ...templateMap,
        [currentGroupKey]: templates,
      }).flat();
      onSubmit({ templates: allTemplates, comment });
    },
    [templateMap, selectedGroup, onSubmit]
  );

  const areTemplatesDirty = useCallback(
    (currentGroup: string, currentTemplates: Template[]): boolean => {
      const currentTemplateMap = { ...templateMap };
      currentTemplateMap[currentGroup] = currentTemplates;

      const templatesChanged = !isEqual(
        new Set(templates),
        new Set(Object.values(currentTemplateMap).flat())
      );
      return templatesChanged;
    },
    [templates, templateMap]
  );

  return (
    <div className={styles.root}>
      <TabStrip
        selected={selectedGroup}
        onSelect={onGroupSelect}
        tabContentStyle={{ backgroundColor: "white" }}
        keepTabsMounted
      >
        {Object.entries(templateMap).map((value, index) => {
          const [group, templates] = value;
          return (
            <TabStripTab title={group} key={index}>
              <Formik
                enableReinitialize={true}
                initialValues={{ templates, comment: commentState }}
                onSubmit={onFormSubmission}
                validationSchema={schema}
                validateOnMount
                innerRef={(ref) =>
                  ref ? (groupsRef.current[group] = ref) : ref
                }
              >
                {(props: FormikProps<any>) => (
                  <Form
                    onSubmit={(e) => {
                      if (!props.isValid) {
                        showNotification({
                          uuid: uuidv4(),
                          type: "warning",
                          text: "One or more fields are not valid.",
                        });
                      }
                      props.handleSubmit(e);
                    }}
                  >
                    {isCommentOnTop ? (
                      <>
                        <Card>
                          <CardHeader>
                            <CardTitle>Comments</CardTitle>
                          </CardHeader>
                          <CardBody className={styles.commentsCardBody}>
                            <div className={styles.comments}>
                              <Error>
                                <ErrorMessage name="comment" />
                              </Error>
                              <FastField
                                name="comment"
                                component={FormikTextArea}
                                placeholder={
                                  "Please enter relevant information"
                                }
                                disabled={disabled}
                              />
                            </div>
                          </CardBody>
                        </Card>
                        <FieldArray
                          name="templates"
                          render={() => (
                            <>
                              {templates.map((template, index) => {
                                return (
                                  <TemplateItem
                                    key={`${template.name}-${template.group}`}
                                    configuration={template}
                                    index={index}
                                    disabled={disabled}
                                    setFieldTouched={props.setFieldTouched}
                                    setFieldValue={props.setFieldValue}
                                  />
                                );
                              })}
                            </>
                          )}
                        />
                      </>
                    ) : (
                      <>
                        <FieldArray
                          name="templates"
                          render={() => (
                            <>
                              {templates.map((template, index) => {
                                return (
                                  <TemplateItem
                                    key={`${template.name}-${template.group}`}
                                    configuration={template}
                                    index={index}
                                    disabled={disabled}
                                    setFieldTouched={props.setFieldTouched}
                                    setFieldValue={props.setFieldValue}
                                  />
                                );
                              })}
                            </>
                          )}
                        />
                        <Card>
                          <CardHeader>
                            <CardTitle>Comments</CardTitle>
                          </CardHeader>
                          <CardBody className={styles.commentsCardBody}>
                            <div className={styles.comments}>
                              <Error>
                                <ErrorMessage name="comment" />
                              </Error>
                              <FastField
                                name="comment"
                                component={FormikTextArea}
                                placeholder={
                                  "Please enter relevant information"
                                }
                                disabled={disabled}
                              />
                            </div>
                          </CardBody>
                        </Card>
                      </>
                    )}

                    <div className={styles.submit}>
                      {!disabled && (
                        <Button
                          type="submit"
                          className={styles.submit}
                          disabled={
                            !areTemplatesDirty(group, props.values.templates)
                          }
                        >
                          Submit
                        </Button>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </TabStripTab>
          );
        })}
      </TabStrip>
      <Notifications
        notifications={notifications}
        hideNotification={hideNotification}
      />
    </div>
  );
};

export default RiskConfigurationForm;
