import axiosInstance from "../api/axios";
import { Option } from "../components/common/formik/FormikWrappers";
import { COMPLIANCE_API_ORIGIN, DEFAULT_WCO_GROUP_ID } from "../config";
import { MasterFileData, OMNIOptions } from "../pages/CreateDDC/CreateDDC";
import {
  RELATIONSHIP_STATUS_ACTIVE_ID,
  RELATIONSHIP_STATUS_PROSPECT_ID,
  RELATIONSHIP_STATUS_TERMINATED_ID,
} from "./constants";

export const getErrorMessage = (error: any) => {
  let message;
  if (error.response) {
    message = error.response.data.message;
  }
  if (!message) {
    message = error.message;
  }
  return message;
};

export const formatAsDate = (date: Date | undefined) => {
  if (!date) {
    return "N/A";
  }
  const formattedDate = date.toLocaleDateString("en-GB", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return formattedDate;
};

export const formatAsQuartal = (date: Date) => {
  if (!date) {
    return "N/A";
  }
  const newDate = new Date(date.getTime());
  const month = newDate.getMonth() + 1;
  const quartal = Math.ceil(month / 3);
  const year = newDate.getFullYear().toString().slice(-2);
  return `${quartal}Q${year}`;
};

export const incrementDay = (date: Date | null) => {
  if (!date) {
    return date;
  }
  let newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 1);
  return newDate;
};

export const optionsToStrings = (
  options: Option[] | undefined,
  key: keyof Option = "name"
) => {
  if (!options) {
    return [];
  }
  return options.map((option: Option) => option[key]);
};

export const arrayToString = (items: any[]) => {
  if (items.length === 0) {
    return "";
  } else if (typeof items[0] === "string") {
    return items.join(", ");
  } else {
    return items.map((item) => JSON.stringify(item)).join(", ");
  }
};

export const isCounterpartyTerminated = (relationshipStatus: Option) => {
  return (
    relationshipStatus &&
    relationshipStatus.id === RELATIONSHIP_STATUS_TERMINATED_ID
  );
};

export const isCounterpartyActiveOrProspect = (relationshipStatus: Option) => {
  return (
    relationshipStatus &&
    (relationshipStatus.id === RELATIONSHIP_STATUS_ACTIVE_ID ||
      relationshipStatus.id === RELATIONSHIP_STATUS_PROSPECT_ID)
  );
};

export const createWCOCase = async ({
  id,
  name,
  groupId = DEFAULT_WCO_GROUP_ID,
  entity = "ORGANISATION",
  ongoing = true,
}: {
  id: string;
  name: string;
  groupId?: string;
  entity?: string;
  ongoing?: boolean;
}): Promise<any> => {
  const { data } = await axiosInstance.post(
    `${COMPLIANCE_API_ORIGIN}/v1/create-wco-case`,
    {
      id,
      name,
      groupId,
      entity,
      ongoing,
    }
  );

  console.log("CREATE WCO RESPONSE: ", data);
  return data;
};

export const getWCOResults = async ({
  id,
  groupId = DEFAULT_WCO_GROUP_ID,
}: {
  id: string;
  groupId?: string;
}): Promise<any> => {
  const {
    data,
  } = await axiosInstance.get(
    `${COMPLIANCE_API_ORIGIN}/v1/get-wco-screening-results`,
    { params: { id, groupId } }
  );

  console.log("GET WCO RESULTS RESPONSE: ", data);
  return data;
};

export const getOMNIOptions = async (): Promise<OMNIOptions> => {
  const { data } = await axiosInstance.get(
    `${COMPLIANCE_API_ORIGIN}/v1/omni-options`
  );
  return data;
};
