import { Button } from "@progress/kendo-react-buttons";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "@progress/kendo-react-layout";
import React, { useCallback } from "react";

import { useHistory, useParams } from "react-router-dom";

const ReviewArchived: React.FC = () => {
  // Route params will be used once this page is implemented, which also might not happen
  const { counterpartyId, ddcId } = useParams<{
    counterpartyId: string;
    ddcId: string;
  }>();

  const history = useHistory();

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <div>
      <Card>
        <CardHeader>Archived</CardHeader>
        <CardBody>
          <div>Reviewing archived DDCs is not supported.</div>
        </CardBody>
        <CardFooter>
          <Button onClick={goBack}>Go back</Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default ReviewArchived;
