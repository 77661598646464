import React from "react";

import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartLegend,
} from "@progress/kendo-react-charts";
import SectionHeader from "../../SectionHeader";

import { ChartListItem } from "../../types";
import SectionFooter from "../../SectionFooter";

const ExclusiveDueDilligenceRiskScoringChart: React.FC<{
  id: number;
  partners: String[];
  riskScores: ChartListItem[];
}> = ({ id, partners, riskScores }) => {
  return (
    <>
      <SectionHeader id={id}>
        {`${id} Due Diligence Risk Scoring chart of all exclusive contracts`}
      </SectionHeader>
      <Chart
        style={{ width: "1000px" }}
        seriesColors={["#5cb7c7", "#0093ad"]}
        transitions={false}
      >
        <ChartLegend position="bottom" orientation="horizontal" />
        <ChartValueAxis>
          <ChartValueAxisItem labels={{ format: "{0}%" }} />
        </ChartValueAxis>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            categories={partners}
            labels={{ rotation: -45 }}
          />
        </ChartCategoryAxis>
        <ChartSeries>
          {riskScores.map((item, idx) => (
            <ChartSeriesItem
              key={idx}
              type="column"
              data={item.values}
              name={item.category}
            />
          ))}
        </ChartSeries>
      </Chart>
      <SectionFooter />
    </>
  );
};

export default ExclusiveDueDilligenceRiskScoringChart;
