import React from "react";

import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";

import DDCOverview from "../DDCOverview";
import QCORTemplate from "../QCORTemplate";
import QCOROverview from "../QCOROverview";
import CSSFTemplate from "../CSSFTemplate";
import RiskConfiguration from "../RiskConfigutation";
import RiskConfigurationHistory from "../RiskConfigurationHistory";
import TierConfiguration from "../TierConfiguration";
import CounterpartyDetails from "../CounterpartyDetails";
import CreateDDCForCounterparty from "../CreateDDCForCounterparty";
import ReviewDDC from "../ReviewDDC";
import ReviewStub from "../ReviewStub";
import CounterpartySearch from "../CounterpartySearch";

import { useAuthContext } from "../../auth/AcolinAuthContext";

import styles from "./Home.module.scss";
import WhatsNew from "../WhatsNew";
import ReviewArchived from "../ReviewArchived";

// TODO Refactor navigation routes
const Home: React.FC = () => {
  const [authState, { logout }] = useAuthContext();

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Logo className={styles.logo} />
        <div className={styles.userContainer}>
          <div className={styles.userInfo}>
            <h3>{authState.user?.fullName}</h3>
            <span>{authState.user?.role?.name}</span>
          </div>
          <div
            className={styles.logOut}
            onClick={() => {
              logout();
            }}
          >
            <span className="k-icon k-i-login"></span>
          </div>
        </div>
      </div>
      <div className={styles.sidebar}>
        <h3>Due Diligence</h3>
        <menu>
          <li>
            <NavLink activeClassName={styles.activeNav} to={`/whatsnew`}>
              What's New
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={styles.activeNav} to={`/ddcoverview`}>
              Overview
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={styles.activeNav} to={`/createddc`}>
              Checklist
            </NavLink>
          </li>
        </menu>
        <h3>QCOR</h3>
        <menu>
          <li>
            <NavLink activeClassName={styles.activeNav} to={`/qcor`}>
              Overview
            </NavLink>
          </li>
        </menu>
        <h3>CSSF</h3>
        <menu>
          <li>
            <NavLink activeClassName={styles.activeNav} to={`/cssf/template`}>
              Template
            </NavLink>
          </li>
        </menu>
        <>
          <h3>Management</h3>
          <menu>
            <li>
              <NavLink
                activeClassName={styles.activeNav}
                to={`/management/risk`}
              >
                Risk config
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName={styles.activeNav}
                to={`/management/risk-history`}
              >
                Risk config history
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName={styles.activeNav}
                to={`/management/tier`}
              >
                Tier config
              </NavLink>
            </li>
          </menu>
        </>
      </div>
      <div className={styles.content}>
        <Switch>
          <Route exact path={`/ddcoverview`}>
            <DDCOverview />
          </Route>
          <Route path={`/counterparty-details/:counterpartyId`}>
            <CounterpartyDetails />
          </Route>
          <Route exact path={`/createddc`}>
            <CounterpartySearch />
          </Route>
          <Route path={`/ddc/review/:counterpartyId/:ddcId`}>
            <ReviewDDC />
          </Route>
          <Route path={`/ddc/review-stub/:counterpartyId/:ddcId`}>
            <ReviewStub />
          </Route>
          <Route path={`/ddc/review-archived/:counterpartyId/:ddcId`}>
            <ReviewArchived />
          </Route>
          <Route path={`/ddc/create/:counterpartyId`}>
            <CreateDDCForCounterparty />
          </Route>
          <Route exact path={`/qcor`}>
            <QCOROverview />
          </Route>
          <Route path={`/qcor/:id`}>
            <QCORTemplate />
          </Route>
          <Route path={`/cssf/template`}>
            <CSSFTemplate />
          </Route>
          <Route path={`/management/risk`}>
            <RiskConfiguration />
          </Route>
          <Route path={`/management/risk-history`}>
            <RiskConfigurationHistory />
          </Route>
          <Route path={`/management/tier`}>
            <TierConfiguration />
          </Route>
          <Route path={`/whatsnew`}>
            <WhatsNew />
          </Route>
          <Route path="*">
            <Redirect to="/whatsnew" />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default Home;
