import { SortDescriptor } from "@progress/kendo-data-query";

const getQueryParamName = (descriptor: SortDescriptor) => {
  let queryParam;
  switch (descriptor.field) {
    case "ddc.counterpartyId":
      queryParam = "counterpartyId";
      break;
    case "ddc.counterpartyName":
      queryParam = "counterpartyName";
      break;
    case "ddc.arr":
      queryParam = "arr";
      break;
    default:
      queryParam = descriptor.field;
  }
  return queryParam;
};

const getQueryParamValue = (descriptor: SortDescriptor) => {
  return descriptor.dir?.toUpperCase();
};

export const createSortParam = (descriptor: SortDescriptor) => {
  const field = getQueryParamName(descriptor);
  const direction = getQueryParamValue(descriptor);
  return `${field}+${direction}`;
};
