import React, { useCallback, useEffect, useState } from "react";
import Loading from "../../components/Loading";
import DDCForm from "../../components/DDCForm";

import { useAuthContext } from "../../auth/AcolinAuthContext";
import { useParams } from "react-router-dom";
import { COMPLIANCE_API_ORIGIN } from "../../config";
import {
  ROLE_NAME_MANAGER,
  DDC_STATUS_DRAFT,
  DDC_STATUS_ACTIVE,
  DDC_STATUS_OUTDATED,
  DDC_STATUS_PENDING,
  LogItemResponse,
  ComponentState,
  FormData,
  DDC_STATUS_CANCELLED,
  CountryResponse,
  UBO,
  DDC_STATUS_ARCHIVED,
} from "../../common/ddc/types";
import { LogItem } from "../../common/ddc/types";
import {
  getErrorMessage,
} from "../../common/util";
import {
  createUBOs,
  mapResponseToCountry,
  responseToDDC,
} from "../../common/ddc/util";
import axiosInstance from "../../api/axios";
import { Option } from "../../components/common/formik/FormikWrappers";
import { isEqual } from "lodash";

const areOMNIFieldsDifferent = (oldUBOs: UBO[], newUBOs: UBO[]): boolean => {
  if (oldUBOs?.length !== newUBOs?.length) {
    return true;
  }

  const oldIds = oldUBOs?.map((ubo) => ubo.id).sort();
  const newIds = newUBOs?.map((ubo) => ubo.id).sort();

  if (!isEqual(oldIds, newIds)) {
    return true;
  }

  return oldIds?.reduce((acc: boolean, id: string) => {
    const oldUBO = oldUBOs?.find((ubo) => id === ubo.id);
    const newUBO = newUBOs?.find((ubo) => id === ubo.id);
    const areDifferent: boolean =
      !isEqual(oldUBO?.name, newUBO?.name) ||
      !isEqual(oldUBO?.countryOfTaxResidence, newUBO?.countryOfTaxResidence) ||
      !isEqual(oldUBO?.isPEP, newUBO?.isPEP) ||
      !isEqual(oldUBO?.isRCA, newUBO?.isRCA) ||
      !isEqual(oldUBO?.nationality, newUBO?.nationality);

    return acc || areDifferent;
  }, false);
};

const updateOMNIFields = (oldUBOs: UBO[], newUBOs: UBO[]): UBO[] => {
  return newUBOs?.map((newUBO) => {
    const oldUBO = oldUBOs.find((ubo) => ubo.id === newUBO.id);
    if (oldUBO) {
      return {
        ...newUBO,
        isOnSanctionList: oldUBO.isOnSanctionList,
        hasAdverseInformationRelatedToFinancialCrime:
          oldUBO.hasAdverseInformationRelatedToFinancialCrime,
      };
    } else {
      return newUBO;
    }
  });
};

const ReviewDDC: React.FC = () => {
  const { counterpartyId, ddcId } = useParams<{
    counterpartyId: string;
    ddcId: string;
  }>();
  const [authState] = useAuthContext();
  const user = authState?.user;
  const roleName = user?.role?.name;
  const isManager = roleName === ROLE_NAME_MANAGER;
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<FormData>();
  const [ddcStatus, setDDCStatus] = useState<string>("");
  const [reload, setReload] = useState<boolean>(false);

  const forceReload = useCallback(() => {
    setReload(prev => !prev);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      axiosInstance.get(`${COMPLIANCE_API_ORIGIN}/v1/ddc/${ddcId}`),
      axiosInstance.get(
        `${COMPLIANCE_API_ORIGIN}/v1/counterparties/${counterpartyId}`
      ),
      axiosInstance.get(`${COMPLIANCE_API_ORIGIN}/v1/ddc-options/`),
    ])
      .then((responses) => {
        const ddcResponse = responses[0].data;
        const ddc = responseToDDC(ddcResponse);
        const owner = ddcResponse.owner;
        const logItems: LogItem[] = ddcResponse.log
          .map((item: LogItemResponse) => ({
            id: item.id,
            email: item.updatedBy.email,
            status: item.status.status,
            changeset: item.changeset,
            date: new Date(item.timestamp),
          }))
          .sort(
            (first: LogItem, second: LogItem) =>
              second.date.getTime() - first.date.getTime()
          );

        const ddcStatus = ddcResponse.status.status;
        setDDCStatus(ddcStatus);

        const ddConfirmationState: ComponentState = ddcStatus === DDC_STATUS_DRAFT ? "active" : "disabled";

        const concluded =
          ddcStatus === DDC_STATUS_ACTIVE ||
          ddcStatus === DDC_STATUS_OUTDATED ||
          ddcStatus === DDC_STATUS_CANCELLED ||
          ddcStatus === DDC_STATUS_ARCHIVED;

        const isOwner = owner.email.toLowerCase() === user?.email.toLowerCase();
        const readOnly = concluded || (isOwner && ddcStatus === DDC_STATUS_PENDING);


        const amlConfirmationState: ComponentState = ddcStatus === DDC_STATUS_DRAFT ? "hidden" : "active";

        ddc.submissionDate = new Date(ddc.submissionDate);
        // Next DD date might be undefined if user selected
        // None as frequency
        if (ddc.nextDDDate) {
          ddc.nextDDDate = new Date(ddc.nextDDDate);
        }

        // Set the DDC values obtained from the backend as the initial
        // values for the form, plus the information from the account
        // information that are not stored as a part of DDC, but have
        // to be shown in the Account Information section.
        const accountInformation = responses[1].data.accInfo;
        if (accountInformation.latestNextDDDate) {
          accountInformation.latestNextDDDate = new Date(
            accountInformation.latestNextDDDate
          );
        }
        if (accountInformation.terminationDate) {
          accountInformation.terminationDate = new Date(
            accountInformation.terminationDate
          );
        }

        const country = ddcResponse.tierConfiguration.snapshot.find(
          (country: any) => country.isoAlpha2Code === ddc.domicileISOCode
        );

        const defaultDomicileGroup: Option = country?.group
          ? { id: country.group, name: country.group }
          : { id: "EU/UK", name: "EU/UK" };

        const domicileGroup = ddc.domicileGroup
          ? { id: ddc.domicileGroup, name: ddc.domicileGroup }
          : defaultDomicileGroup;

        const countries = mapResponseToCountry(
          ddcResponse.amlctfConfiguration.content.categories,
          ddcResponse.tierConfiguration.snapshot
        );

        const amlCtfOptions = {
          categories: ddcResponse.amlctfConfiguration.content.categories,
          binary: ddcResponse.amlctfConfiguration.content.binary,
          countries,
          version: ddcResponse.amlctfConfiguration.content.version,
        };

        const isNewAmlCtfVersion =
          ddcResponse?.amlctfConfiguration?.content?.version !== undefined;

        if (isNewAmlCtfVersion) {
          const newUBOs = createUBOs(ddcResponse.UBOList, amlCtfOptions);
          const isDDCUBOListEmpty: boolean = !ddc.UBO || ddc.UBO?.length === 0;

          if (ddcStatus === "DRAFT") {
            if (areOMNIFieldsDifferent(ddc.UBO as UBO[], newUBOs)) {
              ddc.UBO = isDDCUBOListEmpty ? newUBOs : updateOMNIFields(ddc.UBO as UBO[], newUBOs);
            }
          } 
        }

        // Blend in data from the stored DDC and account information
        // to create initial form values. If DDC is concluded, don't
        // show termination date and counterparty status because that
        // information is pulled form OMNI and is up to date, so it
        // wouldn't make sense to show it on DDCs that are finalized.
        let initialValues = {
          ...ddc,
          domicileGroup,
          latestFrequency: accountInformation.latestFrequency,
          latestNextDDDate: accountInformation.latestNextDDDate,
          terminationDate: concluded
            ? undefined
            : accountInformation.terminationDate,
          status: concluded ? undefined : accountInformation.status,
        };

        const showCancel = (!concluded && isManager);
        const showRevert = ddcStatus === DDC_STATUS_PENDING && !isOwner;

        const save = (formSubmission: any) => {
          setIsLoading(true);
           return axiosInstance.put(
              `${COMPLIANCE_API_ORIGIN}/v1/ddc/${ddcId}`,
              {
                formSubmission,
              }
            ).then((resp) => {setIsLoading(false); forceReload(); return resp});
        };

        const submit = (formSubmission: any) => {
            return axiosInstance.put(
              `${COMPLIANCE_API_ORIGIN}/v1/ddc/${ddcId}`,
              {
                formSubmission,
              },
              {
                params: {
                  isSubmit: true
                }
              }
            );
        };

        const cancel = (formSubmission: any) => {
          return axiosInstance.put(
            `${COMPLIANCE_API_ORIGIN}/v1/ddc/${ddcId}`,
            {
              formSubmission,
            },
            {
              params: {
                action: "cancel",
              },
            }
          );
        };

      const revert = (formSubmission: any) => {
          return axiosInstance.put(
            `${COMPLIANCE_API_ORIGIN}/v1/ddc/${ddcId}`,
            {
              formSubmission,
            },
            {
              params: {
                action: "revert",
              },
            }
          );
        };

        const domicileGroupsSet: Set<string> = ddcResponse.tierConfiguration.snapshot.reduce(
          (acc: Set<string>, country: CountryResponse) => {
            if (country.group) {
              acc.add(country.group);
            }
            return acc;
          },
          new Set<string>()
        );

        const domicileGroups: Option[] = Array.from(
          domicileGroupsSet
        ).map((group) => ({ id: group, name: group }));

        setFormData({
          initialValues,
          logItems,
          showBack: true,
          showCancel,
          showRevert,
          ddConfirmationState,
          amlConfirmationState,
          readOnly,
          riskConfigTemplates: ddcResponse.riskConfiguration.content,
          amlCtfOptions,
          options: { ...responses[2].data, domicileGroups },
          save,
          submit,
          cancel,
          revert
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        const message = getErrorMessage(error);
        alert(message);
      });
  }, [ddcId, user, roleName, counterpartyId, reload]);

  return isLoading ? (
    <Loading />
  ) : formData ? (
    <DDCForm
      initialValues={formData.initialValues}
      logItems={formData.logItems}
      showTitle
      showBack={formData.showBack}
      showCancel={formData.showCancel}
      showRevert={formData.showRevert}
      readOnly={formData.readOnly}
      ddConfirmationState={formData.ddConfirmationState}
      amlConfirmationState={formData.amlConfirmationState}
      riskConfigTemplates={formData.riskConfigTemplates}
      amlCtfOptions={formData.amlCtfOptions}
      options={formData.options}
      ddcStatus={ddcStatus}
      save={formData.save}
      submit={formData.submit}
      cancel={formData.cancel}
      revert={formData.revert}
    />
  ) : (
    <></>
  );
};

export default ReviewDDC;
