import React from "react";

import SectionHeader from "../../SectionHeader";
import SectionFooter from "../../SectionFooter";

const DistributionPartnersMonitoring: React.FC<{ id: number }> = ({ id }) => {
  return (
    <>
      <SectionHeader id={id}>
        {`${id} Monitoring / Screening of relevant individuals of our distribution partners `}
      </SectionHeader>
      <p>
        We use Refinitive`s World Check One and international databases to check
        our counterparties and the relevant individuals involved in the business
        relationship (e.g. Ultimate Beneficial Owner, the person that acts on
        behalf of the counterparty, Board members, etc). An initial screening is
        always carried out prior to signing an agreement with a new partner. We
        continue to monitor both the counterparty and the respective individuals
        during the course of our business relationship. To ensure the
        effectiveness of our monitoring, we regularly update our partners’ data
        as part of our ongoing due diligence questionnaires.
      </p>
      <SectionFooter />
    </>
  );
};

export default React.memo(DistributionPartnersMonitoring);
