import React, { useCallback, useState } from "react";
import Loading from "../../components/Loading";

import axiosInstance from "../../api/axios";
import { COMPLIANCE_API_ORIGIN } from "../../config";
import { getErrorMessage } from "../../common/util";

import styles from "./CreateDDC.module.scss";
import { Option } from "../../components/common/formik/FormikWrappers";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import Dialog from "../../components/Dialog";

export type DDCStub = {
  counterpartyId: string;
  counterpartyName: string;
  domicile: string;
  domicileISOCode?: string;
  contractType: Option | null;
  ddcType: Option | null;
  serviceType: Option | null;
};

export type MasterFileRow = {
  counterpartyId: string;
  counterpartyName: string;
  domicile: string;
  contractTypeName: string;
  ddcTypeName: string;
  serviceTypeName: string;
};

export type MasterFileData = {
  [counterpartyId: string]: MasterFileRow;
};

export type OMNIOptions = {
  acolinCounterparties: Option[];
  contractTypes: Option[];
  ddcTypes: Option[];
  serviceTypes: Option[];
};

type DialogMessage = {
  confirmation: boolean;
  error: boolean;
  message: string;
};

const emptyDialog: DialogMessage = {
  confirmation: false,
  error: false,
  message: "",
};

const CreateDDC: React.FC<{
  stubData: DDCStub;
  isValidStub: boolean;
  onCreate: () => void;
}> = ({ stubData, isValidStub, onCreate }) => {
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState<DialogMessage>(emptyDialog);

  const showError = useCallback((message) => {
    setDialog({
      confirmation: false,
      error: true,
      message,
    });
  }, []);

  const showWarning = useCallback((message) => {
    setDialog({
      confirmation: true,
      error: false,
      message,
    });
  }, []);

  const closeDialog = useCallback(() => {
    setDialog(emptyDialog);
  }, []);

  const createStub = useCallback(async () => {
    try {
      setLoading(true);
      await axiosInstance.post(
        `${COMPLIANCE_API_ORIGIN}/v1/ddc-insert-stubs-batch`,
        [stubData]
      );
      onCreate();
    } catch (error) {
      const message =
        (error as any)?.response?.data?.error?.message ??
        getErrorMessage(error);
      alert(message);
    } finally {
      setLoading(false);
    }
  }, [onCreate, stubData]);

  const handleSubmit = useCallback(() => {
    if (!isValidStub) {
      return;
    }

    const { counterpartyId } = stubData;
    setLoading(true);

    axiosInstance
      .get(`${COMPLIANCE_API_ORIGIN}/v1/ddc`, { params: { counterpartyId } })
      .then(({ data }) => {
        const statuses: string[] = data?.ddcs?.map(
          (ddc: any) => ddc?.status?.status
        );
        if (
          statuses.some((status) =>
            ["STUB", "DRAFT", "PENDING"].includes(status)
          )
        ) {
          showError(
            "There is a STUB, DRAFT or PENDING DDC for this counterparty.\nPlease CANCEL it before creating a new STUB."
          );
        } else if (statuses.some((status) => status === "ACTIVE")) {
          showWarning(
            "There is an ACTIVE DDC for this counterparty.\nAre you sure you want to create a STUB?"
          );
        } else {
          createStub();
        }
      })
      .catch((error) => {
        const message =
          error?.response?.data?.error?.message ?? getErrorMessage(error);
        alert(message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [stubData, isValidStub, createStub, showError, showWarning]);

  return loading ? (
    <div className={styles.loadingContainer}>
      <Loading />
    </div>
  ) : stubData ? (
    <>
      <Card>
        <CardHeader>
          <CardTitle>STUB DDC</CardTitle>
        </CardHeader>
        <CardBody className={styles.root}>
          <div className={styles.item}>
            <dt>Counterparty Name:</dt>
            <dd>{stubData?.counterpartyName || "N/A"}</dd>
          </div>
          <div className={styles.item}>
            <dt>ID Number:</dt>
            <dd>{stubData?.counterpartyId || "N/A"}</dd>
          </div>
          <div className={styles.item}>
            <dt>Acolin Service:</dt>
            <dd>{stubData?.serviceType?.name || "N/A"}</dd>
          </div>
          <div className={styles.item}>
            <dt>Domicile:</dt>
            <dd>{stubData?.domicile || "N/A"}</dd>
          </div>
          <div className={styles.item}>
            <dt>Contract Type:</dt>
            <dd>{stubData?.contractType?.name || "N/A"}</dd>
          </div>
          <div className={styles.item}>
            <dt>DDC Type:</dt>
            <dd>{stubData?.ddcType?.name || "N/A"}</dd>
          </div>
        </CardBody>

        <CardFooter className={styles.footer}>
          <Button
            type="button"
            onClick={handleSubmit}
            disabled={!isValidStub}
            className={styles.button}
          >
            Create STUB
          </Button>
        </CardFooter>
      </Card>
      {dialog.confirmation && (
        <Dialog
          title={"Warning"}
          type="choice"
          onNegativeButtonClick={() => {
            closeDialog();
          }}
          onPositiveButtonClick={() => {
            closeDialog();
            createStub();
          }}
          onClose={() => {
            closeDialog();
          }}
        >
          <>{dialog.message}</>
        </Dialog>
      )}
      {dialog.error && (
        <Dialog
          title="Error"
          type="confirmation"
          positiveButtonText="Ok"
          onPositiveButtonClick={() => {
            closeDialog();
          }}
          onClose={() => {
            closeDialog();
          }}
        >
          <>{dialog.message}</>
        </Dialog>
      )}
    </>
  ) : (
    <></>
  );
};

export default CreateDDC;
