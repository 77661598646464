import React from "react";
import { ErrorMessage, FieldAttributes, FieldProps } from "formik";
import {
  Checkbox,
  CheckboxChangeEvent,
  Input,
  RadioButton,
  TextArea,
} from "@progress/kendo-react-inputs";
import {
  DropDownList,
  DropDownListChangeEvent,
  MultiSelect,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import {
  DatePicker,
  DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import { Error as KendoError, Label } from "@progress/kendo-react-labels";
import { InputChangeEvent } from "@progress/kendo-react-inputs/dist/npm/input/interfaces/InputChangeEvent";
import { formatAsDate, formatAsQuartal } from "../../../common/util";

import styles from "./FormikWrappers.module.scss";

// If changing component's value has a side effect,
// in a sense that it's value affects other field's value
// (eg. other field is sum, and this input's value contributes
// to  that sum) can pass side effect function which
// accepts old and updated value, so that parent
// component can make required form values updates.
// Take in consideration that affected field(s) could be
// updated here using fomik props' setFieldValue, but
// it's not possible to reliably get updated values
// immediately after calling setFieldValue
// (see: https://github.com/formium/formik/issues/529),
// and since it might be required in some cases we
// opted out for the side effect function approach.

export interface Option {
  id: string;
  name: string;
}

interface FormRowProps {
  children: React.ReactElement;
  label: string;
}

export const FormRow: React.FC<FormRowProps> = ({ children, label }) => {
  const name: string = children.props.name;
  if (!name) {
    throw new Error("FormRow child must provide name prop!");
  }
  return (
    <div className={styles.formRow}>
      <KendoError>
        <ErrorMessage name={name} />
      </KendoError>
      <div className={styles.formRowContent}>
        <Label className={styles.label}>{label}</Label>
        {children}
      </div>
    </div>
  );
};

export const FormikTableInput = ({
  field,
  placeholder,
  ...props
}: FieldAttributes<any>) => {
  const meta = props.form.getFieldMeta(field.name);
  const notValid = meta.touched && meta.error;
  let inputPlaceholder = placeholder;
  let placeholderStyle = "";
  if (notValid) {
    inputPlaceholder = meta.error;
    placeholderStyle = styles.tableInputError;
  }
  return (
    <input
      className={placeholderStyle}
      {...field}
      {...props}
      placeholder={inputPlaceholder}
      autoComplete="off"
    />
  );
};

interface InputProps extends FieldProps {
  autoComplete: string;
  disabled: boolean;
  sideEffect?: (oldValue: any, newValue: any) => void;
}

export const FormikInput = ({
  field,
  autoComplete = "off",
  disabled,
  sideEffect,
}: InputProps) => {
  return (
    <Input
      {...field}
      onChange={(e: InputChangeEvent) => {
        field.onChange(e);
        if (typeof sideEffect === "function") {
          const newValue = e.target.value as string;
          sideEffect(field.value, newValue);
        }
      }}
      autoComplete={autoComplete}
      disabled={disabled}
    />
  );
};

export const FormikTextArea = ({
  field,
  form,
  disabled,
  ...props
}: FieldAttributes<any>) => {
  return (
    <TextArea
      {...field}
      {...props}
      rows={3}
      disabled={disabled}
      autoSize
      onChange={(event) => {
        form.setFieldValue(field.name, event.value);
      }}
    />
  );
};

export const FormikRadioGroup = ({
  field,
  form,
  options,
  disabled,
  sideEffect,
}: FieldAttributes<any>) => {
  return (
    <div className={styles.radioGroup}>
      {options.map((option: { label: string; value: string }) => (
        <RadioButton
          key={option.label}
          {...field}
          label={option.label}
          value={option}
          checked={option.value === field.value?.value}
          disabled={disabled}
          onChange={() => {
            if (typeof sideEffect === "function") {
              sideEffect(field.value, option);
            }
            form.setFieldValue(field.name, option);
          }}
        />
      ))}
    </div>
  );
};

interface MultiSelectProps extends FieldProps {
  options: Option[];
  disabled: boolean;
  textField: string;
  dataItemKey: string;
  sideEffect?: (oldValue: any, newValue: any) => void;
}

export const FormikMultiSelect = ({
  field,
  form,
  options,
  disabled,
  textField,
  dataItemKey,
  sideEffect,
  ...props
}: MultiSelectProps) => {
  return (
    <MultiSelect
      className={styles.dropDown}
      {...field}
      {...props}
      data={options}
      disabled={disabled}
      textField="name"
      dataItemKey="id"
      onChange={(event: MultiSelectChangeEvent) => {
        const newValue = event.target.value;
        if (typeof sideEffect === "function") {
          sideEffect(field.value, newValue);
        }
        form.setFieldValue(field.name, newValue);
      }}
    />
  );
};

interface DateLabelProps extends FieldProps {
  format: "date" | "quartal";
}

export const FormikDateLabel = ({ field, format }: DateLabelProps) => {
  let formattedDate;
  if (format === "date") {
    formattedDate = formatAsDate(field.value);
  } else {
    formattedDate = formatAsQuartal(field.value);
  }
  return <div>{formattedDate}</div>;
};

interface DropDownProps extends FieldProps {
  options: Option[];
  sideEffect?: (oldValue: any, newValue: any) => void;
  disabled: boolean;
}

export const FormikDropDown = ({
  field,
  options,
  sideEffect,
  disabled,
}: DropDownProps) => {
  return (
    <DropDownList
      className={styles.dropDown}
      {...field}
      data={options}
      textField="name"
      dataItemKey="id"
      disabled={disabled}
      onChange={(e: DropDownListChangeEvent) => {
        if (typeof sideEffect === "function") {
          const newValue = e.target.value;
          sideEffect(field.value, newValue);
        }
        field.onChange(e);
      }}
    />
  );
};

// Formik has integral support for checkbox inputs,
// but since we're using kendo it's not supported.
export const FormikCheckbox = ({
  field,
  form,
  disabled,
}: FieldAttributes<any>) => {
  return (
    <Checkbox
      name={field.name}
      checked={form.values[field.name]}
      disabled={disabled}
      onChange={(event: CheckboxChangeEvent) => {
        form.setFieldTouched(field.name, true, false);
        form.setFieldValue(field.name, event.value, true);
      }}
    />
  );
};

interface CheckBoxGroupProps extends FieldProps {
  options: Option[];
  disabled: boolean;
}

// Formik has multibinding for checkbox group, but since we're
// using kendo it's not supported. field.onChange is not working
export const FormikCheckboxGroup: React.FC<CheckBoxGroupProps> = ({
  field,
  form,
  options,
  disabled,
}) => {
  return (
    <div className={styles.checkboxGroup}>
      {options &&
        options.map((option) => (
          <Checkbox
            key={option.id}
            label={option.name}
            name={option.name}
            checked={field.value.includes(option.id)}
            disabled={disabled}
            onChange={(event: CheckboxChangeEvent) => {
              const checked = event.value;
              let newValues;
              if (checked) {
                newValues = [...form.values[field.name], option.id];
              } else {
                newValues = form.values[field.name].filter(
                  (id: string) => id !== option.id
                );
              }
              form.setFieldTouched(field.name, true, false);
              form.setFieldValue(field.name, newValues, true);
            }}
          />
        ))}
    </div>
  );
};

interface DatePickerProps extends FieldProps {
  min: Date;
  max: Date;
  disabled: boolean;
  sideEffect?: (oldValue: any, newValue: any) => void;
}

export const FormikDatePicker = ({
  field,
  min,
  max,
  disabled,
  sideEffect,
}: DatePickerProps) => {
  return (
    <DatePicker
      className={styles.dropDown}
      {...field}
      disabled={disabled}
      min={min}
      max={max}
      format="dd/MMMM/yyyy"
      onChange={(e: DatePickerChangeEvent) => {
        if (typeof sideEffect === "function") {
          const newValue = e.target.value;
          sideEffect(field.value, newValue);
        }
        field.onChange(e);
      }}
    />
  );
};
